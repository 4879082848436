import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import userService from "../../services/userService";

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [role, setRole] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [userDetails, setUserDetails] = useState({
    name: "",
    mobile_number: "",
    role: "user",
    password: "",
    currentPassword: "",
    newPassword: "",
  });
  const [error, setError] = useState({
    name: "",
    mobile_number: "",
    password: "",
    currentPassword: "",
    newPassword: "",
  });

  const fetchUsers = async () => {
    const response = await userService.getUsers();
    setUsers(response.data);
    setFilteredUsers(response.data);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    const results = users.filter((user) =>
      Object.values(user).some(
        (value) =>
          typeof value === "string" &&
          value.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
    setFilteredUsers(results);
  }, [searchTerm, users]);

  const handleOpenDialog = (user = null) => {
    setSelectedUser(user);
    setIsEditMode(!!user);
    setUserDetails(
      user
        ? { ...user, currentPassword: "", newPassword: "" }
        : { name: "", mobile_number: "", role: "user", password: "" }
    );

    setError({
      name: "",
      mobile_number: "",
      password: "",
      currentPassword: "",
      newPassword: "",
    });
    setOpenDialog(true);
  };

  const handleSaveUser = async () => {
    const validationErrors = {};
    if (!userDetails.name) validationErrors.name = "Name is required";
    if (!userDetails.mobile_number)
      validationErrors.mobile_number = "Mobile number is required";
    // if (!isEditMode && !userDetails.password)
    //   validationErrors.password = "Password is required for new users";
    // if (
    //   isEditMode &&
    //   (userDetails.newPassword != "" || userDetails.currentPassword != "")
    // ) {
    //   if (!userDetails.currentPassword)
    //     validationErrors.currentPassword =
    //       "Current password is required to set a new password";
    //   if (!userDetails.newPassword)
    //     validationErrors.newPassword = "New password cannot be empty";
    // }

    if (Object.keys(validationErrors).length > 0) {
      setError(validationErrors);
      return;
    }

    try {
      setError({});
      if (isEditMode) {
        await userService.updateUserRole(
          selectedUser.mobile_number,
          userDetails.name,
          userDetails.role,
          userDetails.newPassword,
          userDetails.currentPassword
        );
        setUsers(
          users.map((u) =>
            u.mobile_number === selectedUser.mobile_number
              ? { ...u, ...userDetails }
              : u
          )
        );
        setFilteredUsers(
          filteredUsers.map((u) =>
            u.mobile_number === selectedUser.mobile_number
              ? { ...u, ...userDetails }
              : u
          )
        );
        toast.success("User updated successfully.");
      } else {
        await userService.createUser(userDetails);
        const response = await userService.getUsers();
        setUsers(response.data);
        setFilteredUsers(response.data);
        toast.success("User added successfully.");
      }
      setOpenDialog(false);
    } catch (error) {
      console.error(error);
      toast.error(error.response?.data?.error || "Failed to save user.");
    }
  };

  const handleDeleteUser = async (ID) => {
    try {
      await userService.deleteUser(ID);
      setUsers(users.filter((user) => user.ID !== ID));
      setFilteredUsers(filteredUsers.filter((user) => user.ID !== ID));
      toast.success("User deleted successfully.");
    } catch (error) {
      toast.error("Failed to delete user.");
    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        User Management
      </Typography>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleOpenDialog()}
        >
          Add User
        </Button>

        <TextField
          label="Search"
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ width: "300px" }}
        />
      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Mobile Number</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredUsers.map((user, index) => (
              <TableRow key={index}>
                <TableCell>{index}</TableCell>
                <TableCell>{user.name}</TableCell>
                <TableCell>{user.mobile_number}</TableCell>
                <TableCell>{user.role}</TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    onClick={() => handleOpenDialog(user)}
                    style={{ marginRight: "10px" }}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => handleDeleteUser(user.ID)}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Dialog for adding or editing user */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>{isEditMode ? "Edit User" : "Add New User"}</DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            value={userDetails.name}
            onChange={(e) =>
              setUserDetails({ ...userDetails, name: e.target.value })
            }
            fullWidth
            margin="dense"
            error={!!error.name}
            helperText={error.name}
          />
          <TextField
            label="Mobile Number"
            value={userDetails.mobile_number}
            onChange={(e) =>
              setUserDetails({ ...userDetails, mobile_number: e.target.value })
            }
            fullWidth
            margin="dense"
            disabled={isEditMode}
            error={!!error.mobile_number}
            helperText={error.mobile_number}
          />
          <Select
            label="Role"
            value={userDetails.role}
            onChange={(e) =>
              setUserDetails({ ...userDetails, role: e.target.value })
            }
            fullWidth
            margin="dense"
          >
            <MenuItem value="user">User</MenuItem>
            <MenuItem value="admin">Admin</MenuItem>
            <MenuItem value="manager">Manager</MenuItem>
            <MenuItem value="staff">Staff</MenuItem>
          </Select>

          {/* Password fields based on mode */}
          {isEditMode ? (
            <>
              <TextField
                label="Current Password"
                type="password"
                value={userDetails.currentPassword}
                onChange={(e) =>
                  setUserDetails({
                    ...userDetails,
                    currentPassword: e.target.value,
                  })
                }
                fullWidth
                margin="dense"
                error={!!error.currentPassword}
                helperText={error.currentPassword}
              />
              <TextField
                label="New Password"
                type="password"
                value={userDetails.newPassword}
                onChange={(e) =>
                  setUserDetails({
                    ...userDetails,
                    newPassword: e.target.value,
                  })
                }
                fullWidth
                margin="dense"
                error={!!error.newPassword}
                helperText={error.newPassword}
              />
            </>
          ) : (
            <TextField
              label="Password"
              type="password"
              value={userDetails.password}
              onChange={(e) =>
                setUserDetails({ ...userDetails, password: e.target.value })
              }
              fullWidth
              margin="dense"
              error={!!error.password}
              helperText={error.password}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSaveUser} color="primary">
            {isEditMode ? "Save Changes" : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default UserManagement;
