import {
  Box,
  Button,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Checkbox,
  tableCellClasses,
  Alert,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Snackbar,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { Add, Delete, Edit } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import GroupService from "../../services/GroupModifierService"; // Import the service for Group CRUD operations
import CategoryService from "../../services/CategoryService"; // Import the service for Group CRUD operations
import { COLORS } from "../../theme/themeColor";
import GroupModifierForm from "./GroupModifierForm";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";

const CategoryForm = ({
  productId,
  existingRecipe,
  existingMaterialIds,
  recipeId,
  recipeType,
  onClose,
  onSuccess,
  onError,
}) => {
  const [materials, setMaterials] = useState([]);
  const [loading, setLoading] = useState(true);
  const [unit, setUnit] = useState("");
  //   console.log("id product", productId);

  const schema = Yup.object().shape({
    material_id: Yup.number().required("Please select a material."),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      material_id: existingRecipe ? existingRecipe.material_id : "",
      quantity: existingRecipe ? existingRecipe.quantity : "",
    },
  });

  useEffect(() => {
    const getMaterials = async () => {
      try {
        const response = await CategoryService.getAllCategories();
        let fetchedMaterials = response.data.dataTable;
        // let fetchedMaterials = data;
        console.log(
          "category list",
          fetchedMaterials,
          existingMaterialIds,
          existingRecipe
        );

        // // Loại bỏ các materials đã có trong recipe
        if (existingMaterialIds && existingMaterialIds.length > 0) {
          // Lọc lại data để loại bỏ các phần tử có ID trùng với ID trong mảng delet
          fetchedMaterials = fetchedMaterials.filter(
            (item) =>
              !existingMaterialIds.some(
                (deletedItem) => deletedItem.ID === item.ID
              )
          );
        }
        setMaterials(fetchedMaterials);
      } catch (err) {
        console.error("Failed to fetch materials:", err);
        onError("Failed to fetch materials from server.");
      } finally {
        setLoading(false);
      }
    };
    getMaterials();
  }, [onError]);

  const handleDetailChange = (value, materials) => {
    const material = materials.find((m) => m.ID === value);
    console.log("value", value);
    setUnit(material.unit);
  };

  //   useEffect(() => {
  //     if (materials.length > 0 && existingRecipe) {
  //       console.log(existingRecipe);
  //       handleDetailChange(existingRecipe.material_id, materials);
  //     }
  //   }, [existingRecipe, materials]);

  const onSubmit = async (data) => {
    try {
      const payload = {
        category_id: data.material_id,
        group_id: productId,
      };
      console.log("payload", payload);
      if (existingRecipe) {
        // await updateRecipe(recipeId, payload);
        console.log("update", payload);
      } else {
        await GroupService.setGroupForProducts(payload);
      }
      onSuccess();
      reset();
      onClose();
    } catch (err) {
      console.error("Failed to save recipe:", err);
      onError(err.response?.data?.error || "Failed to save recipe.");
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        {/* Material Selection */}
        <Grid item xs={12}>
          <FormControl
            fullWidth
            variant="outlined"
            error={!!errors.material_id}
          >
            <InputLabel id="material-label">Category</InputLabel>
            <Controller
              name="material_id"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  labelId="material-label"
                  label="Category"
                  disabled={loading}
                  onChange={(e) => {
                    field.onChange(e.target.value); // Update value for react-hook-form
                    handleDetailChange(e.target.value, materials);
                  }}
                >
                  {materials.map((material) => (
                    <MenuItem key={material.ID} value={material.ID}>
                      {material.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            {errors.material_id && (
              <Typography color="error" variant="body2">
                {errors.material_id.message}
              </Typography>
            )}
          </FormControl>
        </Grid>

        {/* Submit Button */}
        <Grid item xs={12}>
          <Button variant="contained" color="primary" type="submit" fullWidth>
            {existingRecipe ? "Update Category" : "Add Category"}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const AddCategory = ({ existing, recipeType, onClose }) => {
  //
  const [recipes, setRecipes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openForm, setOpenForm] = useState(false);
  const [selectedRecipe, setSelectedRecipe] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const getRecipes = async () => {
    try {
      const response = await GroupService.getGroupModifierCategoryById(
        existing.ID
      );
      console.log("category", response.data);
      setRecipes(response.data);
    } catch (err) {
      console.log("Failed to fetch recipes:", err);
      // setError('Failed to fetch recipes from server.');
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getRecipes();
  }, [existing]);

  const removeRecipeById = (recipeId) => {
    setRecipes((prevRecipes) => ({
      ...prevRecipes,
      details: prevRecipes.details.filter((recipe) => recipe.ID !== recipeId),
    }));
  };

  const handleDelete = async (item) => {
    console.log(`deleting`, item.ID, existing.ID);

    try {
      await GroupService.deleteGroupModifierOnProducts(existing.ID, item.ID);
      //   await deleteRecipedetail(data);
      //   removeRecipeById(item.ID);
      getRecipes();
      setSnackbar({
        open: true,
        message: "Deleted successfully",
        severity: "success",
      });
    } catch (err) {
      if (err.response)
        console.log("Failed to delete recipe:", err.response.data.error);
      console.log("Failed to delete recipe:", err.message);
      setSnackbar({
        open: true,
        message: `Error: ${err.message}`,
        severity: "error",
      });
    }
    // }
  };

  const handleEdit = (recipe) => {
    console.log("ddddd", recipes);
    setSelectedRecipe(recipe);
    setOpenForm(true);
  };

  const handleAdd = () => {
    setSelectedRecipe(null);
    setOpenForm(true);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    // getRecipes();
  };

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="50vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="50vh"
      >
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  return (
    <Box p={3}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h5">Category</Typography>
        <IconButton color="primary" onClick={handleAdd}>
          <Add />
        </IconButton>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Category Name</TableCell>
              {/* <TableCell>Quantity</TableCell> */}
              {/* <TableCell>Unit</TableCell> */}
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {recipes?.length > 0 &&
              recipes.map((recipe) => (
                <TableRow key={recipe.ID}>
                  <TableCell>{recipe.name}</TableCell>
                  {/* <TableCell>{recipe.quantity}</TableCell> */}
                  {/* <TableCell>{recipe.material.unit}</TableCell> */}
                  <TableCell align="right">
                    <IconButton
                      color="primary"
                      //   onClick={() => handleEdit(recipe)}
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      color="secondary"
                      onClick={() => handleDelete(recipe)}
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            {recipes.details ? null : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  No recipes available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Dialog Form */}
      <Dialog open={openForm} onClose={handleCloseForm} fullWidth maxWidth="sm">
        <DialogTitle>
          {selectedRecipe ? "Edit Recipe" : "Add Recipe"}
        </DialogTitle>
        <DialogContent>
          <CategoryForm
            productId={existing.ID}
            recipeId={recipes.ID}
            recipeType={recipeType}
            existingRecipe={selectedRecipe}
            existingMaterialIds={recipes}
            onClose={handleCloseForm}
            onSuccess={() => {
              getRecipes();
              setSnackbar({
                open: true,
                message: selectedRecipe
                  ? "Update successful"
                  : "Added successfully",
                severity: "success",
              });
            }}
            onError={(msg) => {
              setSnackbar({ open: true, message: msg, severity: "error" });
            }}
          />
        </DialogContent>
      </Dialog>

      {/* Snackbar Notification */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

const GrouptModifier = () => {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: COLORS.BLUE,
      color: theme.palette.common.white,
      align: "center",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: "5px 5px",
      align: "center",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
      align: "center",
    },
    "&:last-child td, &:last-child th": {
      border: 0,
      align: "center",
    },
  }));

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const [openForm, setOpenForm] = useState(false);
  const [selectedProductRecipe, setSelectedProductRecipe] = useState(null);

  const [groupModifiers, setGroupModifiers] = useState([]);
  const [searchTerm, setSearchTerm] = useState(""); // State cho từ khóa tìm kiếm
  const [openModal, setOpenModal] = useState(false);
  const [selectedGroupModifier, setSelectedGroupModifier] = useState(null); // State lưu trữ thông tin nhóm đang chỉnh sửa

  useEffect(() => {
    fetchGroupModifiers(); // Fetch existing group modifiers
  }, []);

  const fetchGroupModifiers = async () => {
    try {
      const response = await GroupService.getAllGroupModifiers(); // Use GroupService to fetch group modifiers
      const sortdata = response.data.sort((a, b) =>
        a.Name.localeCompare(b.Name)
      );
      setGroupModifiers(sortdata || []);
      console.log(response.data);
    } catch (error) {
      console.error("Failed to fetch group modifiers:", error);
      setGroupModifiers([]);
    }
  };

  const handleOpenModal = (group = null) => {
    setSelectedGroupModifier(group); // Đặt dữ liệu của nhóm được chọn (nếu có) vào state
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedGroupModifier(null); // Xóa dữ liệu nhóm đã chọn sau khi đóng modal
  };

  const handleSaveGroupModifier = async (groupData) => {
    await fetchGroupModifiers(); // Cập nhật lại danh sách group modifiers
    // try {
    //     if (selectedGroupModifier) {
    //         // Chỉnh sửa nhóm đã chọn
    //         await GroupService.updateGroupModifier(selectedGroupModifier.ID, groupData);
    //         toast.success("Group Modifier updated successfully.");
    //     } else {
    //         // Tạo mới một nhóm
    //         await GroupService.createGroupModifier(groupData);
    //         toast.success("Group Modifier created successfully.");
    //     }
    //     await fetchGroupModifiers(); // Cập nhật lại danh sách group modifiers
    //     handleCloseModal(); // Đóng modal sau khi lưu thành công
    // } catch (error) {
    //     console.error("Failed to save group modifier:", error);
    //     toast.error("Failed to save group modifier.");
    // }
  };

  const handleDeleteGroupModifier = async (id) => {
    try {
      await GroupService.deleteGroupModifier(id);
      setGroupModifiers(groupModifiers.filter((group) => group.ID !== id));
      toast.success("Group Modifier deleted successfully.");
    } catch (error) {
      console.error("Failed to delete group modifier:", error);
      toast.error("Failed to delete group modifier.");
    }
  };

  const filteredGroupModifiers = groupModifiers.filter((group) =>
    group.Name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleChange1Checkbox = async (value) => {
    value.PosOnly = !value.PosOnly;
    const tempValue = {
      ID: value.ID,
      name: value.Name,
      description: value.Description,
      min_qty: value.MinQty,
      max_qty: value.MaxQty,
      pos_only: value.PosOnly,
    };
    console.log("change check", tempValue);

    try {
      //   await campaignService.updateCampaign(value.ID, value);
      //         // Chỉnh sửa nhóm đã chọn
      await GroupService.updateGroupModifierPosOnly(value.ID, tempValue);
      toast.success("Updated successfully!");
      fetchGroupModifiers();
    } catch (error) {
      toast.error("Updated error!");
      console.log(error);
    }
  };

  const handleCloseForm = () => {
    // fetchProducts();
    setOpenForm(false);
  };

  const handleAdd = (group) => {
    console.log("testdata", group);
    setSelectedProductRecipe(group);
    setOpenForm(true);
  };

  return (
    <div style={{ width: "100%" }}>
      <Typography variant="h4" gutterBottom>
        Group Modifier List
      </Typography>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleOpenModal()}
        >
          Add Group Modifier
        </Button>

        <TextField
          label="Search"
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ width: "300px" }} // Đặt độ rộng cho TextField tìm kiếm
        />
      </div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">ID</StyledTableCell>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Description</StyledTableCell>
              <StyledTableCell align="center">MinQty</StyledTableCell>
              <StyledTableCell align="center">MaxQty</StyledTableCell>
              <StyledTableCell>Category</StyledTableCell>
              <StyledTableCell align="center">PosOnly</StyledTableCell>
              <StyledTableCell align="right">Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredGroupModifiers.map((group, idx) => (
              <StyledTableRow key={group.ID}>
                <StyledTableCell align="center">{idx}</StyledTableCell>
                <StyledTableCell>{group.Name}</StyledTableCell>
                <StyledTableCell>{group.Description}</StyledTableCell>
                <StyledTableCell align="center">{group.MinQty}</StyledTableCell>
                <StyledTableCell align="center">{group.MaxQty}</StyledTableCell>
                <StyledTableCell>
                  <Button
                    variant="outlined"
                    // color={product.recipe ? "info" : "error"}
                    onClick={() => handleAdd(group)}
                  >
                    Category
                  </Button>
                </StyledTableCell>

                <StyledTableCell align="center">
                  <Checkbox
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                    checked={group.PosOnly}
                    onChange={() => handleChange1Checkbox(group)}
                    // disabled // Checkbox chỉ để hiển thị, không cho phép thay đổi
                  />
                </StyledTableCell>
                <StyledTableCell align="right">
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleOpenModal(group)}
                    sx={{ marginRight: 1 }}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => handleDeleteGroupModifier(group.ID)}
                  >
                    Delete
                  </Button>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={modalStyle}>
          <GroupModifierForm
            mode={selectedGroupModifier ? "edit" : "create"}
            groupData={selectedGroupModifier}
            onRefesh={handleSaveGroupModifier}
            onClose={handleCloseModal}
          />
        </Box>
      </Modal>

      {/* Dialog Form */}
      <Dialog open={openForm} onClose={handleCloseForm} fullWidth maxWidth="md">
        <DialogTitle>
          {selectedProductRecipe
            ? "Edit GroupModifier " + selectedProductRecipe.Name
            : "Add New Category "}
        </DialogTitle>
        <DialogContent>
          <AddCategory
            existing={selectedProductRecipe}
            recipeType={"product"}
            onClose={handleCloseForm}
            // onSuccess={() => {
            //     getAdjustmentRecords();
            //     setSnackbar({ open: true, message: selectedAdjustment ? 'Cập nhật phiếu điều chỉnh thành công' : 'Thêm phiếu điều chỉnh thành công', severity: 'success' });
            // }}
            // onError={(msg) => {
            //     setSnackbar({ open: true, message: msg, severity: 'error' });
            // }}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default GrouptModifier;
