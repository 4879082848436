import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  TextField,
  Box,
  Chip,
  List,
  ListItem,
  ListItemText,
  Divider,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import GroupCustomerService from "../../services/GroupCustomerService";

const GroupCustomerManager = ({
  customer,
  setSelectedGroupCustomer,
  SetlistCustomerOfGroup,
  delSuccess,
}) => {
  // States
  const [groups, setGroups] = useState([]); // Danh sách nhóm
  const [customers, setCustomers] = useState([]); // Danh sách khách hàng
  const [selectedGroup, setSelectedGroup] = useState(null); // Nhóm được chọn
  const [selectedCustomers, setSelectedCustomers] = useState([]); // Khách hàng đã thêm vào nhóm
  const [isCreateGroupDialogOpen, setIsCreateGroupDialogOpen] = useState(false); // Trạng thái mở dialog tạo nhóm
  const [newGroupName, setNewGroupName] = useState(""); // Tên nhóm mới
  const [newGroupDescription, setNewGroupDescription] = useState(""); // Mô tả nhóm mới

  // Fetch groups on component mount
  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const response = await GroupCustomerService.getGroups();
        setGroups(response.data);
        setSelectedGroup(response.data[0]);
      } catch (error) {
        console.error("Error fetching groups:", error);
      }
    };
    fetchGroups();
  }, []);
  useEffect(() => {
    SetlistCustomerOfGroup(selectedCustomers);
  }, [selectedCustomers]);

  // Fetch customers in a group when selectedGroup changes
  useEffect(() => {
    if (selectedGroup) {
      const fetchGroupCustomers = async () => {
        try {
          const response = await GroupCustomerService.getGroupCustomers(
            selectedGroup.id
          );
          setSelectedCustomers(response.data);
        } catch (error) {
          console.error("Error fetching group customers:", error);
        }
      };
      fetchGroupCustomers();

      setSelectedGroupCustomer(selectedGroup);
    }
  }, [selectedGroup]);

  // Search customers
  const handleSearchCustomers = async (query) => {
    try {
      const response = await GroupCustomerService.searchCustomers(query);
      console.log(response.data);
      //   setCustomers(response.data);
    } catch (error) {
      console.error("Error searching customers:", error);
    }
  };

  useEffect(() => {
    console.log("customer add group", customer);
    // setCustomers(customer);
    if (customer) {
      handleCustomerSelect(customer);
    }
  }, [customer]);

  //   useEffect(() => {}, [customer]);

  // Add customer to group
  const handleCustomerSelect = async (newValue) => {
    if (!selectedGroup) {
      alert("Please select a group first!");
      return;
    }

    if (
      newValue &&
      !selectedCustomers.some((cust) => cust.id === newValue.id)
    ) {
      try {
        await GroupCustomerService.addCustomerToGroup(
          selectedGroup.id,
          newValue.id
        );
        setSelectedCustomers((prev) => [...prev, newValue]);
      } catch (error) {
        console.error("Error adding customer to group:", error);
      }
    }
  };

  //
  const handleCustomerRemove = async (customer) => {
    if (!selectedGroup) {
      alert("Please select a group first!");
      return;
    }

    try {
      // Gọi API xóa khách hàng khỏi nhóm
      await GroupCustomerService.removeCustomerFromGroup(
        selectedGroup.id,
        customer.id
      );

      // Cập nhật danh sách khách hàng sau khi xóa
      setSelectedCustomers((prev) =>
        prev.filter((cust) => cust.id !== customer.id)
      );

      console.log(
        `Customer ID ${customer.id} removed from group ${selectedGroup.name}`
      );
      delSuccess();
    } catch (error) {
      console.error("Error removing customer from group:", error);
    }
  };

  // Create a new group
  const handleCreateGroup = async () => {
    const newGroupData = {
      name: newGroupName,
      description: newGroupDescription,
    };

    try {
      const response = await GroupCustomerService.createGroup(newGroupData);
      setGroups((prev) => [...prev, response.data]);
      setIsCreateGroupDialogOpen(false);
    } catch (error) {
      console.error("Error creating group:", error);
    }
  };

  return (
    <Box display="flex" flexDirection="row" p={3} gap={3}>
      {/* Danh sách các nhóm */}
      <Box width="30%">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Typography variant="h6">Groups</Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setIsCreateGroupDialogOpen(true)}
          >
            Create Group
          </Button>
        </Box>
        <List>
          {groups.map((group) => (
            <ListItem
              button
              key={group.id}
              onClick={() => setSelectedGroup(group)}
              selected={selectedGroup?.id === group.id}
              style={{
                border:
                  selectedGroup?.id === group.id
                    ? "2px solid #1976d2"
                    : "1px solid #ccc", // Màu viền khác nhau
                borderRadius: "8px", // Làm viền bo tròn
                marginBottom: "8px", // Khoảng cách giữa các item
              }}
            >
              <ListItemText
                primary={group.name}
                secondary={group.description}
                style={{
                  color: selectedGroup?.id === group.id ? "#1976d2" : "inherit", // Màu chữ khác khi được chọn
                }}
              />
            </ListItem>
          ))}
        </List>
      </Box>

      <Divider orientation="vertical" flexItem />

      {/* Quản lý khách hàng */}
      <Box width="70%">
        {selectedGroup ? (
          <>
            <Typography variant="h6">
              Manage Customers for Group: {selectedGroup.name}
            </Typography>

            {/* Hiển thị danh sách khách hàng đã thêm */}
            <Box mt={2}>
              <Typography variant="subtitle1">Added Customers:</Typography>
              <Box mt={1} display="flex" flexWrap="wrap" gap={1}>
                {selectedCustomers.map((customer, index) => (
                  <Chip
                    key={`${customer.id}-${index}`}
                    label={`${customer.username} (${customer.mobile_number})`}
                    onDelete={
                      () => handleCustomerRemove(customer) // Gọi logic xóa khách hàng
                    }
                  />
                ))}
              </Box>
            </Box>
          </>
        ) : (
          <Typography variant="h6">Please select a group to manage.</Typography>
        )}
      </Box>

      {/* Dialog Tạo Nhóm */}
      <Dialog
        open={isCreateGroupDialogOpen}
        onClose={() => setIsCreateGroupDialogOpen(false)}
      >
        <DialogTitle>Create New Group</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Group Name"
            variant="outlined"
            value={newGroupName}
            onChange={(e) => setNewGroupName(e.target.value)}
            margin="dense"
          />
          <TextField
            fullWidth
            label="Description"
            variant="outlined"
            value={newGroupDescription}
            onChange={(e) => setNewGroupDescription(e.target.value)}
            margin="dense"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsCreateGroupDialogOpen(false)}>
            Cancel
          </Button>
          <Button
            onClick={handleCreateGroup}
            variant="contained"
            color="primary"
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default GroupCustomerManager;
