import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  tableCellClasses,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // Sử dụng useNavigate
import config from "../../config";
import InventoryService, {
  UpdateMaxQuantityRecipe,
} from "../../services/InventoryService";
import { COLORS } from "../../theme/themeColor";
const BaseUrl = config.apiBaseUrl;

const MaterialManagement = () => {
  const [filtered, setFiltered] = useState([]); // State cho danh sách sản phẩm đã lọc
  const [searchTerm, setSearchTerm] = useState(""); // State cho từ khóa tìm kiếm
  const [selectedWarehouse, setSelectedWarehouse] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [uniqueWarehouses, setUniqueWarehouses] = useState([]);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: COLORS.BLUE,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: "0px 5px",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
    // height: 20,
  }));

  const handleSelectChange = (event) => {
    const warehouseName = event.target.value;
    setSelectedWarehouse(warehouseName);

    // Lọc danh sách theo tên warehouse
    const filter = filtered.filter(
      (item) => item.warehouse.name === warehouseName
    );
    setFilteredData(filter);
  };

  const handleMaxQuantity = async () => {
    await UpdateMaxQuantityRecipe(null);
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await InventoryService.getAllInventory();
      console.log("fetchData", response.data);
      if (response.data) {
        setFiltered(response.data); // Ban đầu hiển thị tất cả sản phẩm

        const uniqueWarehouses = Object.values(
          response.data.reduce((acc, item) => {
            const warehouse = item.warehouse;
            if (!acc[warehouse.ID]) {
              acc[warehouse.ID] = {
                ID: warehouse.ID,
                name: warehouse.name,
              };
            }
            return acc;
          }, {})
        );
        setUniqueWarehouses(uniqueWarehouses);

        // Lọc danh sách theo tên warehouse
        const filter = response.data.filter(
          (item) => item.warehouse.name === uniqueWarehouses[0].name
        );
        setFilteredData(filter);
        setSelectedWarehouse(uniqueWarehouses[0].name);
      }
    };
    fetchData();
  }, []);

  // Hàm để lọc sản phẩm dựa trên từ khóa tìm kiếm
  useEffect(() => {
    if (filtered) {
      const results = filtered.filter((item) => {
        const fieldsToSearch = ["Warehouse", "Name", "Description"]; // Các trường cần tìm kiếm
        return fieldsToSearch.some(
          (field) =>
            item[field] && // Kiểm tra trường tồn tại
            typeof item[field] === "string" && // Chỉ tìm kiếm trên chuỗi
            item[field].toLowerCase().includes(searchTerm.toLowerCase()) // Tìm kiếm từ khóa
        );
      });
      console.log("check search", searchTerm);
      setFilteredData(results);
    }
  }, [searchTerm]);

  return (
    <div style={{ width: "100%" }}>
      <Typography variant="h4" gutterBottom>
        Inventory Management
      </Typography>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <FormControl sx={{ width: "300px" }}>
          <InputLabel id="warehouse-select-label" shrink>
            Select Warehouse
          </InputLabel>
          <Select
            labelId="warehouse-select-label"
            label="Select Warehouse"
            value={selectedWarehouse}
            onChange={handleSelectChange}
          >
            {uniqueWarehouses.map((warehouse) => (
              <MenuItem key={warehouse.ID} value={warehouse.name}>
                {warehouse.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button
          variant="contained"
          color="primary"
          onClick={() => handleMaxQuantity()}
        >
          Update Max Quantity Recipe
        </Button>

        {/* TextField cho tìm kiếm */}
        <TextField
          label="Search"
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ width: "300px" }} // Đặt độ rộng cho TextField tìm kiếm
        />
      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">ID</StyledTableCell>
              <StyledTableCell>Date</StyledTableCell>
              <StyledTableCell align="center">Warehouse</StyledTableCell>
              <StyledTableCell align="center">Name</StyledTableCell>
              <StyledTableCell align="center">Description</StyledTableCell>
              <StyledTableCell align="center">Quantity</StyledTableCell>
              <StyledTableCell align="center">Unit</StyledTableCell>
              <StyledTableCell align="center">UnitConversion</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.map((item, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell align="center">{index}</StyledTableCell>

                <StyledTableCell>
                  {" "}
                  {moment(item.StartDate).format("DD/MM/YYYY")}
                </StyledTableCell>

                <StyledTableCell align="center">
                  {" "}
                  {item.warehouse.name}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {item.material.name}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {item.material.description}
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  style={
                    item.quantity == 0
                      ? { color: "black", fontSize: 24 }
                      : item.quantity < 5
                      ? { color: "red", fontSize: 24 }
                      : item.quantity > 10
                      ? { color: "blue", fontSize: 24 }
                      : { color: "orange", fontSize: 24 }
                  }
                >
                  {item.quantity.toFixed(2)}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {item.material.unit}
                </StyledTableCell>

                <StyledTableCell align="right">
                  {item.quantities ? (
                    <div>
                      {Object.entries(item.quantities).map(([unit, qty]) => (
                        <div key={unit}>
                          {unit}: {qty.toFixed(2)}
                        </div>
                      ))}
                    </div>
                  ) : (
                    "N/A"
                  )}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default MaterialManagement;
