import { Add, Delete, Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Radio,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  UpdateSourceWarehouse,
  deleteWarehouse,
  fetchWarehouses,
} from "../../services/InventoryService";
import WarehouseForm from "./WarehouseForm";

const WarehouseTable = () => {
  const [warehouses, setWarehouses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openForm, setOpenForm] = useState(false);
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [sourceWarehouseID, setSourceWarehouseID] = useState(null); // Kho đã xác nhận
  const [tempWarehouse, setTempWarehouse] = useState(null); // Kho được chọn tạm thời
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false); // Trạng thái hiển thị popup

  const handleSetDefaultWareHouse = async (ID, tempWarehouse) => {
    try {
      await UpdateSourceWarehouse(ID, tempWarehouse);
      setSourceWarehouseID(ID);
      setOpenConfirmDialog(false);
      setSnackbar({
        open: true,
        message: "Warehouse updated successfully",
        severity: "success",
      });
    } catch (error) {
      console.log(error);
      setSnackbar({
        open: true,
        message: error.response.data.error,
        severity: "error",
      });
    }
  };

  const getWarehouses = async () => {
    try {
      const response = await fetchWarehouses();
      setWarehouses(response.data);
      // Lọc các kho có allow_export là true
      const allowedWarehouseIDs = response.data
        .filter((warehouse) => warehouse.allow_export === true)
        .map((warehouse) => warehouse.ID);
      setSourceWarehouseID(allowedWarehouseIDs[0]);
      console.log(allowedWarehouseIDs);

      // set default warehouse if not already default
      if (response.data.length > 0 && allowedWarehouseIDs.length === 0) {
        handleSetDefaultWareHouse(response.data[0].ID, response.data[0]);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getWarehouses();
  }, []);

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this warehouse?")) {
      try {
        await deleteWarehouse(id);
        setWarehouses(warehouses.filter((wh) => wh.id !== id));
        setSnackbar({
          open: true,
          message: "Warehouse deleted successfully",
          severity: "success",
        });
        getWarehouses();
      } catch (err) {
        setSnackbar({
          open: true,
          message: `Error: ${err.response?.data?.error || err.message}`,
          severity: "error",
        });
      }
    }
  };

  const handleEdit = (warehouse) => {
    setSelectedWarehouse(warehouse);
    setOpenForm(true);
  };

  const handleAdd = () => {
    console.log(warehouses.length);
    if (warehouses.length < 2) {
      setSelectedWarehouse(null);
      setOpenForm(true);
    }
  };

  const handleCloseForm = () => {
    setOpenForm(false);
  };

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="50vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="50vh"
      >
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  return (
    <Box p={3}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h5">Warehouse List</Typography>
        <IconButton color="primary" onClick={handleAdd}>
          <Add />
        </IconButton>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Warehouse Name</TableCell>
              <TableCell>Location</TableCell>
              <TableCell>source Ware house</TableCell>
              <TableCell>Type</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {warehouses?.map((warehouse) => (
              <TableRow key={warehouse.ID}>
                <TableCell>{warehouse.name}</TableCell>
                <TableCell>{warehouse.location}</TableCell>
                <TableCell>
                  <Radio
                    checked={sourceWarehouseID === warehouse.ID}
                    onChange={() => {
                      setTempWarehouse(warehouse);
                      setOpenConfirmDialog(true);
                    }}
                    value={warehouse.ID}
                  />
                </TableCell>
                <TableCell>{warehouse.type}</TableCell>
                <TableCell align="right">
                  <IconButton
                    color="primary"
                    onClick={() => handleEdit(warehouse)}
                  >
                    <Edit />
                  </IconButton>
                  {/* <IconButton
                    color="secondary"
                    onClick={() => handleDelete(warehouse.ID)}
                  >
                    <Delete />
                  </IconButton> */}
                </TableCell>
              </TableRow>
            ))}
            {warehouses?.length === 0 && (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  No data available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Dialog Form */}
      <Dialog open={openForm} onClose={handleCloseForm} fullWidth maxWidth="sm">
        <DialogTitle>
          {selectedWarehouse ? "Edit Warehouse" : "Add New Warehouse"}
        </DialogTitle>
        <DialogContent>
          <WarehouseForm
            existingWarehouse={selectedWarehouse}
            onClose={handleCloseForm}
            onSuccess={() => {
              getWarehouses();
              setSnackbar({
                open: true,
                message: selectedWarehouse
                  ? "Warehouse updated successfully"
                  : "Warehouse added successfully",
                severity: "success",
              });
            }}
            onError={(msg) => {
              setSnackbar({ open: true, message: msg, severity: "error" });
            }}
          />
        </DialogContent>
      </Dialog>

      {/* popup confirm sourceWarehouse */}
      <Dialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
      >
        <DialogTitle>Confirm</DialogTitle>
        <DialogContent>
          Are you sure you want to select this warehouse as the shipping
          warehouse?
          {/* Bạn có chắc chắn muốn chọn kho này làm kho xuất hàng không? */}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={async () => {
              tempWarehouse.allow_export = true;
              // User confirms
              handleSetDefaultWareHouse(tempWarehouse.ID, tempWarehouse);
            }}
            color="primary"
          >
            Confirm
          </Button>
          <Button
            onClick={() => {
              // User cancels
              setTempWarehouse(null);
              setOpenConfirmDialog(false);
            }}
            color="secondary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar Notification */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbar.message}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      />
    </Box>
  );
};

export default WarehouseTable;
