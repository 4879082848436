import axios from "axios";
import config from "../config";

const API_URL = config.apiBaseUrl;

export const fetchUnitConversions = (materialId) =>
  axios.get(`${API_URL}/material/${materialId}/unit-conversions`);
export const fetchConfigs = () => axios.get(`${API_URL}/app-config-new`);
export const createConfig = (data) => axios.post(`${API_URL}/app-config`, data);
export const updateConfig = (id, data) =>
  axios.put(`${API_URL}/app-config/${id}`, data);
export const deleteUnitConversion = (id) =>
  axios.delete(`${API_URL}/app-config/${id}`);

export const fetchRewards = () => axios.get(`${API_URL}/rewards`);
export const createRewards = (data) => axios.post(`${API_URL}/rewards`, data);
export const updateRewards = (id, data) =>
  axios.put(`${API_URL}/rewards/${id}`, data);
export const deleteRewards = (id) => axios.delete(`${API_URL}/rewards/${id}`);
