import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
  // import Grid from '@mui/material/Grid2';
  import { Form, Formik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import * as Yup from 'yup';
import InventoryService from '../../services/InventoryService'; // Import  service
import UnitService from "../../services/UnitService";
  
const MaterialForm = ({existing, onClose}) => {
  const { id } = useParams(); // Lấy id từ URL
  const [data, setData] = useState([]); //
  const [loading, setLoading] = useState(false); // State để kiểm tra quá trình tải dữ liệu
  const [measurements, setMeasurements] = useState([]);
  const navigate = useNavigate();
  
  const fetchMeasurements = useCallback(async () => {
        const response = await UnitService.getUnits();
        setMeasurements(response.data.dataTable);
        console.log("Unit",response.data.dataTable)
  }, []);
  
  useEffect(() => {
    fetchMeasurements();
  }, [
    fetchMeasurements,
  ]);

  // Fetch thông tin chiến dịch nếu có ID
  useEffect(() => {
    console.log('check value',existing)
    // if (id) {
    //   const fetchData = async () => {
    //     try {
    //       const response = await InventoryService.getCampaign(id);
    //       setData(response.data);
    //       console.log(response.data);
        
    //     } catch (error) {
    //       console.error('Failed to fetch campaign:', error);
    //     } finally {
    //       setLoading(false); // Đặt loading thành false sau khi hoàn tất fetch
    //     }
    //   };
    //   fetchData();
    // } else {
    //   setLoading(false); // Nếu không có ID thì đặt loading thành false
    // }
  }, [existing]);
    
  // Define the validation schema
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('name is required'),
    // description: Yup.string().required('Description is required'),
    unit: Yup.string().required('unit is required'),
  });
    
  // Nếu đang loading thì hiển thị thông báo hoặc một spinner
  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Formik
      initialValues={{
        isOpen: existing ? existing.IsOpen : true,
        name: existing ? existing.name : '',
        description: existing ? existing.description : '',
        unit: existing ? existing.unit : '',
      
      }}
      

      validationSchema={validationSchema} // Chỉ sử dụng schema cơ bản cho các trường không liên quan đến TopUp hay GiftCode
      onSubmit={async (values, { setSubmitting, setTouched, setErrors, errors  }) => {
        console.log('Errors:', validationSchema);
          // Kiểm tra xem có lỗi validation không
        let submittedData = {
          ...values,
        };

        
        try {
          if (existing){
            await InventoryService.updateMaterial(existing.ID, submittedData);
            toast.success("Material updated successfully!");
            onClose()
          }else{
            console.log('Đang gửi API vs data:', submittedData);
            const res = await InventoryService.createMaterial(submittedData);
            // console.log('res:', res);
            toast.success("Material created successfully!");
            onClose()
          }
          
          // alert('Campaign created successfully!');
        } catch (error) {
          toast.error("Failed to create Material");
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting, errors, touched, handleChange, handleBlur, values, setFieldValue }) => (

        <Form>
          <Box sx={{ p: 3 }}>
            <Grid container spacing={2}>
              

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Material Name"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name && errors.name}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Description"
                  name="description"
                  multiline
                  rows={4}
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.description && Boolean(errors.description)}
                  helperText={touched.description && errors.description}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h6" component="h3" gutterBottom>
                  Unit
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  {/* Set the maximum amount allowed for benefits under this campaign. */}
                </Typography>
                  <FormControl fullWidth  error={Boolean(touched.unit && errors.unit)}>
                      <InputLabel>unit</InputLabel>
                      <Select
                          name="unit"
                          label="unit"
                          value={values.unit}
                          onChange={handleChange}
                          onBlur={handleBlur}
                      >
                          {measurements.map((measurement) => (
                          <MenuItem key={measurement.ID} value={measurement.name}>
                              {measurement.name}
                          </MenuItem>
                          ))}
                      </Select>
                      <FormHelperText>{touched.unit && errors.unit}</FormHelperText>
                  </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Button variant="contained" color="primary" type="submit" disabled={id? true: false}>
                  {existing ? 'Update': 'Create '}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Form>
      )}
    </Formik>
  );
};
  
const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default MaterialForm;
  