import axios from "axios";
import config from "../config";

const API_URL = config.apiBaseUrl;

// Báo Cáo Doanh Thu APIs
export const fetchRevenueReport = (startDate, endDate, period) =>
  axios.get(`${API_URL}/report/revenue`, {
    params: { start_date: startDate, end_date: endDate, period: period },
  });

export const fetchTransactionMetadata = (transactionId) =>
  axios.get(`${API_URL}/transaction/${transactionId}/metadata`);

export const fetchOrderReport = (startDate, endDate) =>
  axios.get(`${API_URL}/report/orders`, {
    params: { start_date: startDate, end_date: endDate },
  });

export const fetchOrderTimeRangeReport = (
  startDate,
  endDate,
  startTime,
  endTime
) =>
  axios.get(`${API_URL}/report/ordersrange`, {
    params: {
      start_date: startDate,
      end_date: endDate,
      start_time: startTime,
      end_time: endTime,
    },
  });
