import { Add, Remove } from "@mui/icons-material";
import { DatePicker } from "@mui/lab";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import InventoryService, {
  fetchWarehouses,
} from "../../services/InventoryService";
import { fetchUnitConversions } from "../../services/unitConversionService";

const ExportRecordForm = ({ existingExport, onClose, onSuccess, onError }) => {
  const [warehouses, setWarehouses] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [unitConversions, setUnitConversions] = useState({}); // { materialId: [unitConversion1, unitConversion2, ...] }
  const [warehouseId, setWarehouseId] = useState(
    existingExport ? existingExport.warehouse_id : ""
  );
  const [exportedAt, setExportedAt] = useState(
    existingExport ? new Date(existingExport.exported_at) : new Date()
  );
  const [exportedBy, setExportedBy] = useState(
    existingExport ? existingExport.exported_by : ""
  );
  const [details, setDetails] = useState(
    existingExport ? existingExport.details : []
  );
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const getData = async () => {
      try {
        const [warehousesRes, materialsRes] = await Promise.all([
          fetchWarehouses(),
          InventoryService.getAllMaterial(),
        ]);
        setWarehouses(warehousesRes.data);
        setMaterials(materialsRes.data);

        // Fetch unit conversions for all materials
        const conversions = {};
        await Promise.all(
          materialsRes.data.map(async (material) => {
            const res = await fetchUnitConversions(material.id);
            conversions[material.id] = res.data;
          })
        );
        setUnitConversions(conversions);
      } catch (err) {
        onError(err.response?.data?.error || err.message);
      }
    };

    getData();
  }, [existingExport, onError]);

  const handleAddDetail = () => {
    setDetails([
      ...details,
      { material_id: "", from_unit: "", quantity: "", price: "" },
    ]);
  };

  const handleRemoveDetail = (index) => {
    const newDetails = [...details];
    newDetails.splice(index, 1);
    setDetails(newDetails);
  };

  const handleDetailChange = (index, field, value) => {
    const newDetails = [...details];
    newDetails[index][field] = value;

    // If material_id changes, reset from_unit and price
    if (field === "material_id") {
      newDetails[index]["from_unit"] = "";
      newDetails[index]["price"] = "";
    }

    setDetails(newDetails);
  };

  const handleMaterialChange = async (index, materialId) => {
    handleDetailChange(index, "material_id", materialId);
    // Optionally, set default from_unit and price based on selected material
    const material = materials.find((m) => m.ID === materialId);
    if (material) {
      const conversions = unitConversions[materialId];
      if (conversions && conversions.length > 0) {
        // Set from_unit to the first available conversion
        handleDetailChange(index, "from_unit", conversions[0].from_unit);
      } else {
        handleDetailChange(index, "from_unit", material.unit);
      }
      // Set default price to material's price
      handleDetailChange(index, "price", material.price);
    }
  };

  const validate = () => {
    let tempErrors = {};
    if (!warehouseId) tempErrors.warehouseId = "Warehouse is required";
    if (!exportedBy) tempErrors.exportedBy = "Exporter is required";
    if (details.length === 0)
      tempErrors.details = "At least one export detail is required";
    details.forEach((detail, index) => {
      if (!detail.material_id) {
        tempErrors[`details_${index}_material_id`] = "Material is required";
      }
      if (!detail.from_unit) {
        tempErrors[`details_${index}_from_unit`] = "Unit is required";
      }
      if (!detail.quantity || isNaN(detail.quantity) || detail.quantity <= 0) {
        tempErrors[`details_${index}_quantity`] =
          "Quantity must be a positive number";
      }
      if (!detail.price || isNaN(detail.price) || detail.price < 0) {
        tempErrors[`details_${index}_price`] =
          "Price must be a non-negative number";
      }
    });
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validate()) {
      onError("Please fill in all required information correctly");
      return;
    }

    const payload = {
      warehouse_id: warehouseId,
      exported_at: exportedAt.toISOString(),
      exported_by: exportedBy,
      details: details.map((detail) => ({
        material_id: detail.material_id,
        from_unit: detail.from_unit,
        quantity: parseFloat(detail.quantity),
        price: parseFloat(detail.price),
      })),
    };

    try {
      if (existingExport) {
        // await updateExportRecord(existingExport.id, payload);
      } else {
        await InventoryService.createExportRecord(payload);
      }
      onSuccess("Success: The operation was completed successfully.");
      onClose();
    } catch (error) {
      console.log("Error", error.response.data);
      onError(error.response.data.error || "An error occurred");
    }
  };

  return (
    // <LocalizationProvider dateAdapter={AdapterDateFns}>
    <Box component="form" onSubmit={handleSubmit} noValidate>
      <FormControl fullWidth margin="normal">
        <InputLabel id="warehouse-select-label">Warehouse</InputLabel>
        <Select
          labelId="warehouse-select-label"
          value={warehouseId}
          label="Warehouse"
          onChange={(e) => setWarehouseId(e.target.value)}
          error={!!errors.warehouseId}
        >
          {warehouses.map((wh) => (
            <MenuItem key={wh.ID} value={wh.ID}>
              {wh.name}
            </MenuItem>
          ))}
        </Select>
        {errors.warehouseId && (
          <FormHelperText error>{errors.warehouseId}</FormHelperText>
        )}
      </FormControl>

      <FormControl fullWidth margin="normal">
        <DatePicker
          label="Export Date"
          value={exportedAt}
          onChange={(newValue) => setExportedAt(newValue)}
          renderInput={(params) => <TextField {...params} />}
        />
      </FormControl>

      <FormControl fullWidth margin="normal">
        <TextField
          label="Exporter"
          value={exportedBy}
          onChange={(e) => setExportedBy(e.target.value)}
          error={!!errors.exportedBy}
          helperText={errors.exportedBy}
        />
      </FormControl>

      <Box mt={3}>
        <Typography variant="h6">Export Details</Typography>
        {errors.details && (
          <Typography variant="body2" color="error">
            {errors.details}
          </Typography>
        )}
        {details.map((detail, index) => (
          <Paper key={index} variant="outlined" sx={{ p: 2, mt: 2 }}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth margin="normal">
                  <InputLabel id={`material-select-label-${index}`}>
                    Material
                  </InputLabel>
                  <Select
                    labelId={`material-select-label-${index}`}
                    value={detail.material_id}
                    label="Material"
                    onChange={(e) =>
                      handleMaterialChange(index, e.target.value)
                    }
                    error={!!errors[`details_${index}_material_id`]}
                  >
                    {materials.map((material) => (
                      <MenuItem key={material.ID} value={material.ID}>
                        {material.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors[`details_${index}_material_id`] && (
                    <FormHelperText error>
                      {errors[`details_${index}_material_id`]}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={2}>
                <FormControl fullWidth margin="normal" disabled={true}>
                  <TextField
                    label="Unit"
                    disabled={true}
                    value={detail.from_unit}
                    onChange={(e) =>
                      handleDetailChange(index, "from_unit", e.target.value)
                    }
                    error={!!errors[`details_${index}_from_unit`]}
                    helperText={errors[`details_${index}_from_unit`]}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={2}>
                <FormControl fullWidth margin="normal">
                  <TextField
                    label="Quantity"
                    type="number"
                    inputProps={{ step: "any" }}
                    value={detail.quantity}
                    onChange={(e) =>
                      handleDetailChange(index, "quantity", e.target.value)
                    }
                    error={!!errors[`details_${index}_quantity`]}
                    helperText={errors[`details_${index}_quantity`]}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={2}>
                <FormControl fullWidth margin="normal">
                  <TextField
                    label="Price (VND)"
                    type="number"
                    inputProps={{ step: "any" }}
                    value={detail.price}
                    onChange={(e) =>
                      handleDetailChange(index, "price", e.target.value)
                    }
                    error={!!errors[`details_${index}_price`]}
                    helperText={errors[`details_${index}_price`]}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={1}>
                <IconButton
                  color="error"
                  onClick={() => handleRemoveDetail(index)}
                >
                  <Remove />
                </IconButton>
              </Grid>
            </Grid>
          </Paper>
        ))}

        <Button
          variant="contained"
          color="primary"
          startIcon={<Add />}
          onClick={handleAddDetail}
          sx={{ mt: 2 }}
        >
          Add Detail
        </Button>
      </Box>

      <Box mt={4} display="flex" justifyContent="flex-end">
        <Button
          onClick={onClose}
          color="secondary"
          variant="contained"
          sx={{ mr: 2 }}
        >
          Cancel
        </Button>
        <Button type="submit" color="primary" variant="contained">
          {existingExport ? "Update Export Record" : "Add Export Record"}
        </Button>
      </Box>
    </Box>
    // </LocalizationProvider>
  );
};

export default ExportRecordForm;
