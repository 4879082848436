import axios from "axios";
import config from "../config";

const API_URL = config.apiBaseUrl + "/units";

const getUnits = () => {
  return axios.get(API_URL);
};

const createUnit = (measurement) => {
  return axios.post(API_URL, measurement);
};

const updateUnit = (id, measurement) => {
  return axios.put(`${API_URL}/${id}`, measurement);
};

const deleteUnit = (id) => {
  return axios.delete(`${API_URL}/${id}`);
};

export default {
  getUnits,
  createUnit,
  updateUnit,
  deleteUnit,
};
