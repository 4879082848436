import axios from "axios";
import config from "../config";

const API_URL = config.apiBaseUrl + "/topups" ;

const TopupService = {
    // Get all topups
    GetAllTopups() {
        return axios.get(API_URL);
    },

    // Get a single topups by ID
    GetTopupById(id) {
        return axios.get(`${API_URL}/${id}`);
    },

    // Create a new topups
    CreateTopup(topupData) {
        return axios.post(API_URL, topupData);
    },

    // Update a topups by ID
    UpdateTopup(id, topupData) {
        return axios.put(`${API_URL}/${id}`, topupData);
    },

    // Delete a topups by ID
    DeleteTopup(id) {
        return axios.delete(`${API_URL}/${id}`);
    }
};

export default TopupService;
