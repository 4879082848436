import {
  Button,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  tableCellClasses,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // Sử dụng useNavigate
import { toast } from "react-toastify";
import config from "../../config";
import campaignService from "../../services/CampaignService"; // Sử dụng dịch vụ sản phẩm
import { COLORS } from "../../theme/themeColor";
const BaseUrl = config.apiBaseUrl;

const CampaignManagement = () => {
  const [campaign, setCampaign] = useState([]);
  const [filteredCampaign, setFilteredCampaign] = useState([]); // State cho danh sách sản phẩm đã lọc
  const [searchTerm, setSearchTerm] = useState(""); // State cho từ khóa tìm kiếm
  const [selectedcampaign, setSelectedcampaign] = useState(null);
  const [openAddcampaignDialog, setOpenAddcampaignDialog] = useState(false);
  const [newcampaign, setNewcampaign] = useState({
    name: "",
    description: "",
    price: 0,
  });
  const [error, setError] = useState("");
  const navigate = useNavigate(); // Thay thế useHistory bằng useNavigate

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: COLORS.BLUE,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: "0px 5px",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
    // height: 20,
  }));

  const fetchCampaign = async () => {
    const response = await campaignService.getAllCampaign();
    console.log("Campaign", response.data);
    setCampaign(response.data);
    if (response.data) {
      setFilteredCampaign(response.data); // Ban đầu hiển thị tất cả sản phẩm
    }
  };

  useEffect(() => {
    fetchCampaign();
  }, []);

  // Hàm để lọc sản phẩm dựa trên từ khóa tìm kiếm
  useEffect(() => {
    if (campaign) {
      const results = campaign.filter((campaign) =>
        Object.values(campaign).some(
          (value) =>
            typeof value === "string" &&
            value.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
      setFilteredCampaign(results);
    }
  }, [searchTerm, campaign]);

  const handleAddCampaign = async () => {
    navigate("/add-campaign"); // Điều hướng đến màn hình thêm sản phẩm
  };

  const handleEditCampaign = async (id) => {
    navigate(`/add-campaign/${id}`); // Điều hướng đến màn hình thêm sản phẩm
  };

  const handleChange1Checkbox = async (value) => {
    console.log("change check", value);
    value.IsOpen = !value.IsOpen;
    try {
      await campaignService.updateCampaign(value.ID, value);
      toast.success("Campaign updated successfully!");
      fetchCampaign();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <Typography variant="h4" gutterBottom>
        Campaign Management
      </Typography>

      {/* Tạo hàng ngang với nút "Add campaign" và ô tìm kiếm */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleAddCampaign()}
        >
          Add Campaign
        </Button>

        {/* TextField cho tìm kiếm */}
        <TextField
          label="Search"
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ width: "300px" }} // Đặt độ rộng cho TextField tìm kiếm
        />
      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>ID</StyledTableCell>
              <StyledTableCell>StartDate</StyledTableCell>
              <StyledTableCell>EndDate</StyledTableCell>
              <StyledTableCell align="center">Name</StyledTableCell>
              <StyledTableCell align="center">Description</StyledTableCell>
              <StyledTableCell align="center">TargetAudience</StyledTableCell>
              <StyledTableCell align="center">Type</StyledTableCell>
              <StyledTableCell>Open</StyledTableCell>
              <StyledTableCell align="right">Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredCampaign.map((campaign, index) => {
              const isExpired = moment().isAfter(moment(campaign.EndDate)); // Kiểm tra nếu EndDate đã qua

              return (
                <StyledTableRow
                  key={index}
                  style={{
                    opacity: isExpired ? 0.5 : 1, // Làm mờ nếu đã hết hạn
                    // pointerEvents: isExpired ? "none" : "auto", // Vô hiệu hóa các thao tác nếu cần
                  }}
                >
                  <StyledTableCell align="center">{index}</StyledTableCell>
                  <StyledTableCell>
                    {moment(campaign.StartDate).format("DD/MM/YYYY")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {moment(campaign.EndDate).format("DD/MM/YYYY")}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {campaign.name}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {campaign.Description}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {campaign.TargetAudience}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {campaign.ApplyTo}
                  </StyledTableCell>
                  <StyledTableCell>
                    <Checkbox
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                      checked={campaign.IsOpen}
                      onChange={() => handleChange1Checkbox(campaign)}
                      // disabled // Checkbox chỉ để hiển thị, không cho phép thay đổi
                    />
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <Button
                      variant="outlined"
                      onClick={() => handleEditCampaign(campaign.ID)}
                      style={{ marginRight: "10px" }}
                    >
                      Edit
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default CampaignManagement;
