// src/App.js

import {
    Box,
    CircularProgress,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Inventory from '../../services/InventoryService';
import UnitConversionTable from './UnitConversionList';

function UnitConversion() {
    const [materials, setMaterials] = useState([]);
    const [selectedMaterial, setSelectedMaterial] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const getMaterials = async () => {
        try {
            const response = await Inventory.getAllMaterial();
            console.log(response.data)
            setMaterials(response.data);
            if (response.data.length > 0) {
                setSelectedMaterial(response.data[0].ID);
            }
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getMaterials();
    }, []);

    const handleMaterialChange = (e) => {
        setSelectedMaterial(e.target.value);
    };

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                <Typography variant="h6" color="error">
                    {error}
                </Typography>
            </Box>
        );
    }

    return (
        <React.Fragment>
            {/* <CssBaseline /> */}
            {/* <Container maxWidth="lg"> */}
            <Box p={3}>
                <Typography variant="h4" gutterBottom>
                    Manage Material Unit Conversion
                </Typography>
                <FormControl fullWidth margin="normal">
                    <InputLabel id="material-select-label">Select Material</InputLabel>
                    <Select
                        labelId="material-select-label"
                        value={selectedMaterial}
                        label="Select Material"
                        onChange={handleMaterialChange}
                    >
                        {materials.map((material, idx) => (
                            <MenuItem key={idx} value={material.ID}>
                                {material.name}
                            </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>Select a material to view unit conversions</FormHelperText>
                </FormControl>
                {selectedMaterial && <UnitConversionTable materialId={selectedMaterial} />}
            </Box>

            {/* </Container> */}
        </React.Fragment>
    );
}

export default UnitConversion;
