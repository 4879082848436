import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  tableCellClasses,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // Sử dụng useNavigate
import config from "../../config";
import InventoryService from "../../services/InventoryService";
import { COLORS } from "../../theme/themeColor";
import ImportForm from "./ImportRecordForm";
const BaseUrl = config.apiBaseUrl;

const MaterialManagement = () => {
  const [campaign, setCampaign] = useState([]);
  const [filteredMaterial, setFilteredMaterial] = useState([]); // State cho danh sách sản phẩm đã lọc
  const [searchTerm, setSearchTerm] = useState(""); // State cho từ khóa tìm kiếm
  const [selectedcampaign, setSelectedcampaign] = useState(null);
  const [openAddcampaignDialog, setOpenAddcampaignDialog] = useState(false);
  const [newcampaign, setNewcampaign] = useState({
    name: "",
    description: "",
    price: 0,
  });
  const [error, setError] = useState("");
  const navigate = useNavigate(); // Thay thế useHistory bằng useNavigate

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: COLORS.BLUE,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: "0px 5px",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
    // height: 20,
  }));

  const getImportRecords = async () => {
    const response = await InventoryService.getAllRecords();
    console.log("getImportRecords", response.data);
    setCampaign(response.data);
    if (response.data) {
      setFilteredMaterial(response.data); // Ban đầu hiển thị tất cả sản phẩm
    }
  };
  useEffect(() => {
    getImportRecords();
  }, []);

  // Hàm để lọc sản phẩm dựa trên từ khóa tìm kiếm
  useEffect(() => {
    if (campaign) {
      const results = campaign.filter((campaign) =>
        Object.values(campaign).some(
          (value) =>
            typeof value === "string" &&
            value.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
      setFilteredMaterial(results);
    }
  }, [searchTerm, campaign]);

  const handleAddItem = async () => {
    navigate("/importRecordForm"); // Điều hướng đến màn hình thêm sản phẩm
  };

  const handleEditItem = async (id) => {
    navigate(`/importRecordForm/${id}`); // Điều hướng đến màn hình thêm sản phẩm
  };

  return (
    <div style={{ width: "100%" }}>
      <Typography variant="h4" gutterBottom>
        Import Receipt
      </Typography>

      {/* Tạo hàng ngang với nút "Add campaign" và ô tìm kiếm */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <ImportForm
          onSuccess={() => {
            getImportRecords();
            // setSnackbar({ open: true, message: selectedExport ? 'Cập nhật phiếu xuất thành công' : 'Thêm phiếu xuất thành công', severity: 'success' });
          }}
        />

        {/* TextField cho tìm kiếm */}
        <TextField
          label="Search"
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ width: "300px" }} // Đặt độ rộng cho TextField tìm kiếm
        />
      </div>

      <TableContainer component={Paper}>
        <Table aria-label="adjustment table">
          <TableHead>
            <TableRow>
              <StyledTableCell rowSpan={2}>ID</StyledTableCell>
              <StyledTableCell rowSpan={2}>Date</StyledTableCell>
              <StyledTableCell rowSpan={2}>Name</StyledTableCell>
              <StyledTableCell rowSpan={2}>Warehouse</StyledTableCell>
              <StyledTableCell
                colSpan={6}
                align="center"
                sx={{ backgroundColor: "#1976d2", color: "white" }}
              >
                <Typography variant="subtitle1" color="inherit">
                  Details
                </Typography>
              </StyledTableCell>
            </TableRow>
            <TableRow>
              {/* <TableCell colSpan={6} /> */}

              <StyledTableCell
                sx={{ backgroundColor: "#1976d2", color: "white" }}
              >
                FromQuantity
              </StyledTableCell>
              <StyledTableCell
                sx={{ backgroundColor: "#1976d2", color: "white" }}
              >
                FromUnit
              </StyledTableCell>
              <StyledTableCell
                sx={{ backgroundColor: "#1976d2", color: "white" }}
              >
                Material Name
              </StyledTableCell>
              <StyledTableCell
                sx={{ backgroundColor: "#1976d2", color: "white" }}
              >
                Quantity
              </StyledTableCell>
              <StyledTableCell
                sx={{ backgroundColor: "#1976d2", color: "white" }}
              >
                Unit
              </StyledTableCell>
              <StyledTableCell
                sx={{ backgroundColor: "#1976d2", color: "white" }}
              >
                Price
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredMaterial.map((row, idx) => (
              <React.Fragment key={row.ID}>
                {row.details.map((detail, index) => (
                  <TableRow key={`${row.ID}-${index}`}>
                    {index === 0 && (
                      <>
                        <TableCell rowSpan={row.details.length}>
                          {idx + 1}
                        </TableCell>
                        <TableCell rowSpan={row.details.length}>
                          {moment(row.imported_at).format("DD/MM/YYYY HH:mm")}
                        </TableCell>
                        <TableCell rowSpan={row.details.length}>
                          {row.imported_by}
                        </TableCell>
                        <TableCell rowSpan={row.details.length}>
                          {row.warehouse.name}
                        </TableCell>
                      </>
                    )}
                    <TableCell>{detail.from_quantity}</TableCell>
                    <TableCell>{detail.from_unit}</TableCell>
                    <TableCell>{detail.material.name}</TableCell>
                    <TableCell>{detail.quantity}</TableCell>
                    <TableCell>{detail.material.unit}</TableCell>
                    <TableCell>{detail.price}</TableCell>
                  </TableRow>
                ))}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default MaterialManagement;
