import { AddCircle, RemoveCircle } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { FieldArray, Form, Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import InventoryService, {
  fetchWarehouses,
} from "../../services/InventoryService";
import {
  fetchUnitConversions,
  fetchAllUnitConversions,
} from "../../services/unitConversionService";
import { toast } from "react-toastify";

const ImportForm = ({ onSuccess }) => {
  const [open, setOpen] = useState(false);
  const [materials, setMaterials] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [unitConversions, setUnitConversions] = useState({});
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  useEffect(() => {
    const loadData = async () => {
      try {
        const [materialsRes, warehousesRes] = await Promise.all([
          InventoryService.getAllMaterial(),
          fetchWarehouses(),
        ]);
        setMaterials(materialsRes.data);
        setWarehouses(warehousesRes.data);

        // Fetch unit conversions for each material
        const res = await fetchAllUnitConversions();
        // console.log("unit", res.data);
        const conversions = {};
        for (let material of materialsRes.data) {
          conversions[material.ID] = []; // Tạo mảng rỗng nếu chưa có
          for (let item of res.data) {
            if (item.material_id == material.ID) {
              conversions[material.ID].push(item);
              // console.log("material", material.ID, item);
            }
          }
        }

        setUnitConversions(conversions);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
      console.log("load finish");
      //   onSuccess();
    };

    loadData();
  }, []);

  const initialValues = {
    warehouse_id: "",
    imported_at: "",
    imported_by: "",
    details: [
      {
        material_id: "",
        from_unit: "",
        quantity: "",
        price: "",
      },
    ],
  };

  const validationSchema = Yup.object().shape({
    warehouse_id: Yup.number().required("Warehouse is required"),
    // imported_at: Yup.date().required('Import date is required'),
    imported_by: Yup.string().required("Importer is required"),
    details: Yup.array()
      .of(
        Yup.object().shape({
          material_id: Yup.number().required("Material is required"),
          from_unit: Yup.string().required("Import unit is required"),
          quantity: Yup.number()
            .positive("Quantity must be a positive number")
            .required("Quantity is required"),
          price: Yup.number()
            .positive("Price must be a positive number")
            .required("Price is required"),
        })
      )
      .min(1, "At least one import detail is required"),
  });

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      // Prepare data by converting quantities to default units
      const convertedDetails = await Promise.all(
        values.details.map(async (detail) => {
          const material = materials.find((m) => m.ID === detail.material_id);
          const defaultUnit = material.unit;
          const fromUnit = detail.from_unit;
          const quantity = parseFloat(detail.quantity);
          let convertedQuantity = quantity;

          if (fromUnit !== defaultUnit) {
            const conversion = unitConversions[material.ID].find(
              (uc) => uc.from_unit === fromUnit && uc.to_unit === defaultUnit
            );

            if (conversion) {
              convertedQuantity = quantity * conversion.factor;
            } else {
              throw new Error(
                `Conversion from ${fromUnit} to ${defaultUnit} for material ${material.name} not found`
              );
            }

            console.log("conversion", conversion, convertedQuantity);
          }

          return {
            material_id: detail.material_id,
            from_unit: detail.from_unit,
            from_quantity: quantity,
            quantity: convertedQuantity,
            converted_quantity: convertedQuantity,
            price: parseFloat(detail.price),
          };
        })
      );

      const imported_at = new Date().toISOString();
      const payload = {
        warehouse_id: values.warehouse_id,
        imported_at: imported_at,
        imported_by: values.imported_by,
        details: convertedDetails,
      };

      console.log("check", payload);

      await InventoryService.createRecord(payload);
      onSuccess();
      toast.success("Import record created successfully");
      // setSnackbar({
      //   open: true,
      //   message: "Import record created successfully",
      //   severity: "success",
      // });
      resetForm();
      setOpen(false);
    } catch (error) {
      console.error("Error creating import record:", error);
      toast.error(error.message || "An error occurred");
      // setSnackbar({
      //   open: true,
      //   message: error.message || "An error occurred",
      //   severity: "error",
      // });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Box>
      <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
        Create Import Record
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          Create Import Record {moment(new Date()).format("DD/MM/YYYY")}
        </DialogTitle>
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="50vh"
          >
            <CircularProgress />
          </Box>
        ) : (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              isSubmitting,
            }) => (
              <Form>
                <DialogContent>
                  <Box display="flex" flexDirection="column" gap={2}>
                    <FormControl fullWidth>
                      <InputLabel id="warehouse-label">Warehouse</InputLabel>
                      <Select
                        labelId="warehouse-label"
                        id="warehouse_id"
                        name="warehouse_id"
                        value={values.warehouse_id}
                        label="Warehouse"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.warehouse_id && Boolean(errors.warehouse_id)
                        }
                      >
                        {warehouses.map((warehouse) => (
                          <MenuItem key={warehouse.ID} value={warehouse.ID}>
                            {warehouse.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {touched.warehouse_id && errors.warehouse_id && (
                        <Typography color="error" variant="caption">
                          {errors.warehouse_id}
                        </Typography>
                      )}
                    </FormControl>

                    {/* <TextField
                                        label="Import Date"
                                        type="date"
                                        name="imported_at"
                                        InputLabelProps={{ shrink: true }}
                                        value={values.imported_at}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={touched.imported_at && Boolean(errors.imported_at)}
                                        helperText={touched.imported_at && errors.imported_at}
                                        fullWidth
                                    /> */}
                    {/* <Typography variant="h4" gutterBottom>
                                        
                                    </Typography> */}
                    <TextField
                      label="Importer"
                      name="imported_by"
                      value={values.imported_by}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.imported_by && Boolean(errors.imported_by)}
                      helperText={touched.imported_by && errors.imported_by}
                      fullWidth
                    />
                    <FieldArray name="details">
                      {({ push, remove }) => (
                        <Box>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            mb={1}
                          >
                            <Typography variant="h6">
                              Import Record Details
                            </Typography>
                            <IconButton
                              color="primary"
                              onClick={() =>
                                push({
                                  material_id: "",
                                  from_unit: "",
                                  quantity: "",
                                  price: "",
                                })
                              }
                            >
                              <AddCircle />
                            </IconButton>
                          </Box>
                          {values.details.map((detail, index) => {
                            const material = materials.find(
                              (m) => m.ID === detail.material_id
                            );
                            const availableUnits =
                              unitConversions && detail.material_id
                                ? unitConversions[detail.material_id]?.map(
                                    (uc) => uc.from_unit
                                  )
                                : [];

                            return (
                              <Box
                                key={index}
                                display="flex"
                                flexDirection="column"
                                gap={1}
                                mb={2}
                                p={2}
                                border={1}
                                borderRadius={2}
                                borderColor="grey.300"
                              >
                                <Box display="flex" justifyContent="flex-end">
                                  <IconButton
                                    color="secondary"
                                    onClick={() => remove(index)}
                                  >
                                    <RemoveCircle />
                                  </IconButton>
                                </Box>
                                <FormControl fullWidth>
                                  <InputLabel
                                    id={`details.${index}.material_id-label`}
                                  >
                                    Material
                                  </InputLabel>
                                  <Select
                                    labelId={`details.${index}.material_id-label`}
                                    id={`details.${index}.material_id`}
                                    name={`details.${index}.material_id`}
                                    value={detail.material_id}
                                    label="Material"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={
                                      touched.details &&
                                      touched.details[index] &&
                                      touched.details[index].material_id &&
                                      Boolean(
                                        errors.details?.[index]?.material_id
                                      )
                                    }
                                  >
                                    {materials.map((material) => (
                                      <MenuItem
                                        key={material.ID}
                                        value={material.ID}
                                      >
                                        {material.name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                  {touched.details &&
                                    touched.details[index] &&
                                    touched.details[index].material_id &&
                                    errors.details?.[index]?.material_id && (
                                      <Typography
                                        color="error"
                                        variant="caption"
                                      >
                                        {errors.details[index].material_id}
                                      </Typography>
                                    )}
                                </FormControl>
                                {detail.material_id && (
                                  <FormControl fullWidth>
                                    <InputLabel
                                      id={`details.${index}.from_unit-label`}
                                    >
                                      Import Unit
                                    </InputLabel>
                                    <Select
                                      labelId={`details.${index}.from_unit-label`}
                                      id={`details.${index}.from_unit`}
                                      name={`details.${index}.from_unit`}
                                      value={detail.from_unit}
                                      label="Import Unit"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      error={
                                        touched.details &&
                                        touched.details[index] &&
                                        touched.details[index].from_unit &&
                                        Boolean(
                                          errors.details?.[index]?.from_unit
                                        )
                                      }
                                    >
                                      {unitConversions[detail.material_id]?.map(
                                        (uc) => (
                                          <MenuItem
                                            key={uc.ID}
                                            value={uc.from_unit}
                                          >
                                            {uc.from_unit}
                                          </MenuItem>
                                        )
                                      )}
                                    </Select>
                                    {touched.details &&
                                      touched.details[index] &&
                                      touched.details[index].from_unit &&
                                      errors.details?.[index]?.from_unit && (
                                        <Typography
                                          color="error"
                                          variant="caption"
                                        >
                                          {errors.details[index].from_unit}
                                        </Typography>
                                      )}
                                  </FormControl>
                                )}
                                <TextField
                                  label="Quantity"
                                  name={`details.${index}.quantity`}
                                  type="number"
                                  value={detail.quantity}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={
                                    touched.details &&
                                    touched.details[index] &&
                                    touched.details[index].quantity &&
                                    Boolean(errors.details?.[index]?.quantity)
                                  }
                                  helperText={
                                    touched.details &&
                                    touched.details[index] &&
                                    touched.details[index].quantity &&
                                    errors.details?.[index]?.quantity
                                  }
                                  fullWidth
                                />
                                <TextField
                                  label="Price Per Unit"
                                  name={`details.${index}.price`}
                                  type="number"
                                  value={detail.price}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={
                                    touched.details &&
                                    touched.details[index] &&
                                    touched.details[index].price &&
                                    Boolean(errors.details?.[index]?.price)
                                  }
                                  helperText={
                                    touched.details &&
                                    touched.details[index] &&
                                    touched.details[index].price &&
                                    errors.details?.[index]?.price
                                  }
                                  fullWidth
                                />
                              </Box>
                            );
                          })}
                          {typeof errors.details === "string" && (
                            <Typography color="error" variant="caption">
                              {errors.details}
                            </Typography>
                          )}
                        </Box>
                      )}
                    </FieldArray>
                  </Box>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => setOpen(false)}
                    color="secondary"
                    variant="contained"
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <CircularProgress size={24} />
                    ) : (
                      "Create Import Record"
                    )}
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        )}
      </Dialog>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        message={snackbar.message}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      />
    </Box>
  );
};

export default ImportForm;
