import { Add, Remove } from "@mui/icons-material";
import { DatePicker } from "@mui/lab";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import InventoryService, {
  createAdjustmentRecord,
  fetchWarehouses,
} from "../../services/InventoryService";
import { fetchUnitConversions } from "../../services/unitConversionService";

const AdjustmentRecordForm = ({
  existingAdjustment,
  onClose,
  onSuccess,
  onError,
}) => {
  const [warehouses, setWarehouses] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [unitConversions, setUnitConversions] = useState({}); // { materialId: [unitConversion1, unitConversion2, ...] }
  const [warehouseId, setWarehouseId] = useState(
    existingAdjustment ? existingAdjustment.warehouse_id : ""
  );
  const [adjustedAt, setAdjustedAt] = useState(
    existingAdjustment ? new Date(existingAdjustment.adjusted_at) : new Date()
  );
  const [adjustedBy, setAdjustedBy] = useState(
    existingAdjustment ? existingAdjustment.adjusted_by : ""
  );
  const [details, setDetails] = useState(
    existingAdjustment ? existingAdjustment.details : []
  );
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const getData = async () => {
      try {
        const [warehousesRes, materialsRes] = await Promise.all([
          fetchWarehouses(),
          InventoryService.getAllMaterial(),
        ]);
        setWarehouses(warehousesRes.data);
        setMaterials(materialsRes.data);

        // Fetch unit conversions for all materials
        // const conversions = {};
        // await Promise.all(
        //   materialsRes.data.map(async (material) => {
        //     console.log("material", material);
        //     const res = await fetchUnitConversions(material.ID);
        //     conversions[material.ID] = res.data;
        //   })
        // );
        // setUnitConversions(conversions);
      } catch (err) {
        onError(err.response?.data?.error || err.message);
      }
    };

    getData();
  }, [existingAdjustment, onError]);

  const handleAddDetail = () => {
    setDetails([
      ...details,
      { material_id: "", from_unit: "", quantity: "", reason: "", type: "" },
    ]);
  };

  const handleRemoveDetail = (index) => {
    const newDetails = [...details];
    newDetails.splice(index, 1);
    setDetails(newDetails);
  };

  const handleDetailChange = (index, field, value) => {
    const newDetails = [...details];
    newDetails[index][field] = value;

    // Reset from_unit and type if material_id changes
    if (field === "material_id") {
      newDetails[index]["from_unit"] = "";
      newDetails[index]["type"] = "";
    }

    setDetails(newDetails);
  };

  const handleMaterialChange = async (index, materialId) => {
    handleDetailChange(index, "material_id", materialId);
    const conversions = unitConversions[materialId];
    if (conversions && conversions.length > 0) {
      handleDetailChange(index, "from_unit", conversions[0].from_unit);
    } else {
      const material = materials.find((m) => m.ID === materialId);
      if (material) {
        handleDetailChange(index, "from_unit", material.unit);
      }
    }
  };

  const validate = () => {
    let tempErrors = {};
    if (!warehouseId) tempErrors.warehouseId = "Warehouse is required";
    if (!adjustedBy) tempErrors.adjustedBy = "Adjusted by is required";
    if (details.length === 0)
      tempErrors.details = "At least one adjustment detail is required";
    details.forEach((detail, index) => {
      if (!detail.material_id) {
        tempErrors[`details_${index}_material_id`] = "Material is required";
      }
      if (!detail.from_unit) {
        tempErrors[`details_${index}_from_unit`] = "Unit is required";
      }
      if (!detail.quantity || isNaN(detail.quantity)) {
        tempErrors[`details_${index}_quantity`] = "Quantity must be a number";
      }
      if (detail.quantity <= 0) {
        tempErrors[`details_${index}_quantity`] =
          "Quantity must be greater than 0";
      }
      if (!detail.reason) {
        tempErrors[`details_${index}_reason`] = "Reason is required";
      }
      if (
        !detail.type ||
        (detail.type !== "Increase" && detail.type !== "Decrease")
      ) {
        tempErrors[`details_${index}_type`] =
          'Adjustment type must be "Increase" or "Decrease"';
      }
    });
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validate()) {
      onError("Please fill in all required fields");
      return;
    }

    const payload = {
      warehouse_id: warehouseId,
      adjusted_at: adjustedAt.toISOString(),
      adjusted_by: adjustedBy,
      details: details.map((detail) => ({
        material_id: detail.material_id,
        from_unit: detail.from_unit,
        quantity: parseFloat(detail.quantity),
        reason: detail.reason,
        type: detail.type,
      })),
    };

    try {
      if (existingAdjustment) {
        // await updateAdjustmentRecord(existingAdjustment.id, payload);
      } else {
        await createAdjustmentRecord(payload);
      }
      onSuccess();
      onClose();
    } catch (err) {
      onError(err.response?.data?.error || "An error occurred");
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate>
      <FormControl fullWidth margin="normal">
        <InputLabel id="warehouse-select-label">Warehouse</InputLabel>
        <Select
          labelId="warehouse-select-label"
          value={warehouseId}
          label="Warehouse"
          onChange={(e) => setWarehouseId(e.target.value)}
          error={!!errors.warehouseId}
        >
          {warehouses.map((wh) => (
            <MenuItem key={wh.ID} value={wh.ID}>
              {wh.name}
            </MenuItem>
          ))}
        </Select>
        {errors.warehouseId && (
          <FormHelperText error>{errors.warehouseId}</FormHelperText>
        )}
      </FormControl>

      <FormControl fullWidth margin="normal">
        <DatePicker
          label="Adjustment Date"
          value={adjustedAt}
          onChange={(newValue) => setAdjustedAt(newValue)}
          renderInput={(params) => <TextField {...params} />}
        />
      </FormControl>

      <FormControl fullWidth margin="normal">
        <TextField
          label="Adjusted By"
          value={adjustedBy}
          onChange={(e) => setAdjustedBy(e.target.value)}
          error={!!errors.adjustedBy}
          helperText={errors.adjustedBy}
        />
      </FormControl>

      <Box mt={3}>
        <Typography variant="h6">Adjustment Details</Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<Add />}
          onClick={handleAddDetail}
          sx={{ mt: 2 }}
        >
          Add Detail
        </Button>
        {errors.details && (
          <Typography variant="body2" color="error">
            {errors.details}
          </Typography>
        )}
        {details.map((detail, index) => (
          <Paper key={index} variant="outlined" sx={{ p: 2, mt: 2 }}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={2}>
                <FormControl fullWidth margin="normal">
                  <InputLabel id={`material-select-label-${index}`}>
                    Material
                  </InputLabel>
                  <Select
                    labelId={`material-select-label-${index}`}
                    value={detail.material_id}
                    label="Material"
                    onChange={(e) =>
                      handleMaterialChange(index, e.target.value)
                    }
                    error={!!errors[`details_${index}_material_id`]}
                  >
                    {materials.map((material) => (
                      <MenuItem key={material.ID} value={material.ID}>
                        {material.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors[`details_${index}_material_id`] && (
                    <FormHelperText error>
                      {errors[`details_${index}_material_id`]}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={2}>
                <FormControl fullWidth margin="normal">
                  <TextField
                    label="Unit"
                    value={detail.from_unit}
                    onChange={(e) =>
                      handleDetailChange(index, "from_unit", e.target.value)
                    }
                    error={!!errors[`details_${index}_from_unit`]}
                    helperText={errors[`details_${index}_from_unit`]}
                    disabled
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={2}>
                <FormControl fullWidth margin="normal">
                  <TextField
                    label="Quantity"
                    type="number"
                    inputProps={{ step: "any", min: "0" }}
                    value={detail.quantity}
                    onChange={(e) =>
                      handleDetailChange(index, "quantity", e.target.value)
                    }
                    error={!!errors[`details_${index}_quantity`]}
                    helperText={errors[`details_${index}_quantity`]}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth margin="normal">
                  <TextField
                    label="Reason"
                    value={detail.reason}
                    onChange={(e) =>
                      handleDetailChange(index, "reason", e.target.value)
                    }
                    error={!!errors[`details_${index}_reason`]}
                    helperText={errors[`details_${index}_reason`]}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={2}>
                <FormControl
                  fullWidth
                  margin="normal"
                  PaperProps={{
                    style: {
                      width: "1200px", // Đặt chiều rộng tùy chỉnh
                      maxWidth: "100%", // Hoặc giới hạn tối đa theo tỷ lệ
                    },
                  }}
                >
                  <InputLabel id={`type-select-label-${index}`}>
                    Type
                  </InputLabel>
                  <Select
                    labelId={`type-select-label-${index}`}
                    value={detail.type}
                    label="Type"
                    onChange={(e) =>
                      handleDetailChange(index, "type", e.target.value)
                    }
                    error={!!errors[`details_${index}_type`]}
                  >
                    <MenuItem value="Increase">Increase</MenuItem>
                    <MenuItem value="Decrease">Decrease</MenuItem>
                  </Select>
                  {errors[`details_${index}_type`] && (
                    <FormHelperText error>
                      {errors[`details_${index}_type`]}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={1}>
                <IconButton
                  color="error"
                  onClick={() => handleRemoveDetail(index)}
                >
                  <Remove />
                </IconButton>
              </Grid>
            </Grid>
          </Paper>
        ))}
      </Box>

      <Box mt={4} display="flex" justifyContent="flex-end">
        <Button
          onClick={onClose}
          color="secondary"
          variant="contained"
          sx={{ mr: 2 }}
        >
          Cancel
        </Button>
        <Button type="submit" color="primary" variant="contained">
          {existingAdjustment
            ? "Update Adjustment Record"
            : "Add Adjustment Record"}
        </Button>
      </Box>
    </Box>
  );
};

export default AdjustmentRecordForm;
