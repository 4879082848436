import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Collapse,
  Paper,
  Box,
  IconButton,
  TableContainer,
  CircularProgress,
} from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Service from "../../services/StoreStatus"; // Import the service
import { styled } from "@mui/material/styles";
import {
  fetchTaxes,
  createTax,
  updateTax,
  deleteTax,
} from "../../services/StoreStatus"; // Import API service functions
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import { format, utcToZonedTime } from "date-fns-tz";
import moment from "moment-timezone";

// Hàm định dạng ngày theo múi giờ Canada/Vancouver
const formatDate = (isoDate) => {
  return moment.tz(isoDate, "America/Vancouver").format("DD/MM/YYYY");
};

// Ví dụ sử dụng
const isoDate = "2024-10-20T00:00:00Z";
console.log(formatDate(isoDate)); // Kết quả: 19/10/2024 (nếu Vancouver đang dùng GMT-7)

// Hàm định dạng ngày theo múi giờ Canada/Vancouver
// const formatDate = (isoDate) => {
//   const timeZone = "America/Vancouver"; // Múi giờ Canada/Vancouver
//   const zonedDate = utcToZonedTime(isoDate, timeZone); // Chuyển sang múi giờ
//   return format(zonedDate, "dd/MM/yyyy"); // Định dạng ngày tháng năm
// };

// // Ví dụ sử dụng
// const isoDate = "2024-10-20T00:00:00Z";
// console.log(formatDate(isoDate)); // Kết quả: 19/10/2024 (nếu Vancouver đang dùng GMT-7)

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  // textAlign: 'center',
  color: theme.palette.text.secondary,
  ...theme.applyStyles("dark", {
    backgroundColor: "#1A2027",
  }),
}));

const TaxList = () => {
  const [taxes, setTaxes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingRow, setEditingRow] = useState(null);
  const [originalValues, setOriginalValues] = useState(null); // Lưu giá trị gốc khi chỉnh sửa

  // Lấy danh sách taxes từ API
  const fetchTaxe = async () => {
    try {
      setLoading(true);
      const res = await fetchTaxes();
      setTaxes(res.data);
    } catch (error) {
      console.error("Error fetching taxes:", error);
    } finally {
      setLoading(false);
    }
  };

  // Lưu thông tin tax và lịch trình
  const saveTax = async (id, updatedTax) => {
    try {
      const updatedTaxes = taxes.map((tax) =>
        tax.id === id ? updatedTax : tax
      );
      setTaxes(updatedTaxes); // Cập nhật UI ngay lập tức
      await createTax(updatedTax); // Cập nhật trên server
    } catch (error) {
      console.error("Error updating tax:", error);
    }
  };

  // // Xử lý chỉnh sửa
  // const handleEdit = (tax) => {
  //   setEditingRow(tax.id);
  // };

  // Xử lý khi vào chế độ chỉnh sửa
  const handleEdit = (tax) => {
    setEditingRow(tax.id); // Ghi nhận ID hàng đang chỉnh sửa
    setOriginalValues({ ...tax }); // Lưu giá trị gốc để hiển thị
  };

  // Xử lý lưu chỉnh sửa
  const handleSave = async (tax) => {
    console.log("check save", tax);
    try {
      const updatedTax = {
        ...tax,
        schedules: {
          ...tax.schedules,
          startDate: formatDateWithTime(tax.startDate), // Xử lý startDate
          endDate: formatDateWithTime(tax.endDate, true), // Xử lý endDate
        },
      };

      await updateTax(tax.id, updatedTax);
      // setEditingRow(null); // Thoát chế độ chỉnh sửa

      // Cập nhật danh sách
      const updatedTaxes = taxes.map((t) => (t.id === tax.id ? tax : t));
      setTaxes(updatedTaxes);

      // Thoát chế độ chỉnh sửa
      setEditingRow(null);
      setOriginalValues(null);
    } catch (error) {
      console.log(error);
    }
  };

  // Xử lý khi hủy chỉnh sửa
  const handleCancel = () => {
    setEditingRow(null); // Thoát chế độ chỉnh sửa
    setOriginalValues(null); // Xóa giá trị gốc
  };

  const formatDateWithTime = (date, isEndDate = false) => {
    const d = new Date(date);
    if (isEndDate) {
      // Đặt giờ cho endDate là 23:59:59
      d.setHours(23, 59, 59, 999);
    } else {
      // Đặt giờ cho startDate là 00:00:00
      d.setHours(0, 0, 0, 0);
    }
    return d.toISOString(); // Chuyển đổi sang định dạng ISO 8601
  };

  // // Xử lý khi chỉnh sửa giá trị
  const handleChange = (id, field, value) => {
    const updatedTaxes = taxes.map((tax) => {
      // Kiểm tra nếu field đang được chỉnh sửa là số
      const parsedValue =
        field === "default_value" || field === "value"
          ? parseFloat(value)
          : value;

      return tax.id === id
        ? {
            ...tax,
            [field]: parsedValue,
            schedules:
              field in tax.schedules
                ? { ...tax.schedules, [field]: parsedValue }
                : tax.schedules,
          }
        : tax;
    });

    setTaxes(updatedTaxes);
  };

  // Gọi fetchTaxes khi component mount
  useEffect(() => {
    fetchTaxe();
  }, []);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <TableContainer component={Paper}>
      <Typography variant="h6" align="center" gutterBottom>
        Tax List
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Default Value</TableCell>
            <TableCell>Start Date</TableCell>
            <TableCell>End Date</TableCell>
            <TableCell>Value</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {taxes.map((tax) => (
            <TableRow key={tax.id}>
              <TableCell>{tax.id}</TableCell>
              <TableCell>{tax.name}</TableCell>
              <TableCell>{tax.description}</TableCell>
              <TableCell>
                {editingRow === tax.id ? (
                  <TextField
                    type="number"
                    value={tax.default_value}
                    onChange={(e) =>
                      handleChange(tax.id, "default_value", e.target.value)
                    }
                  />
                ) : (
                  tax.default_value
                )}
              </TableCell>
              <TableCell>
                {editingRow === tax.id ? (
                  <>
                    <TextField
                      type="date"
                      value={tax.schedules.startDate}
                      onChange={(e) =>
                        handleChange(tax.id, "startDate", e.target.value)
                      }
                    />
                    <div>
                      <Typography variant="caption" color="textSecondary">
                        Current:{" "}
                        {new Date(
                          originalValues.schedules.startDate
                        ).toLocaleDateString()}
                      </Typography>
                    </div>
                  </>
                ) : (
                  new Date(tax.schedules.startDate).toLocaleDateString()
                )}
              </TableCell>
              <TableCell>
                {editingRow === tax.id ? (
                  <>
                    <TextField
                      type="date"
                      value={tax.schedules.endDate}
                      onChange={(e) =>
                        handleChange(tax.id, "endDate", e.target.value)
                      }
                    />
                    <div>
                      <Typography variant="caption" color="textSecondary">
                        Current:{" "}
                        {new Date(
                          originalValues.schedules.endDate
                        ).toLocaleDateString()}
                      </Typography>
                    </div>
                  </>
                ) : (
                  new Date(tax.schedules.endDate).toLocaleDateString()
                )}
              </TableCell>
              <TableCell>
                {editingRow === tax.id ? (
                  <TextField
                    type="number"
                    value={tax.schedules.value}
                    onChange={(e) =>
                      handleChange(tax.id, "value", e.target.value)
                    }
                  />
                ) : (
                  tax.schedules.value
                )}
              </TableCell>
              <TableCell>
                {editingRow === tax.id ? (
                  <>
                    <IconButton color="primary" onClick={() => handleSave(tax)}>
                      <SaveIcon />
                    </IconButton>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                  </>
                ) : (
                  <IconButton color="default" onClick={() => handleEdit(tax)}>
                    <SaveIcon />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const StoreStatus = () => {
  const [id, setId] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [openTime, setOpenTime] = useState(null);
  const [closeTime, setCloseTime] = useState(null);
  const [description, setDescription] = useState("");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [shift, setShift] = useState({});
  //////////// tax //////////
  const [editingTax, setEditingTax] = useState(null);

  const handleEdit = (tax) => {
    setEditingTax(tax);
  };

  const handleDelete = (taxID) => {
    // axios
    //   .delete(`/taxes/${taxID}`)
    //   .then(() => setEditingTax(null))
    //   .catch((error) => console.error(error));
    setEditingTax(null);
  };

  const handleSave = (tax) => {
    setEditingTax(null); // close the form after saving
    // Refresh the tax list or update state
  };

  /////////////// status /////////////
  const fetchStatus = () => {
    // Fetch store status on component mount
    Service.getStoreStatus()
      .then((response) => {
        console.log(response);
        const data = response.data;
        setId(data.id);
        setIsOpen(data.is_open);
        setDescription(data.description);
        setShift(data.shifts);

        // Split the time_slot into openTime and closeTime
        const [open, close] = data.time_slot.split(" - ");
        setOpenTime(dayjs(open, "HH:mm")); // Parse and set openTime
        setCloseTime(dayjs(close, "HH:mm")); // Parse and set closeTime
        console.log("setOpenTime", dayjs(open), dayjs().format("hh:mm"));
      })
      .catch((error) => {
        console.error("Failed to fetch store status", error);
      });
  };

  useEffect(() => {
    fetchStatus();
  }, []);

  // Helper function to format TimeSlot
  const formatTimeSlot = (openTime, closeTime) => {
    console.log(openTime + " " + closeTime);
    if (openTime && closeTime) {
      return `${openTime.format("HH:mm")} - ${closeTime.format("HH:mm")}`;
    }
    return "";
  };

  const handleSubmit = () => {
    const updatedStoreStatus = {
      id: id,
      is_open: isOpen,
      description: description,
      time_slot: formatTimeSlot(openTime, closeTime), // Generate TimeSlot
    };

    console.log("data: ", updatedStoreStatus);

    // Call the update API
    Service.updateStoreStatus(id, updatedStoreStatus)
      .then(() => {
        toast.success("Store status updated successfully");
        fetchStatus();
      })
      .catch((error) => {
        console.error("Failed to update store status", error);
        toast.success("Failed to update store status");
      });
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <>
      <Typography variant="h4" gutterBottom>
        Store OTP
      </Typography>
      <Box sx={{ padding: 2 }}>
        <Item sx={{ padding: 5 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Shift Information
              </Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ShiftID</TableCell>
                    <TableCell>Staff Name</TableCell>
                    <TableCell>OTP</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{shift.shift_id}</TableCell>
                    <TableCell>{shift.user_name}</TableCell>
                    <TableCell>{shift.otp}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </Item>
      </Box>

      <Typography variant="h4" gutterBottom>
        Store Status
      </Typography>
      <Box sx={{ padding: 2 }}>
        <Item sx={{ padding: 5 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {/* Submit Button */}
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
              >
                Apply Store Status
              </Button>
            </Grid>
            <Grid item xs={12}>
              {/* Checkbox for IsOpen */}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isOpen}
                    onChange={(e) => setIsOpen(e.target.checked)}
                  />
                }
                label="Store is Open"
              />
            </Grid>

            <Grid item xs={12}>
              {/* Description Input */}
              <TextField
                label="Description when closed"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                margin="normal"
                fullWidth
              />
            </Grid>

            <Grid item xs={6}>
              {/* Open Time Picker */}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  label="Open Time"
                  value={openTime}
                  onChange={(newValue) => setOpenTime(newValue)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                  // slots={{ textField: (params) => <TextField {...params} /> }}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={6}>
              {/* Close Time Picker */}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  label="Close Time"
                  value={closeTime}
                  onChange={(newValue) => setCloseTime(newValue)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                  // slots={{ textField: (params) => <TextField {...params} /> }}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </Item>
      </Box>

      <Typography variant="h4" gutterBottom>
        Store Tax
      </Typography>
      <Box sx={{ padding: 2 }}>
        <Item sx={{ padding: 5 }}>
          {/* Giả lập danh sách thuế */}
          {/*  onEdit={handleEdit} onDelete={handleDelete} */}
          <TaxList />
        </Item>
      </Box>
    </>
  );
};

export default StoreStatus;
