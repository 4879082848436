// src/components/UnitConversionTable.js

import { Add, Delete, Edit } from '@mui/icons-material';
import {
    Box,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Paper,
    Snackbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { deleteUnitConversion, fetchUnitConversions } from '../../services/unitConversionService';
import UnitConversionForm from './UnitConversionForm';

const UnitConversionTable = ({ materialId }) => {
    const [unitConversions, setUnitConversions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [openForm, setOpenForm] = useState(false);
    const [selectedConversion, setSelectedConversion] = useState(null);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

    const getUnitConversions = async () => {
        try {
            const response = await fetchUnitConversions(materialId);
            setUnitConversions(response.data);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getUnitConversions();
    }, [materialId]);

    const handleDelete = async (id) => {
        if (window.confirm('Are you sure you want to delete this unit conversion?')) {
            try {
                await deleteUnitConversion(id);
                setUnitConversions(unitConversions.filter((uc) => uc.id !== id));
                setSnackbar({ open: true, message: 'Successfully deleted', severity: 'success' });
                getUnitConversions()
            } catch (err) {
                setSnackbar({ open: true, message: `Error: ${err.message}`, severity: 'error' });
            }
        }
    };

    const handleEdit = (conversion) => {
        setSelectedConversion(conversion);
        setOpenForm(true);
    };

    const handleAdd = () => {
        setSelectedConversion(null);
        setOpenForm(true);
    };

    const handleCloseForm = () => {
        setOpenForm(false);
    };

    const handleSnackbarClose = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
                <Typography variant="h6" color="error">
                    {error}
                </Typography>
            </Box>
        );
    }

    return (
        <Box p={3}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="h5">Unit Conversion</Typography>
                <IconButton color="primary" onClick={handleAdd}>
                    <Add />
                </IconButton>
            </Box>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>From Unit</TableCell>
                            <TableCell>To Unit</TableCell>
                            <TableCell>Conversion Factor</TableCell>
                            <TableCell align="right">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {unitConversions.map((uc) => (
                            <TableRow key={uc.ID}>
                                <TableCell>{uc.from_unit}</TableCell>
                                <TableCell>{uc.to_unit}</TableCell>
                                <TableCell>{uc.factor}</TableCell>
                                <TableCell align="right">
                                    <IconButton color="primary" onClick={() => handleEdit(uc)}>
                                        <Edit />
                                    </IconButton>
                                    <IconButton color="secondary" onClick={() => handleDelete(uc.ID)}>
                                        <Delete />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                        {unitConversions.length === 0 && (
                            <TableRow>
                                <TableCell colSpan={4} align="center">
                                    No data available
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Dialog Form */}
            <Dialog open={openForm} onClose={handleCloseForm} fullWidth maxWidth="sm">
                <DialogTitle>{selectedConversion ? 'Edit Unit Conversion' : 'Add Unit Conversion'}</DialogTitle>
                <DialogContent>
                    <UnitConversionForm
                        materialId={materialId}
                        existingConversion={selectedConversion}
                        onClose={handleCloseForm}
                        onSuccess={() => {
                            getUnitConversions();
                            setSnackbar({ open: true, message: selectedConversion ? 'Update successful' : 'Added successfully', severity: 'success' });
                        }}
                        onError={(msg) => {
                            setSnackbar({ open: true, message: msg, severity: 'error' });
                        }}
                    />
                </DialogContent>
                
            </Dialog>

            {/* Snackbar Notification */}
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                message={snackbar.message}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            />
        </Box>

    );
};

export default UnitConversionTable;
