// src/components/UnitConversionForm.js

import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import MaterialService from "../../services/InventoryService";
import UnitService from '../../services/UnitService';
import { createUnitConversion, updateUnitConversion } from '../../services/unitConversionService';

const UnitConversionForm = ({ materialId, existingConversion, onClose, onSuccess, onError }) => {
    const [fromUnit, setFromUnit] = useState(existingConversion ? existingConversion.from_unit : '');
    const [toUnit, setToUnit] = useState(existingConversion ? existingConversion.to_unit : '');
    const [factor, setFactor] = useState(existingConversion ? existingConversion.factor : '');
    const [errors, setErrors] = useState({});
    const [units, setUnits] = useState([]);

    const fetchUnits = useCallback(async () => {
        const response = await UnitService.getUnits();
        console.log("Unit",response.data)
        setUnits(response.data.dataTable);
      }, []);

    const fetchUnitMaterial = useCallback(async () => {
        const response = await MaterialService.getMaterialById(materialId);
        console.log("Material",response.data)
        setToUnit(response.data.unit);
    }, []);
    useEffect(() => {
        fetchUnitMaterial();
        fetchUnits();
    }, [
        fetchUnits,
        fetchUnitMaterial,
    ]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validation
        let tempErrors = {};
        if (!fromUnit) tempErrors.fromUnit = 'Từ đơn vị là bắt buộc';
        if (!toUnit) tempErrors.toUnit = 'Đến đơn vị là bắt buộc';
        if (!factor || isNaN(factor) || factor <= 0) tempErrors.factor = 'Hệ số chuyển đổi phải là số dương';

        // Kiểm tra xem từ đơn vị và đến đơn vị khác nhau
        if (fromUnit && toUnit && fromUnit === toUnit) {
            tempErrors.toUnit = 'Đến đơn vị phải khác từ đơn vị';
        }

        setErrors(tempErrors);
        if (Object.keys(tempErrors).length > 0) return;

        const data = {
            material_id: materialId,
            from_unit: fromUnit,
            to_unit: toUnit,
            factor: parseFloat(factor),
        };

        try {
            if (existingConversion) {
                await updateUnitConversion(existingConversion.id, data);
            } else {
                await createUnitConversion(data);
            }
            onSuccess();
            onClose();
        } catch (err) {
            onError(err.response?.data?.error || 'Đã xảy ra lỗi');
        }
    };

    return (
        <Box component="form" onSubmit={handleSubmit} noValidate>
             <FormControl fullWidth margin="normal" error={Boolean(errors.fromUnit)}>
                <InputLabel>From unit</InputLabel>
                <Select
                    label="From unit"
                    value={fromUnit}
                    onChange={(e) => setFromUnit(e.target.value)}
                >
                    {units.map((unit) => (
                    <MenuItem key={unit.ID} value={unit.name}>
                        {unit.name}
                    </MenuItem>
                    ))}
                </Select>
                <FormHelperText>{errors.fromUnit}</FormHelperText>
            </FormControl>


            <FormControl fullWidth margin="normal">
                <TextField
                    disabled
                    label="To Unit"
                    value={toUnit}
                    onChange={(e) => setToUnit(e.target.value)}
                    error={!!errors.toUnit}
                    helperText={errors.toUnit}
                />
            </FormControl>

           

            <FormControl fullWidth margin="normal">
                <TextField
                    label="Conversion Factor"
                    type="number"
                    inputProps={{ step: "any" }}
                    value={factor}
                    onChange={(e) => setFactor(e.target.value)}
                    error={!!errors.factor}
                    helperText={errors.factor}
                />
            </FormControl>
            <Box mt={2} display="flex" justifyContent="flex-end">
                <Button onClick={onClose} color="secondary" variant="contained" style={{ marginRight: '8px' }}>
                    Cancel
                </Button>
                <Button type="submit" color="primary" variant="contained">
                    {existingConversion ? 'Update' : 'Add New'}
                </Button>
            </Box>
        </Box>

    );
};

export default UnitConversionForm;
