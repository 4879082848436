import axios from "axios";
import config from "../config";

const API = config.apiBaseUrl;
const API_URL = config.apiBaseUrl + "/materials";
const API_imports = config.apiBaseUrl + "/imports";
const API_exports = config.apiBaseUrl + "/exports";
const API_adjustment = config.apiBaseUrl + "/adjustments";
const API_inventory = config.apiBaseUrl + "/inventory";
const API_warehouse = config.apiBaseUrl + "/warehouses";

const InventoryService = {
  /////// inventory
  getAllInventory() {
    return axios.get(API_inventory);
  },
  //////////// Material
  getAllMaterial() {
    return axios.get(API_URL);
  },
  getMaterialById(id) {
    return axios.get(`${API_URL}/${id}`);
  },

  createMaterial(material) {
    return axios.post(API_URL, material);
  },

  updateMaterial(id, material) {
    return axios.put(`${API_URL}/${id}`, material);
  },

  /////////// importRecord //////////////
  getAllRecords() {
    return axios.get(API_imports);
  },

  getRecordById(id) {
    return axios.get(`${API_imports}${id}`);
  },

  createRecord(record) {
    return axios.post(API_imports, record);
  },

  updateRecord(id, record) {
    return axios.put(`${API_URL}/imports/${id}`, record);
  },

  deleteRecord(id) {
    return axios.delete(`${API_URL}/imports/${id}`);
  },
  /////////// ExportRecord //////////////
  getAllExportRecords(page, pageSize, searchTerm, startDate, endDate) {
    return axios.get(API_exports, {
      params: { page, pageSize, searchTerm, startDate, endDate },
    });
  },
  createExportRecord(record) {
    return axios.post(API_exports, record);
  },
};
export default InventoryService;

// Warehouse APIs
export const fetchWarehouses = () => axios.get(API_warehouse);
export const createWarehouse = (data) => axios.post(`${API_warehouse}`, data);
export const updateWarehouse = (id, data) =>
  axios.put(`${API_warehouse}/${id}`, data);
export const deleteWarehouse = (id) => axios.delete(`${API_warehouse}/${id}`);
export const UpdateSourceWarehouse = (id, data) =>
  axios.put(`${API_warehouse}/source/${id}`, data);

// AdjustmentRecord APIs
export const fetchAdjustments = () => axios.get(API_adjustment);
export const createAdjustmentRecord = (data) =>
  axios.post(API_adjustment, data);

// Recipe
export const fetchRecipe = () => axios.get(API + "/recipe");
export const fetchRecipeById = (value) =>
  axios.get(`${API}/recipe`, { params: value });
export const createRecipe = (data) => axios.post(API + "/recipe", data);
export const updateRecipe = (id, data) =>
  axios.put(`${API}/recipe/${id}`, data);
export const deleteRecipe = (id) => axios.delete(`${API}/recipe/${id}`);
export const deleteRecipedetail = (data) =>
  axios.delete(`${API}/recipe-detail`, { data });

export const UpdateMaxQuantityRecipe = (data) =>
  axios.post(`${API}/update-max-quantity-recipe`, data);

// Unit Conversion APIs
