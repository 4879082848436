import axios from "axios";
import config from "../config";

const API_URL = config.apiBaseUrl;

const StoreStatus = {
  // Get store status
  getStoreStatus() {
    return axios.get(`${API_URL}/store-status`);
  },

  // Update store status
  updateStoreStatus(id, payload) {
    return axios.put(`${API_URL}/store-status/${id}`, payload);
  },
};
export default StoreStatus;

// Fetch all taxes
export const fetchTaxes = () => axios.get(`${API_URL}/taxes`);
// Create a new tax
export const createTax = (newTax) => axios.post(`${API_URL}/taxes`, newTax);
// Update an existing tax
export const updateTax = (taxID, updatedTax) =>
  axios.put(`${API_URL}/taxes/${taxID}`, updatedTax);
// Delete a tax
export const deleteTax = (taxID) => axios.delete(`${API_URL}/taxes/${taxID}`);
