import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  tableCellClasses,
  Chip,
} from "@mui/material";
// import Grid from '@mui/material/Grid2';
import { styled } from "@mui/material/styles";
import { Form, Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import config from "../../config";
import CampaignService from "../../services/CampaignService"; // Import campaign service
import CustomerService from "../../services/CustomerService";
import { COLORS } from "../../theme/themeColor";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import CustomerForm from "./CustomerForm";
import CustomerTable from "./CustomerTable";
import GroupCustomerService from "../../services/GroupCustomerService";

const BaseUrl = config.apiBaseUrl;

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  // textAlign: 'center',
  color: theme.palette.text.secondary,
  ...theme.applyStyles("dark", {
    backgroundColor: "#1A2027",
  }),
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: COLORS.BLUE,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "0px 5px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  // height: 20,
}));

// Hàm chuyển đổi từ ISO 8601 sang định dạng yyyy-MM-dd
const isoToDateInputFormat = (isoString) => {
  if (!isoString) return ""; // Trả về chuỗi rỗng nếu giá trị không hợp lệ

  const date = new Date(isoString);

  // Kiểm tra tính hợp lệ của ngày
  if (isNaN(date.getTime())) return ""; // Nếu ngày không hợp lệ, trả về chuỗi rỗng

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Tháng phải +1 vì bắt đầu từ 0
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`; // Trả về định dạng yyyy-MM-dd
};

function addValuesToArray(value, array) {
  // Tách chuỗi value thành mảng các phần tử
  const valuesArray = value.split(",").map((item) => item.trim()); // trim() để loại bỏ khoảng trắng

  let result = [];
  // Thêm từng phần tử từ valuesArray vào array
  valuesArray.forEach((item) => {
    if (!array.includes(item)) {
      // Kiểm tra xem item đã tồn tại trong array chưa
      // array.push(item); // Thêm phần tử vào array
      result.push({ key: item, value: true }); // Thêm đối tượng vào array
    } else {
      result.push({ key: item, value: false });
    } //
  });
  console.log(result);
  return result; // Trả về mảng đã được cập nhật
}

// const validateTimeSlots = (timeSlots) => {
//   const seen = new Set();
//   for (let slot of timeSlots) {
//     const key = `${slot.endHour}`;
//     if (seen.has(key)) {
//       return "Duplicate or overlapping time slots detected";
//     }
//     seen.add(key);
//   }
//   return null;
// };

// const validateTimeSlots = (timeSlots) => {
//   const seen = new Set();

//   for (let slot of timeSlots) {
//     const startKey = `start-${slot.dayOfWeek}-${slot.startHour}`;
//     const endKey = `end-${slot.dayOfWeek}-${slot.endHour}`;

//     if (seen.has(startKey) || seen.has(endKey)) {
//       return "Duplicate or overlapping time slots detected";
//     }

//     // Thêm các key mới vào `Set` để đảm bảo không trùng lặp
//     seen.add(startKey);
//     seen.add(endKey);
//   }

//   return null;
// };

const validateTimeSlots = (timeSlots) => {
  for (let i = 0; i < timeSlots.length; i++) {
    const slotA = timeSlots[i];

    for (let j = i + 1; j < timeSlots.length; j++) {
      const slotB = timeSlots[j];

      // Kiểm tra giao nhau giữa các time slots
      if (
        (slotA.startHour < slotB.endHour && slotA.endHour > slotB.startHour) ||
        (slotB.startHour < slotA.endHour && slotB.endHour > slotA.startHour)
      ) {
        return `Overlapping time slots detected on day ${slotA.dayOfWeek}: ${slotA.startHour}-${slotA.endHour} and ${slotB.startHour}-${slotB.endHour}`;
      }
    }
  }

  return null; // Không có lỗi
};

const CampaignForm = () => {
  const { id } = useParams(); // Lấy id từ URL
  const [campaign, setCampaign] = useState(null); // State cho dữ liệu chiến dịch
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [openProductModal, setOpenProductModal] = useState(false);
  const [enddateTemp, setEnddateTemp] = useState("");
  const [selection, setSelection] = useState("add"); // State for discount type (% or amount)
  const [loading, setLoading] = useState(true); // State để kiểm tra quá trình tải dữ liệu
  const [customers, setCustomers] = useState([]);
  const [listGiftCodes, setListGiftCodes] = useState([]);
  const [listGiftCodesAvailable, setListGiftCodesAvailable] = useState([]);
  const [giftCode, setGiftCode] = useState("");
  const navigate = useNavigate();

  // Thiết lập giá trị mặc định cho Open Time và Close Time
  const [openTime, setOpenTime] = useState(dayjs("00:00", "HH:mm")); // 00:00
  const [closeTime, setCloseTime] = useState(dayjs("23:59", "HH:mm")); // 23:59

  ////// search customer //////
  const [searchTerm, setSearchTerm] = useState("");
  const [customerOptions, setCustomerOptions] = useState([]);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [reloadFlag, setReloadFlag] = useState(false); // State to trigger reload
  const [groups, setGroups] = useState([]); // Danh sách nhóm
  const [selectedGroup, setSelectedGroup] = useState(null); // Nhóm được chọn
  const [showTable, setShowTable] = useState(false);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [ordersByGiftCode, setOrdersByGiftCode] = useState([]);

  const renderOrderDetails = (order) => (
    <Box>
      {order.order_details.map((detail, idx) => (
        <Box key={idx} sx={{ marginBottom: "8px" }}>
          {`${detail.name} - Qty: ${detail.quantity}, Price: ${
            detail.price
          }, Modifiers: ${
            detail.selectedModifiers?.map((mod) => mod.name).join(", ") ||
            "None"
          }`}
        </Box>
      ))}
    </Box>
  );

  const calculateOrderTotal = (orderDetails) => {
    return orderDetails.reduce(
      (total, detail) => total + detail.price * detail.quantity,
      0
    );
  };

  const handleRowClick = (giftCode) => {
    if (ordersByGiftCode && giftCode && id) {
      console.log("check click");
      const orders = ordersByGiftCode[giftCode] || []; // Lọc orders theo GiftCode
      setFilteredOrders(orders); // Cập nhật danh sách đơn hàng được lọc
      if (orders.length > 0) {
        setShowTable(true);
      } else {
        toast.error("No orders found");
      }
    }
  };

  // Fetch groups on component mount
  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const response = await GroupCustomerService.getGroups();
        setGroups(response.data);
        // setSelectedGroup(response.data[0]);
      } catch (error) {
        console.error("Error fetching groups:", error);
      }
    };
    fetchGroups();
  }, []);

  // Callback to handle customer updates
  const handleCustomerUpdated = () => {
    setReloadFlag(!reloadFlag); // Toggle to trigger useEffect in CustomerTable
  };

  const fetchCustomers = async () => {
    if (searchTerm != "") {
      const response = await CustomerService.searchCustomers(searchTerm);
      console.log("customers list", response.data);
      // setCustomers(response.data);
      setCustomerOptions(response.data);
    }
  };

  // Gửi yêu cầu thêm Customer vào Group
  const addCustomerToGroup = async (customer) => {
    try {
      // await axios.post(`/api/groups/${groupId}/customers/${customer.id}`);
      setSelectedCustomers((prev) => [...prev, customer]);
    } catch (error) {
      console.error("Error adding customer to group:", error);
    }
  };

  // Xử lý sự kiện khi chọn khách hàng
  const handleCustomerSelect = (event, newValue) => {
    if (!selectedCustomers.find((cust) => cust.id === newValue.id)) {
      addCustomerToGroup(newValue);
    }
  };
  ///////////////////////////

  const handleSelectionChange = (event) => {
    setSelection(event.target.value);
  };

  const handleAddGiftCode = async () => {
    // Logic to add the gift code
    console.log("Gift code added:", giftCode);
    try {
      const response = await CampaignService.checkUniqueGiftCode(giftCode);
      // setListGiftCodes(response.data.unique_codes);
      setListGiftCodes(addValuesToArray(giftCode, response.data.unique_codes));
      setListGiftCodesAvailable(response.data.unique_codes);
      // console.log(response.data)
    } catch (error) {
      console.error("Error checking gift codes:", error);
    }
  };

  // Fetch thông tin chiến dịch nếu có ID
  useEffect(() => {
    if (id) {
      const fetchCampaign = async () => {
        try {
          const response = await CampaignService.getCampaign(id);
          const { campaign, gift_codes, orders_by_gift_code } = response.data;
          console.log(response.data);
          setCampaign(campaign);
          setOrdersByGiftCode(orders_by_gift_code);

          if (
            response.data.campaign.ApplyTo == "GiftCode" ||
            response.data.campaign.ApplyTo == "SpendGiftCode"
          ) {
            const formattedcampaign = {
              ...campaign,
              Metadata: {
                ...campaign.Metadata,
                start_date: dayjs(campaign.Metadata.start_date, "HH:mm"),
                end_date: dayjs(campaign.Metadata.end_date, "HH:mm"),
              },
            };
            setCampaign(formattedcampaign);
            setListGiftCodes(response.data.gift_codes);
            console.log(campaign);
          }
        } catch (error) {
          console.error("Failed to fetch campaign:", error);
        } finally {
          setLoading(false); // Đặt loading thành false sau khi hoàn tất fetch
        }
      };
      fetchCampaign();
    } else {
      setLoading(false); // Nếu không có ID thì đặt loading thành false
    }
  }, [id]);

  useEffect(() => {
    const fetchUsers = async () => {
      const response = await CustomerService.getAllCustomers();
      console.log("customers list", response.data);
      setCustomers(response.data);
    };
    fetchUsers();
  }, []);

  // Define the validation schema
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Campaign name is required"),
    // description: Yup.string().required('Description is required'),
    startDate: Yup.date().required("Start date is required"),
    endDate: Yup.date()
      .required("End date is required")
      .min(Yup.ref("startDate"), "End date cannot be before start date"),
    MinAmount: Yup.number()
      .min(0, "Minimum amount must be at least 0")
      .required("Minimum amount is required"),
    MaxAmount: Yup.number()
      .min(1, "Maximum amount must be greater than to minimum amount")
      .required("Maximum amount must be greater than to minimum amount"),
    targetAudience: Yup.string().required("Target audience is required"),
    ApplyTo: Yup.string().required("Campaign type is required"),

    // Validation for Discount
    discount: Yup.object()
      .nullable()
      .when("ApplyTo", {
        is: (value) => value === "Discount",
        then: (schema) =>
          Yup.object().shape({
            discountType: Yup.string().required("Discount type is required"),
            discountValue: Yup.number()
              .min(1, "Discount value must be greater than or equal to 1")
              .required("Discount value is required"),
            timeSlots: Yup.array()
              .of(
                Yup.object().shape({
                  startHour: Yup.number()
                    .min(0, "Start hour must be between 0 and 23")
                    .max(23, "Start hour must be between 0 and 23")
                    .required("Start hour is required"),
                  endHour: Yup.number()
                    .min(0, "End hour must be between 0 and 23")
                    .max(23, "End hour must be between 0 and 23")
                    .required("End hour is required")
                    .test(
                      "is-greater",
                      "End hour must be greater than start hour",
                      function (value) {
                        const { startHour } = this.parent; // Lấy giá trị startHour từ cùng đối tượng
                        return value > startHour; // Kiểm tra điều kiện endHour > startHour
                      }
                    ),
                  dayOfWeek: Yup.array()
                    .min(1, "At least one day of week is required")
                    .required("Day of Week is required"),
                })
              )
              .test(
                "unique-time-slots",
                // "Duplicate or overlapping time slots detected",
                (timeSlots) => !validateTimeSlots(timeSlots)
              ),
          }),
      }),

    // Validation cho TopUpRules
    rules: Yup.object()
      .nullable()
      .when("ApplyTo", {
        is: (value) => value === "TopUp",
        then: (schema) =>
          (schema = Yup.object().shape({
            CustomerValue: Yup.number()
              .min(0, "Customer value must be at least 0")
              .required("Customer value is required"),
            CustomerRuleType: Yup.string().required(
              "Customer rule type is required"
            ),
            KolValue: Yup.number()
              .min(0, "KOL value must be at least 0")
              .required("KOL value is required"),
            KolRuleType: Yup.string().required("KOL rule type is required"),
            CustomerReferralValue: Yup.number()
              .min(0, "Customer referral value must be at least 0")
              .required("Customer referral value is required"),
            CustomerReferralRuleType: Yup.string().required(
              "Customer referral rule type is required"
            ),
            KolReferralValue: Yup.number()
              .min(0, "KOL referral value must be at least 0")
              .required("KOL referral value is required"),
            KolReferralRuleType: Yup.string().required(
              "KOL referral rule type is required"
            ),
            Value: Yup.number()
              .min(0, "General value must be at least 0")
              .required("General value is required"),
            RuleType: Yup.string().required("General rule type is required"),
          })),
        otherwise: (schema) => (schema = Yup.object().shape()),
      }),
    // Validation cho GiftCodeCampaign
    giftCodes: Yup.object()
      .nullable()
      .when("ApplyTo", {
        is: (value) => value === "GiftCode" || value === "SpendGiftCode", // Cả hai lựa chọn,
        then: (schema) =>
          (schema = Yup.object().shape({
            value: Yup.number()
              .min(1, "At least one value is required")
              .required("Number of value is required"),
            value_type: Yup.string().required("GiftCode type is required"),
            number_of_code: Yup.number()
              .min(1, "At least one gift code is required")
              .required("Number of gift codes is required"),
            code_length: Yup.number()
              .min(4, "Code length must be at least 4 characters")
              .required("Code length is required"),
            expiry_date: Yup.date().required("Expiry date is required"),
            usage_limit: Yup.number()
              .min(1, "Usage limit must be at least 1")
              .required("Usage limit is required"),
            selection: Yup.string().required("Campaign type is required"),
            // start_date: Yup.date().required("Start time is required"),
            // end_date: Yup.date()
            //   .required("End time is required")
            //   .test(
            //     "is-greater",
            //     "End time must be greater than Start time",
            //     function (value) {
            //       const { start_date } = this.parent;

            //       // Chuyển đổi start_date và end_date thành dạng "HH:mm"
            //       const startTime = dayjs(start_date).format("HH:mm");
            //       const endTime = dayjs(value).format("HH:mm");
            //       console.log("endTime > startTime", endTime > startTime);
            //       // Kiểm tra logic: end_time phải lớn hơn start_time
            //       return endTime > startTime;
            //     }
            //   ),
            giftCode: Yup.string()
              .nullable()
              .test(
                "check-selection",
                "Gift Code is required when selection is 'add'",
                function (value) {
                  const { selection } = this.parent;
                  return selection !== "add" || !!value; // Chỉ yêu cầu giftCode khi selection === "add"
                }
              ),
          })),
        otherwise: (schema) => (schema = Yup.object().shape()),
      }),
  });

  const DAYS_OF_WEEK = [
    { label: "Sunday", value: 0 },
    { label: "Monday", value: 1 },
    { label: "Tuesday", value: 2 },
    { label: "Wednesday", value: 3 },
    { label: "Thursday", value: 4 },
    { label: "Friday", value: 5 },
    { label: "Saturday", value: 6 },
  ];

  // Nếu đang loading thì hiển thị thông báo hoặc một spinner
  if (loading) {
    return <Typography>Loading...</Typography>;
  }
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Formik
        initialValues={{
          isOpen: campaign ? campaign.IsOpen : true,
          name: campaign ? campaign.name : "",
          description: campaign ? campaign.Description : "",
          startDate: campaign
            ? isoToDateInputFormat(campaign?.StartDate)
            : new Date().toISOString().split("T")[0],
          endDate: campaign?.EndDate
            ? isoToDateInputFormat(campaign?.EndDate)
            : "",
          MinAmount: campaign ? campaign.MinAmount : 0,
          MaxAmount: campaign ? campaign.MaxAmount : 0,
          targetAudience: campaign ? campaign.TargetAudience : "AllCustomers",
          EligibleUsers: campaign ? campaign.EligibleUsers : [],
          // ApplyTo: campaign ? campaign.ApplyTo : "TopUp",
          ApplyTo: campaign ? campaign.ApplyTo : "SpendGiftCode",

          // chiến địch discount
          discount:
            campaign && campaign.ApplyTo === "Discount"
              ? campaign.Metadata
              : {
                  discountType: "RATE", // Default là phần trăm
                  discountValue: 0,
                  timeSlots: [], // Danh sách khung giờ
                },

          // Nếu chiến dịch là TopUpCampaign, khởi tạo giá trị cho TopUpRules
          rules:
            campaign && campaign.ApplyTo === "TopUp"
              ? campaign.Metadata
              : {
                  CustomerValue: 0,
                  CustomerRuleType: "RATE",
                  KolValue: 0,
                  KolRuleType: "RATE",
                  StaffValue: 0,
                  StaffRuleType: "RATE",
                  CustomerReferralValue: 0,
                  CustomerReferralRuleType: "RATE",
                  KolReferralValue: 0,
                  KolReferralRuleType: "RATE",
                  StaffReferralValue: 0,
                  StaffReferralRuleType: "RATE",
                  Value: 0,
                  RuleType: "RATE",
                },

          // Nếu là GiftCodeCampaign, khởi tạo dữ liệu liên quan đến mã quà tặng
          giftCodes:
            campaign &&
            (campaign.ApplyTo === "GiftCode" ||
              campaign.ApplyTo === "SpendGiftCode")
              ? campaign.Metadata
              : {
                  number_of_code: 1,
                  value: 0,
                  code_length: 6,
                  expiry_date: campaign
                    ? isoToDateInputFormat(campaign?.expiryDate)
                    : new Date().toISOString().split("T")[0],
                  usage_limit: 1,
                  gift_codes: [],
                  selection: "add",
                  is_active: true,
                  giftCode: "",
                  start_date: openTime,
                  end_date: closeTime,
                  value_type: "FIXED",
                },
        }}
        validationSchema={validationSchema} // Chỉ sử dụng schema cơ bản cho các trường không liên quan đến TopUp hay GiftCode
        onSubmit={async (
          values,
          { setSubmitting, setTouched, setErrors, errors }
        ) => {
          console.log("Errors:", validationSchema);
          // Kiểm tra xem có lỗi validation không
          // Đảm bảo các giá trị date có định dạng đúng ISO với 'T'
          const formattedStartDate = new Date(values.startDate).toISOString();
          const formattedEndDate = new Date(values.endDate).toISOString();
          let submittedData = {
            ...values,
            startDate: formattedStartDate,
            endDate: formattedEndDate,
          };

          // Xử lý theo loại chiến dịch
          if (values.ApplyTo === "Discount") {
            submittedData = {
              ...submittedData,
              metadata: {
                discountType: values.discount.discountType,
                discountValue: values.discount.discountValue,
                timeSlots: values.discount.timeSlots,
              },
            };
          }

          if (values.ApplyTo === "TopUp") {
            submittedData = {
              ...submittedData,
              metadata: {
                CustomerValue: values.rules.CustomerValue,
                CustomerRuleType: values.rules.CustomerRuleType,
                KolValue: values.rules.KolValue,
                KolRuleType: values.rules.KolRuleType,
                StaffValue: values.rules.StaffValue,
                StaffRuleType: values.rules.StaffRuleType,
                CustomerReferralValue: values.rules.CustomerReferralValue,
                CustomerReferralRuleType: values.rules.CustomerReferralRuleType,
                KolReferralValue: values.rules.KolReferralValue,
                KolReferralRuleType: values.rules.KolReferralRuleType,
                StaffReferralValue: values.rules.StaffReferralValue,
                StaffReferralRuleType: values.rules.StaffReferralRuleType,
                Value: values.rules.Value,
                RuleType: values.rules.RuleType,
              },
            };
          }

          if (
            values.ApplyTo === "GiftCode" ||
            values.ApplyTo === "SpendGiftCode"
          ) {
            submittedData = {
              ...submittedData,
              metadata: {
                number_of_code: values.giftCodes.number_of_code,
                value: values.giftCodes.value,
                value_type: values.giftCodes.value_type,
                code_length: values.giftCodes.code_length,
                expiry_date: formattedEndDate,
                usage_limit: values.giftCodes.usage_limit,
                gift_codes: listGiftCodesAvailable,
                selection: values.giftCodes.selection,
                is_active: true,
                start_date: values.giftCodes.start_date.format("HH:mm"),
                end_date: values.giftCodes.end_date.format("HH:mm"),
              },
            };
          }
          console.log("submit", submittedData);
          try {
            if (id) {
              await CampaignService.updateCampaign(id, submittedData);
              toast.success("Campaign updated successfully!");
            } else {
              console.log("Đang gửi API với dữ liệu:", submittedData);
              const res = await CampaignService.createCampaign(submittedData);
              // console.log('res:', res);
              toast.success("Campaign created successfully!");

              // Điều hướng về màn hình home sau khi thành công
              navigate("/campaign");
            }

            // alert('Campaign created successfully!');
          } catch (error) {
            toast.error("Failed to create campaign");
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({
          isSubmitting,
          errors,
          touched,
          handleChange,
          handleBlur,
          values,
          setFieldValue,
          setFieldTouched,
        }) => (
          <Form>
            <Typography
              id="add-modifier-title"
              variant="h6"
              component="h2"
              gutterBottom
            >
              Create Campaign
            </Typography>
            <Box sx={{ p: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.isOpen}
                        onChange={(e) =>
                          setFieldValue("isOpen", e.target.checked)
                        }
                        name="isOpen"
                      />
                    }
                    label="Campaign Open"
                  />
                </Grid>

                {/* Name campaign */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Campaign Name"
                    name="name"
                    disabled={!!id}
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Description"
                    name="description"
                    multiline
                    rows={4}
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.description && Boolean(errors.description)}
                    helperText={touched.description && errors.description}
                  />
                </Grid>

                {/* minimum value */}
                <Grid item xs={12} sm={6}>
                  <Typography variant="h6" component="h3" gutterBottom>
                    Minimum Amount
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    gutterBottom
                  >
                    Specify the minimum amount required to qualify for this
                    campaign.
                  </Typography>
                  <TextField
                    fullWidth
                    label="Minimum Amount"
                    name="MinAmount"
                    type="number"
                    disabled={!!id}
                    value={values.MinAmount}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.MinAmount && Boolean(errors.MinAmount)}
                    helperText={touched.MinAmount && errors.MinAmount}
                  />
                </Grid>

                {/* maximum value */}
                <Grid item xs={12} sm={6}>
                  <Typography variant="h6" component="h3" gutterBottom>
                    Maximum Amount
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    gutterBottom
                  >
                    Set the maximum amount allowed for benefits under this
                    campaign.
                  </Typography>
                  <TextField
                    fullWidth
                    label="Maximum Amount"
                    name="MaxAmount"
                    type="number"
                    disabled={!!id}
                    value={values.MaxAmount}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.MaxAmount && Boolean(errors.MaxAmount)}
                    helperText={touched.MaxAmount && errors.MaxAmount}
                  />
                </Grid>

                {/* start date */}
                <Grid item xs={12} sm={6}>
                  <Typography variant="h6" component="h3" gutterBottom>
                    Start Date
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    gutterBottom
                  >
                    Select the start date for the campaign.
                  </Typography>
                  <TextField
                    fullWidth
                    label="Start Date"
                    name="startDate"
                    type="date"
                    disabled={!!id}
                    InputLabelProps={{ shrink: true }}
                    value={values.startDate}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.startDate && Boolean(errors.startDate)}
                    helperText={touched.startDate && errors.startDate}
                  />
                </Grid>

                {/* End date */}
                <Grid item xs={12} sm={6}>
                  <Typography variant="h6" component="h3" gutterBottom>
                    End Date
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    gutterBottom
                  >
                    Select the end date for the campaign. The campaign will not
                    be active after this date.
                  </Typography>
                  <TextField
                    fullWidth
                    label="End Date"
                    name="endDate"
                    type="date"
                    disabled={!!id}
                    InputLabelProps={{ shrink: true }}
                    value={values.endDate}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.endDate && Boolean(errors.endDate)}
                    helperText={touched.endDate && errors.endDate}
                  />
                </Grid>

                {/* Target Audience */}
                <Grid item xs={12}>
                  <Typography variant="h6" component="h3" gutterBottom>
                    Target Audience
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    gutterBottom
                  >
                    Define which group of customers this campaign is targeting.
                  </Typography>
                  <FormControl
                    fullWidth
                    error={
                      touched.targetAudience && Boolean(errors.targetAudience)
                    }
                  >
                    <InputLabel>Target Audience</InputLabel>
                    <Select
                      name="targetAudience"
                      label="Target Audience"
                      disabled={!!id}
                      value={values.targetAudience}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <MenuItem value="NewCustomer">New Customer</MenuItem>
                      <MenuItem value="RepeatTopUpCustomer">
                        Repeat Customer
                      </MenuItem>
                      <MenuItem value="ReturningCustomer">
                        Returning Customer
                      </MenuItem>
                      <MenuItem value="AllCustomers">All Customers</MenuItem>
                      <MenuItem value="SpecialGroup">Special Group</MenuItem>
                    </Select>
                    <FormHelperText>
                      {touched.targetAudience && errors.targetAudience}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                {values.targetAudience === "SpecialGroup" && (
                  <Grid item xs={12}>
                    {/* <Typography variant="h6" component="h3" gutterBottom>
                      Eligible Users (IDs)
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      gutterBottom
                    >
                      Specify the IDs of eligible users for this campaign,
                      separated by commas.
                    </Typography>
                    <Autocomplete
                      multiple
                      disableCloseOnSelect
                      fullWidth
                      options={customers}
                      value={values.rules.EligibleUsers}
                      getOptionLabel={(option) =>
                        `${option.username} (${option.id})`
                      } // Hiển thị tên và ID của khách hàng
                      onChange={(event, newValue) => {
                        // Cập nhật giá trị khi người dùng chọn một khách hàng
                        setFieldValue("rules.EligibleUsers", newValue);
                      }}
                      onBlur={handleBlur}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Eligible Users (IDs)"
                          name="eligibleUsers"
                          placeholder="Select a user"
                          error={
                            touched.eligibleUsers &&
                            Boolean(errors.eligibleUsers)
                          }
                          helperText={
                            touched.eligibleUsers && errors.eligibleUsers
                          }
                        />
                      )}
                    /> */}

                    {/*  */}

                    <Typography variant="h6" component="h3" gutterBottom>
                      Special Group Customer
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      gutterBottom
                    >
                      Specify Group for this campaign, separated by commas.
                    </Typography>
                    {/* Hiển thị danh sách khách hàng đã chọn dưới dạng văn bản */}
                    <Autocomplete
                      multiple
                      disableCloseOnSelect
                      fullWidth
                      // disabled={!!id}
                      options={groups}
                      value={values.EligibleUsers}
                      getOptionLabel={(option) => `${option.name}`} // Hiển thị tên và ID của khách hàng
                      onChange={(event, newValue) => {
                        console.log("selected group", newValue);
                        // Cập nhật giá trị khi người dùng chọn một khách hàng
                        setFieldValue("EligibleUsers", newValue);
                      }}
                      onBlur={handleBlur}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Group Customer"
                          name="eligibleUsers"
                          placeholder="Select a user"
                          error={
                            touched.eligibleUsers &&
                            Boolean(errors.eligibleUsers)
                          }
                          helperText={
                            touched.eligibleUsers && errors.eligibleUsers
                          }
                        />
                      )}
                    />
                  </Grid>
                )}

                {/* ApplyTo */}
                <Grid item xs={12}>
                  <Typography variant="h6" component="h3" gutterBottom>
                    Apply To
                  </Typography>
                  <FormControl fullWidth>
                    <InputLabel>Apply To</InputLabel>
                    <Select
                      name="ApplyTo"
                      disabled={!!id}
                      label="Apply To"
                      value={values.ApplyTo}
                      // onChange={handleChange}
                      onChange={(e) => {
                        const value = e.target.value;
                        handleChange(e); // Gọi handleChange để cập nhật ApplyTo
                        if (value === "Discount") {
                          setFieldValue("targetAudience", "AllCustomers"); // Cập nhật targetAudience
                        }
                      }}
                      onBlur={handleBlur}
                    >
                      <MenuItem value="TopUp">TopUp</MenuItem>
                      <MenuItem value="GiftCode">Claim GiftCode</MenuItem>
                      <MenuItem value="SpendGiftCode">Spend GiftCode</MenuItem>
                      <MenuItem value="Discount">Hourly Discount</MenuItem>
                    </Select>
                    <FormHelperText>
                      {touched.ApplyTo && errors.ApplyTo}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                {/* Nếu ApplyTo là TopUp */}
                {values.ApplyTo === "TopUp" && (
                  <>
                    <Grid item xs={12} sm={6}>
                      <Item>
                        <Box
                          id="category-a"
                          sx={{
                            fontSize: "20px",
                            textTransform: "uppercase",
                            textAlign: "center",
                          }}
                        >
                          TopUp Info For Customer
                        </Box>
                        <Grid container padding={2} spacing={2}>
                          {/* General Top-Up Rules */}
                          <Grid item xs={12}>
                            <Typography variant="subtitle1">
                              General Top-Up Rules
                            </Typography>
                          </Grid>
                          <Grid item padding={2} xs={12} sm={6}>
                            <FormControl fullWidth>
                              <InputLabel>General Rule Type</InputLabel>
                              <Select
                                name="rules.RuleType"
                                label="General Rule Type"
                                value={values.rules?.RuleType}
                                onChange={(e) => {
                                  setFieldValue("rules.Value", 0);
                                  handleChange(e);
                                  // setDiscountType(e.target.value)
                                }}
                                onBlur={handleBlur}
                                error={
                                  touched.rules?.RuleType &&
                                  Boolean(errors.rules?.RuleType)
                                }
                              >
                                <MenuItem value="RATE">RATE</MenuItem>
                                <MenuItem value="FIXED">FIXED</MenuItem>
                              </Select>
                              <FormHelperText>
                                {touched.rules?.RuleType &&
                                  errors.rules?.RuleType}
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                          <Grid item padding={2} xs={12} sm={6}>
                            <TextField
                              fullWidth
                              label={`General Top-Up Value (${values.rules.RuleType})`}
                              name="rules.Value"
                              type="number"
                              value={values.rules?.Value}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={
                                touched.rules?.Value &&
                                Boolean(errors.rules?.Value)
                              }
                              helperText={
                                touched.rules?.Value && errors.rules?.Value
                              }
                            />
                          </Grid>

                          {/* General Top-Up Rules */}
                          <Grid item xs={12}>
                            <Typography variant="subtitle1">
                              Top-Up Using Another Customer's Code
                            </Typography>
                          </Grid>
                          <Grid item padding={2} xs={12} sm={6}>
                            <FormControl fullWidth>
                              <InputLabel>Customer Rule Type</InputLabel>
                              <Select
                                label="CustomerRuleType"
                                name="rules.CustomerRuleType"
                                value={values.rules.CustomerRuleType}
                                onChange={(e) => {
                                  setFieldValue("rules.CustomerValue", 0);
                                  handleChange(e);
                                  // setDiscountType(e.target.value)
                                }}
                                onBlur={handleBlur}
                                error={
                                  touched.rules?.CustomerRuleType &&
                                  Boolean(errors.rules?.CustomerRuleType)
                                }
                              >
                                <MenuItem value="RATE">RATE</MenuItem>
                                <MenuItem value="FIXED">FIXED</MenuItem>
                              </Select>
                              <FormHelperText>
                                {touched.rules?.CustomerRuleType &&
                                  errors.rules?.CustomerRuleType}
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                          <Grid item padding={2} xs={12} sm={6}>
                            <TextField
                              fullWidth
                              label={`Customer Value (${values.rules.CustomerRuleType})`}
                              name="rules.CustomerValue"
                              type="number"
                              value={values.rules.CustomerValue}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={
                                touched.rules?.CustomerValue &&
                                Boolean(errors.rules?.CustomerValue)
                              }
                              helperText={
                                touched.rules?.CustomerValue &&
                                errors.rules?.CustomerValue
                              }
                            />
                          </Grid>

                          {/* General Top-Up Rules */}
                          <Grid item xs={12}>
                            <Typography variant="subtitle1">
                              Top-Up Using a KOL's Code
                            </Typography>
                          </Grid>
                          <Grid item padding={2} xs={12} sm={6}>
                            <FormControl fullWidth>
                              <InputLabel>KOL Rule Type</InputLabel>
                              <Select
                                name="rules.KolRuleType"
                                label="KolRuleType"
                                value={values.rules?.KolRuleType}
                                onChange={(e) => {
                                  setFieldValue("rules.KolValue", 0);
                                  handleChange(e);
                                  // setDiscountType(e.target.value)
                                }}
                                onBlur={handleBlur}
                                error={
                                  touched.rules?.KolRuleType &&
                                  Boolean(errors.rules?.KolRuleType)
                                }
                              >
                                <MenuItem value="RATE">RATE</MenuItem>
                                <MenuItem value="FIXED">FIXED</MenuItem>
                              </Select>
                              <FormHelperText>
                                {touched.rules?.KolRuleType &&
                                  errors.rules?.KolRuleType}
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                          <Grid item padding={2} xs={12} sm={6}>
                            <TextField
                              fullWidth
                              label={`KOL Value (${values.rules.KolRuleType})`}
                              name="rules.KolValue"
                              type="number"
                              value={values.rules?.KolValue}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={
                                touched.rules?.KolValue &&
                                Boolean(errors.rules?.KolValue)
                              }
                              helperText={
                                touched.rules?.KolValue &&
                                errors.rules?.KolValue
                              }
                            />
                          </Grid>
                          {/* General Top-Up Rules */}
                          <Grid item xs={12}>
                            <Typography variant="subtitle1">
                              Top-Up Using a Staff's Code
                            </Typography>
                          </Grid>
                          <Grid item padding={2} xs={12} sm={6}>
                            <FormControl fullWidth>
                              <InputLabel>Staff Rule Type</InputLabel>
                              <Select
                                name="rules.StaffRuleType"
                                label="StaffRuleType"
                                value={values.rules?.KolRuleType}
                                onChange={(e) => {
                                  setFieldValue("rules.StaffValue", 0);
                                  handleChange(e);
                                  // setDiscountType(e.target.value)
                                }}
                                onBlur={handleBlur}
                                error={
                                  touched.rules?.StaffRuleType &&
                                  Boolean(errors.rules?.StaffRuleType)
                                }
                              >
                                <MenuItem value="RATE">RATE</MenuItem>
                                <MenuItem value="FIXED">FIXED</MenuItem>
                              </Select>
                              <FormHelperText>
                                {touched.rules?.StaffRuleType &&
                                  errors.rules?.StaffRuleType}
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                          <Grid item padding={2} xs={12} sm={6}>
                            <TextField
                              fullWidth
                              label={`Staff Value (${values.rules.StaffRuleType})`}
                              name="rules.StaffValue"
                              type="number"
                              value={values.rules?.StaffValue}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={
                                touched.rules?.StaffValue &&
                                Boolean(errors.rules?.StaffValue)
                              }
                              helperText={
                                touched.rules?.StaffValue &&
                                errors.rules?.StaffValue
                              }
                            />
                          </Grid>
                        </Grid>
                      </Item>
                    </Grid>
                    {/* Các trường liên quan đến TopUp */}
                    <Grid item xs={12} sm={6}>
                      <Item>
                        <Box
                          id="category-a"
                          sx={{
                            fontSize: "20px",
                            textTransform: "uppercase",
                            textAlign: "center",
                          }}
                        >
                          Referral Earnings
                        </Box>
                        <Grid container padding={2} spacing={2}>
                          {/* Referral Top-Up by Other Customers */}
                          <Grid item xs={12}>
                            <Typography
                              variant="subtitle1"
                              sx={{ color: "transparent" }}
                            >
                              abc
                            </Typography>
                          </Grid>
                          <Grid item padding={2} xs={12} sm={6}>
                            <FormControl fullWidth disabled>
                              <InputLabel></InputLabel>
                              <Select
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                      borderColor: "transparent", // Set border color to transparent
                                    },
                                    "&:hover fieldset": {
                                      borderColor: "transparent", // Set hover border color to transparent
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: "transparent", // Set focused border color to transparent
                                    },
                                  },
                                }}
                                // variant="outlined"
                              ></Select>
                              <FormHelperText></FormHelperText>
                            </FormControl>
                          </Grid>
                          <Grid item padding={2} xs={12} sm={6}>
                            <TextField fullWidth disabled type="number" />
                          </Grid>

                          <Grid item xs={12}>
                            <Typography variant="subtitle1">
                              Referral Top-Up by Other Customers
                            </Typography>
                          </Grid>
                          <Grid item padding={2} xs={12} sm={6}>
                            <FormControl fullWidth>
                              <InputLabel>Referral Customers Type</InputLabel>
                              <Select
                                name="rules.CustomerReferralRuleType"
                                label="Referral Customers Type"
                                value={values.rules?.CustomerReferralRuleType}
                                onChange={(e) => {
                                  setFieldValue(
                                    "rules.CustomerReferralValue",
                                    0
                                  );
                                  handleChange(e);
                                  // setDiscountType(e.target.value)
                                }}
                                onBlur={handleBlur}
                                error={
                                  touched.rules?.CustomerReferralRuleType &&
                                  Boolean(
                                    errors.rules?.CustomerReferralRuleType
                                  )
                                }
                              >
                                <MenuItem value="RATE">RATE</MenuItem>
                                <MenuItem value="FIXED">FIXED</MenuItem>
                              </Select>
                              <FormHelperText>
                                {touched.rules?.CustomerReferralRuleType &&
                                  errors.rules?.CustomerReferralRuleType}
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                          <Grid item padding={2} xs={12} sm={6}>
                            <TextField
                              fullWidth
                              label={`Referral Customers Value (${values.rules.CustomerReferralRuleType})`}
                              name="rules.CustomerReferralValue"
                              type="number"
                              value={values.rules?.CustomerReferralValue}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={
                                touched.rules?.CustomerReferralValue &&
                                Boolean(errors.rules?.CustomerReferralValue)
                              }
                              helperText={
                                touched.rules?.CustomerReferralValue &&
                                errors.rules?.CustomerReferralValue
                              }
                            />
                          </Grid>

                          {/* Referral Top-Up by KOLs */}
                          <Grid item xs={12}>
                            <Typography variant="subtitle1">
                              Referral Top-Up by KOLs
                            </Typography>
                          </Grid>
                          <Grid item padding={2} xs={12} sm={6}>
                            <FormControl fullWidth>
                              <InputLabel>Referral KOL Type</InputLabel>
                              <Select
                                label="Referral KOL Type"
                                name="rules.KolReferralRuleType"
                                value={values.rules.KolReferralRuleType}
                                onChange={(e) => {
                                  setFieldValue("rules.KolReferralValue", 0);
                                  handleChange(e);
                                  // setDiscountType(e.target.value)
                                }}
                                onBlur={handleBlur}
                                error={
                                  touched.rules?.KolReferralRuleType &&
                                  Boolean(errors.rules?.KolReferralRuleType)
                                }
                              >
                                <MenuItem value="RATE">RATE</MenuItem>
                                <MenuItem value="FIXED">FIXED</MenuItem>
                              </Select>
                              <FormHelperText>
                                {touched.rules?.KolReferralRuleType &&
                                  errors.rules?.KolReferralRuleType}
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                          <Grid item padding={2} xs={12} sm={6}>
                            <TextField
                              fullWidth
                              label={`Referral KOL Value (${values.rules.KolReferralRuleType})`}
                              name="rules.KolReferralValue"
                              type="number"
                              value={values.rules.KolReferralValue}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={
                                touched.rules?.KolReferralValue &&
                                Boolean(errors.rules?.KolReferralValue)
                              }
                              helperText={
                                touched.rules?.KolReferralValue &&
                                errors.rules?.KolReferralValue
                              }
                            />
                          </Grid>

                          {/* Referral Top-Up by Staff */}
                          <Grid item xs={12}>
                            <Typography variant="subtitle1">
                              Referral Top-Up by Staffs
                            </Typography>
                          </Grid>
                          <Grid item padding={2} xs={12} sm={6}>
                            <FormControl fullWidth>
                              <InputLabel>Referral Staff Type</InputLabel>
                              <Select
                                label="Referral Staff Type"
                                name="rules.StaffReferralRuleType"
                                value={values.rules.KolReferralRuleType}
                                onChange={(e) => {
                                  setFieldValue("rules.StaffReferralValue", 0);
                                  handleChange(e);
                                  // setDiscountType(e.target.value)
                                }}
                                onBlur={handleBlur}
                                error={
                                  touched.rules?.StaffReferralRuleType &&
                                  Boolean(errors.rules?.StaffReferralRuleType)
                                }
                              >
                                <MenuItem value="RATE">RATE</MenuItem>
                                <MenuItem value="FIXED">FIXED</MenuItem>
                              </Select>
                              <FormHelperText>
                                {touched.rules?.StaffReferralRuleType &&
                                  errors.rules?.StaffReferralRuleType}
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                          <Grid item padding={2} xs={12} sm={6}>
                            <TextField
                              fullWidth
                              label={`Referral Staff Value (${values.rules.StaffReferralRuleType})`}
                              name="rules.StaffReferralValue"
                              type="number"
                              value={values.rules.StaffReferralValue}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={
                                touched.rules?.StaffReferralValue &&
                                Boolean(errors.rules?.StaffReferralValue)
                              }
                              helperText={
                                touched.rules?.StaffReferralValue &&
                                errors.rules?.StaffReferralValue
                              }
                            />
                          </Grid>
                        </Grid>
                      </Item>
                    </Grid>
                  </>
                )}

                {/* Các trường liên quan đến GiftCode */}
                {(values.ApplyTo === "GiftCode" ||
                  values.ApplyTo === "SpendGiftCode") && (
                  <>
                    <Grid item xs={12}>
                      <Typography variant="h6" component="h3" gutterBottom>
                        Select Gift Code Option
                      </Typography>
                      <FormControl fullWidth>
                        <InputLabel id="gift-code-select-label">
                          {" "}
                          Select Gift Code Option
                        </InputLabel>
                        <Select
                          labelId="gift-code-select-label"
                          name="giftCodes.selection"
                          label="Select Gift Code Option"
                          disabled={!!id}
                          value={values.giftCodes.selection}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <MenuItem value="add">Add Gift Code</MenuItem>
                          <MenuItem value="generate">
                            Generate Gift Code
                          </MenuItem>
                        </Select>
                        {/* <FormHelperText>{touched.ApplyTo && errors.ApplyTo}</FormHelperText> */}
                      </FormControl>
                    </Grid>

                    {values.giftCodes.selection === "add" && (
                      <>
                        <Grid item xs={12}>
                          <Item>
                            <Box
                              id="category-a"
                              sx={{
                                fontSize: "20px",
                                textTransform: "uppercase",
                                textAlign: "center",
                              }}
                            >
                              {values.ApplyTo}
                            </Box>
                            <Grid container padding={2} spacing={2}>
                              {values.ApplyTo === "SpendGiftCode" && (
                                <Grid item padding={2} xs={12} sm={6}>
                                  <FormControl fullWidth>
                                    <InputLabel>General Rule Type</InputLabel>
                                    <Select
                                      name="giftCodes.value_type"
                                      label="General Rule Type"
                                      disabled={!!id}
                                      value={
                                        values.giftCodes?.value_type || "FIXED"
                                      }
                                      onChange={(e) => {
                                        setFieldValue("giftCodes.value", 0);
                                        // handleChange(e);
                                        const selectedType = e.target.value;
                                        setFieldValue(
                                          "giftCodes.value_type",
                                          selectedType
                                        ); // Cập nhật type
                                      }}
                                      onBlur={handleBlur}
                                      error={
                                        touched.giftCodes?.value_type &&
                                        Boolean(errors.giftCodes?.value_type)
                                      }
                                    >
                                      <MenuItem value="RATE">RATE</MenuItem>
                                      <MenuItem value="FIXED">FIXED</MenuItem>
                                    </Select>
                                    <FormHelperText>
                                      {touched.giftCodes?.value_type &&
                                        errors.giftCodes?.value_type}
                                    </FormHelperText>
                                  </FormControl>
                                </Grid>
                              )}
                              <Grid item padding={2} xs={12} sm={6}>
                                <TextField
                                  fullWidth
                                  label={`Value (${values.giftCodes.value_type})`}
                                  name="giftCodes.value"
                                  type="number"
                                  disabled={!!id}
                                  value={values.giftCodes.value}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={
                                    touched.giftCodes?.value &&
                                    Boolean(errors.giftCodes?.value)
                                  }
                                  helperText={
                                    touched.giftCodes?.value &&
                                    errors.giftCodes?.value
                                  }
                                />
                              </Grid>

                              <Grid item padding={2} xs={12} sm={6}>
                                {/* <Typography
                                  variant="h6"
                                  component="h3"
                                  gutterBottom
                                >
                                  Usage Limit
                                </Typography> */}
                                <TextField
                                  fullWidth
                                  label="Usage Limit"
                                  name="giftCodes.usage_limit"
                                  type="number"
                                  disabled={!!id}
                                  value={values.giftCodes?.usage_limit}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={
                                    touched.giftCodes?.usage_limit &&
                                    Boolean(errors.giftCodes?.usage_limit)
                                  }
                                  helperText={
                                    touched.giftCodes?.usage_limit &&
                                    errors.giftCodes?.usage_limit
                                  }
                                />
                              </Grid>

                              {/* startdate enddate */}
                              {values.ApplyTo === "SpendGiftCode" && (
                                <Grid item padding={2} xs={12} sm={6}>
                                  <Grid container spacing={2}>
                                    {/* Open Time Picker */}
                                    <Grid item xs={6}>
                                      <TimePicker
                                        label="Open Time"
                                        disabled={!!id}
                                        value={values.giftCodes?.start_date}
                                        onChange={(newValue) =>
                                          setFieldValue(
                                            "giftCodes.start_date",
                                            newValue
                                          )
                                        }
                                        onBlur={handleBlur}
                                        renderInput={(params) => (
                                          <TextField {...params} fullWidth />
                                        )}
                                        ampm={false} // Hiển thị định dạng AM/PM
                                      />
                                    </Grid>

                                    {/* Close Time Picker */}
                                    <Grid item xs={6}>
                                      <TimePicker
                                        label="Close Time"
                                        disabled={!!id}
                                        value={values.giftCodes?.end_date}
                                        onChange={(newValue) =>
                                          setFieldValue(
                                            "giftCodes.end_date",
                                            newValue
                                          )
                                        }
                                        onBlur={handleBlur}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            fullWidth
                                            // error={true}
                                            // helperText={"check"}
                                          />
                                        )}
                                        ampm={false} // Hiển thị định dạng AM/PM
                                      />
                                      <div>
                                        {/* Hiển thị lỗi cho end_date */}
                                        {touched.giftCodes?.end_date &&
                                          errors.giftCodes?.end_date && (
                                            <Typography
                                              variant="caption"
                                              color="error"
                                            >
                                              {errors.giftCodes?.end_date
                                                ? errors.giftCodes?.end_date
                                                : dayjs(
                                                    values.giftCodes?.end_date
                                                  ).format("HH:mm")}
                                            </Typography>
                                          )}
                                      </div>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              )}

                              <Grid item padding={2} xs={12} sm={6}>
                                <TextField
                                  fullWidth
                                  disabled={!!id}
                                  multiline // Bật chế độ đa dòng
                                  label="Enter Gift Code"
                                  variant="outlined"
                                  name="giftCodes.giftCode"
                                  value={values.giftCodes?.giftCode}
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    setGiftCode(e.target.value);
                                    handleChange(e);
                                  }}
                                  sx={{ mb: 2 }}
                                  error={
                                    touched.giftCodes?.giftCode &&
                                    Boolean(errors.giftCodes?.giftCode)
                                  }
                                  helperText={
                                    touched.giftCodes?.giftCode &&
                                    errors.giftCodes?.giftCode
                                  }
                                  InputProps={{
                                    style: { height: "160px" }, // Thiết lập chiều cao cho phần input
                                  }}
                                />
                              </Grid>

                              <Grid item xs={12} sm={6}>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  disabled={!!id}
                                  onClick={handleAddGiftCode}
                                >
                                  Check Gift Code
                                </Button>
                              </Grid>
                            </Grid>
                            {/* </Box> */}
                          </Item>
                        </Grid>

                        <Grid item xs={12}>
                          <Item>
                            <Box
                              id="category-a"
                              sx={{
                                fontSize: "20px",
                                textTransform: "uppercase",
                                textAlign: "center",
                              }}
                            >
                              List GiftCodes
                            </Box>
                            {/* <Box aria-labelledby="category-a" padding={1} xs={12}> */}
                            <Grid container padding={4} spacing={2}>
                              <TableContainer component={Paper}>
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      <StyledTableCell>Idx</StyledTableCell>
                                      <StyledTableCell>EndDate</StyledTableCell>
                                      <StyledTableCell align="center">
                                        Code
                                      </StyledTableCell>
                                      <StyledTableCell align="center">
                                        Value
                                      </StyledTableCell>
                                      <StyledTableCell align="center">
                                        Usage Count
                                      </StyledTableCell>
                                      <StyledTableCell align="center">
                                        usage_limit
                                      </StyledTableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {listGiftCodes?.map((item, index) => (
                                      <StyledTableRow
                                        key={index}
                                        hover
                                        // style={{
                                        //   background: item.value ? "red" : "",
                                        // }}
                                        style={{
                                          background: item.value ? "red" : "",
                                          cursor: "pointer", // Thêm cursor pointer để thể hiện có thể click
                                        }}
                                        onClick={() =>
                                          handleRowClick(item.code || item.key)
                                        } // Gọi hàm khi click
                                      >
                                        <StyledTableCell align="center">
                                          {index}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                          {" "}
                                          {item.expiry_date
                                            ? moment(item.expiry_date).format(
                                                "DD/MM/YYYY"
                                              )
                                            : moment(values.endDate).format(
                                                "DD/MM/YYYY"
                                              )}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                          {item.code ? item.code : item.key}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                          {item.Value
                                            ? item.Value
                                            : values.giftCodes.value}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                          {item.usage_count
                                            ? item.usage_count
                                            : 0}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                          {item.usage_limit
                                            ? item.usage_limit
                                            : values.giftCodes.usage_limit}
                                        </StyledTableCell>
                                      </StyledTableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Grid>
                            {/* </Box> */}
                          </Item>
                        </Grid>
                      </>
                    )}
                    {values.giftCodes.selection === "generate" && (
                      <>
                        <Grid item xs={12}>
                          <Item>
                            <Box
                              id="category-a"
                              sx={{
                                fontSize: "20px",
                                textTransform: "uppercase",
                                textAlign: "center",
                              }}
                            >
                              {values.ApplyTo}
                            </Box>
                            <Grid container padding={2} spacing={2}>
                              {values.ApplyTo === "SpendGiftCode" && (
                                <Grid item padding={2} xs={12} sm={6}>
                                  <Typography
                                    variant="h6"
                                    component="h3"
                                    gutterBottom
                                  >
                                    Type
                                  </Typography>
                                  <FormControl fullWidth>
                                    <InputLabel>General Rule Type</InputLabel>
                                    <Select
                                      name="giftCodes.value_type"
                                      label="General Rule Type"
                                      disabled={!!id}
                                      value={
                                        values.giftCodes?.value_type || "FIXED"
                                      }
                                      onChange={(e) => {
                                        setFieldValue("giftCodes.value", 0);
                                        // handleChange(e);
                                        const selectedType = e.target.value;
                                        setFieldValue(
                                          "giftCodes.value_type",
                                          selectedType
                                        ); // Cập nhật type
                                      }}
                                      onBlur={handleBlur}
                                      error={
                                        touched.giftCodes?.value_type &&
                                        Boolean(errors.giftCodes?.value_type)
                                      }
                                    >
                                      <MenuItem value="RATE">RATE</MenuItem>
                                      <MenuItem value="FIXED">FIXED</MenuItem>
                                    </Select>
                                    <FormHelperText>
                                      {touched.giftCodes?.value_type &&
                                        errors.giftCodes?.value_type}
                                    </FormHelperText>
                                  </FormControl>
                                </Grid>
                              )}

                              <Grid item padding={2} xs={12} sm={6}>
                                <Typography
                                  variant="h6"
                                  component="h3"
                                  gutterBottom
                                >
                                  Value
                                </Typography>
                                <TextField
                                  fullWidth
                                  label={`Value (${values.giftCodes.value_type})`}
                                  name="giftCodes.value"
                                  type="number"
                                  disabled={!!id}
                                  value={values.giftCodes.value}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={
                                    touched.giftCodes?.value &&
                                    Boolean(errors.giftCodes?.value)
                                  }
                                  helperText={
                                    touched.giftCodes?.value &&
                                    errors.giftCodes?.value
                                  }
                                />
                              </Grid>

                              <Grid item padding={2} xs={12} sm={6}>
                                <Typography
                                  variant="h6"
                                  component="h3"
                                  gutterBottom
                                >
                                  Number of GiftCode
                                </Typography>
                                <TextField
                                  fullWidth
                                  label="Number of GiftCode"
                                  name="giftCodes.number_of_code"
                                  type="number"
                                  disabled={!!id}
                                  value={values.giftCodes.number_of_code}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={
                                    touched.giftCodes?.number_of_code &&
                                    Boolean(errors.giftCodes?.number_of_code)
                                  }
                                  helperText={
                                    touched.giftCodes?.number_of_code &&
                                    errors.giftCodes?.number_of_code
                                  }
                                />
                              </Grid>

                              <Grid item padding={2} xs={12} sm={6}>
                                <Typography
                                  variant="h6"
                                  component="h3"
                                  gutterBottom
                                >
                                  Code Length
                                </Typography>
                                <TextField
                                  fullWidth
                                  label="Code Length"
                                  name="giftCodes.code_length"
                                  type="number"
                                  disabled={!!id}
                                  value={values.giftCodes.code_length}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={
                                    touched.giftCodes?.code_length &&
                                    Boolean(errors.giftCodes?.code_length)
                                  }
                                  helperText={
                                    touched.giftCodes?.code_length &&
                                    errors.giftCodes?.code_length
                                  }
                                />
                              </Grid>

                              <Grid item padding={2} xs={12} sm={6}>
                                <Typography
                                  variant="h6"
                                  component="h3"
                                  gutterBottom
                                >
                                  Usage Limit
                                </Typography>
                                <TextField
                                  fullWidth
                                  label="Usage Limit"
                                  name="giftCodes.usage_limit"
                                  type="number"
                                  disabled={!!id}
                                  value={values.giftCodes?.usage_limit}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={
                                    touched.giftCodes?.usage_limit &&
                                    Boolean(errors.giftCodes?.usage_limit)
                                  }
                                  helperText={
                                    touched.giftCodes?.usage_limit &&
                                    errors.giftCodes?.usage_limit
                                  }
                                />
                              </Grid>

                              {/* startdate enddate */}
                              {values.ApplyTo === "SpendGiftCode" && (
                                <Grid item padding={2} xs={12} sm={6}>
                                  <Typography
                                    variant="h6"
                                    component="h3"
                                    gutterBottom
                                  >
                                    Time slot
                                  </Typography>
                                  <Grid container spacing={2}>
                                    {/* Open Time Picker */}
                                    <Grid item xs={6}>
                                      <TimePicker
                                        label="Open Time"
                                        value={values.giftCodes?.start_date}
                                        onChange={(newValue) =>
                                          setFieldValue(
                                            "giftCodes.start_date",
                                            newValue
                                          )
                                        }
                                        renderInput={(params) => (
                                          <TextField {...params} fullWidth />
                                        )}
                                        ampm={false} // Hiển thị định dạng AM/PM
                                      />
                                    </Grid>

                                    {/* Close Time Picker */}
                                    <Grid item xs={6}>
                                      <TimePicker
                                        label="Close Time"
                                        value={values.giftCodes?.end_date}
                                        onChange={(newValue) =>
                                          setFieldValue(
                                            "giftCodes.end_date",
                                            newValue
                                          )
                                        }
                                        renderInput={(params) => (
                                          <TextField {...params} fullWidth />
                                        )}
                                        ampm={false} // Hiển thị định dạng AM/PM
                                      />
                                      <div>
                                        {/* Hiển thị lỗi cho end_date */}
                                        {touched.giftCodes?.end_date &&
                                          errors.giftCodes?.end_date && (
                                            <Typography
                                              variant="caption"
                                              color="error"
                                            >
                                              {errors.giftCodes?.end_date
                                                ? errors.giftCodes?.end_date
                                                : dayjs(
                                                    values.giftCodes?.end_date
                                                  ).format("HH:mm")}
                                            </Typography>
                                          )}
                                      </div>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              )}
                            </Grid>
                            {/* </Box> */}
                          </Item>
                        </Grid>

                        <Grid item xs={12}>
                          <Item>
                            <Box
                              id="category-a"
                              sx={{
                                fontSize: "20px",
                                textTransform: "uppercase",
                                textAlign: "center",
                              }}
                            >
                              List GiftCodes
                            </Box>
                            {/* <Box aria-labelledby="category-a" padding={1} xs={12}> */}
                            <Grid container padding={4} spacing={2}>
                              <TableContainer component={Paper}>
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      <StyledTableCell>Idx</StyledTableCell>
                                      <StyledTableCell>EndDate</StyledTableCell>
                                      <StyledTableCell align="center">
                                        Code
                                      </StyledTableCell>
                                      <StyledTableCell align="center">
                                        Value
                                      </StyledTableCell>
                                      <StyledTableCell align="center">
                                        Usage Count
                                      </StyledTableCell>
                                      <StyledTableCell align="center">
                                        usage_limit
                                      </StyledTableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {listGiftCodes.map((giftcode, index) => (
                                      <StyledTableRow
                                        key={index}
                                        hover
                                        style={{
                                          background: giftcode.value
                                            ? "red"
                                            : "",
                                          cursor: "pointer", // Thêm cursor pointer để thể hiện có thể click
                                        }}
                                        onClick={() =>
                                          handleRowClick(
                                            giftcode.code || giftcode.key
                                          )
                                        } // Gọi hàm khi click
                                      >
                                        <StyledTableCell align="center">
                                          {index}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                          {" "}
                                          {moment(giftcode.expiry_date).format(
                                            "DD/MM/YYYY"
                                          )}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                          {giftcode.code}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                          {giftcode.Value}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                          {giftcode.usage_count}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                          {giftcode.usage_limit}
                                        </StyledTableCell>
                                      </StyledTableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Grid>
                            {/* </Box> */}
                          </Item>
                        </Grid>
                      </>
                    )}

                    {id && (
                      <Grid item xs={12}>
                        <Item>
                          <Box
                            id="category-a"
                            sx={{
                              fontSize: "20px",
                              textTransform: "uppercase",
                              textAlign: "center",
                            }}
                          >
                            Gift Code Details
                          </Box>
                          <Grid container padding={4} spacing={2}>
                            {showTable && (
                              <TableContainer component={Paper}>
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      <StyledTableCell>
                                        Order ID
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        CreatedAt
                                      </StyledTableCell>
                                      <StyledTableCell>User</StyledTableCell>
                                      <StyledTableCell>Total</StyledTableCell>
                                      <StyledTableCell>Tip</StyledTableCell>
                                      <StyledTableCell>GST</StyledTableCell>
                                      <StyledTableCell>PST</StyledTableCell>
                                      <StyledTableCell>
                                        Discount
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        Payment Type
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        Order Type
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        Staff Name
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        Gift Code
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        Total Price
                                      </StyledTableCell>
                                      <StyledTableCell>Details</StyledTableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {filteredOrders?.map((order) => {
                                      const orderTotal = calculateOrderTotal(
                                        order.order_details
                                      );
                                      return (
                                        <TableRow key={order.ID}>
                                          <TableCell>{order.ID}</TableCell>
                                          <TableCell>
                                            {dayjs(order.CreatedAt).format(
                                              "DD/MM/YYYY, h:mm:ss A"
                                            )}
                                          </TableCell>
                                          <TableCell>
                                            {order.user_name}
                                          </TableCell>
                                          <TableCell>
                                            {order.total_products}
                                          </TableCell>
                                          <TableCell>{order.tip}</TableCell>
                                          <TableCell>{order.gst}</TableCell>
                                          <TableCell>{order.pst}</TableCell>
                                          <TableCell>
                                            {order.percent_discount}
                                          </TableCell>
                                          <TableCell>
                                            {order.payment_type}
                                          </TableCell>
                                          <TableCell>
                                            {order.order_type}
                                          </TableCell>
                                          <TableCell>
                                            {order.staff_name}
                                          </TableCell>
                                          <TableCell>
                                            {order.gift_code}
                                          </TableCell>
                                          <TableCell>
                                            {orderTotal.toFixed(2)}
                                          </TableCell>
                                          <TableCell>
                                            {renderOrderDetails(order)}
                                          </TableCell>
                                        </TableRow>
                                      );
                                    })}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            )}
                          </Grid>
                        </Item>
                      </Grid>
                    )}
                  </>
                )}

                {/* Nếu là chiến dịch HourlyDiscount */}

                {values.ApplyTo === "Discount" && (
                  <>
                    <Grid item xs={12}>
                      <Item>
                        <Box
                          id="category-a"
                          sx={{
                            fontSize: "20px",
                            textTransform: "uppercase",
                            textAlign: "center",
                          }}
                        >
                          Hourly Discount
                        </Box>
                        <Grid container padding={2} spacing={2}>
                          {/* Discount Type */}
                          <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                              <InputLabel>Discount Type</InputLabel>
                              <Select
                                name="discount.discountType"
                                label="Discount Type"
                                disabled={!!id}
                                value={values.discount.discountType}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              >
                                <MenuItem value="RATE">RATE</MenuItem>
                                <MenuItem value="FIXED">Fixed</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>

                          {/* Discount Value */}
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              label={`Discount Value (${
                                values.discount.discountType === "PERCENTAGE"
                                  ? "%"
                                  : "Amount"
                              })`}
                              name="discount.discountValue"
                              type="number"
                              disabled={!!id}
                              value={values.discount.discountValue}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={
                                touched.discount?.discountValue &&
                                Boolean(errors.discount?.discountValue)
                              }
                              helperText={
                                touched.discount?.discountValue &&
                                errors.discount?.discountValue
                              }
                            />
                          </Grid>

                          {/* Time Slots */}
                          <Grid item xs={12} sm={6}>
                            <Typography variant="h6">Time Slots</Typography>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Button
                              variant="contained"
                              disabled={!!id}
                              onClick={() => {
                                const newSlot = {
                                  startHour: "",
                                  endHour: "",
                                  dayOfWeek: "",
                                };
                                setFieldValue("discount.timeSlots", [
                                  ...values.discount.timeSlots,
                                  newSlot,
                                ]);
                              }}
                            >
                              Add Time Slot
                            </Button>
                          </Grid>

                          {/* List of Time Slots */}
                          <Grid item xs={12}>
                            {touched.discount?.timeSlots &&
                              errors.discount?.timeSlots && (
                                <Typography color="error">
                                  {JSON.stringify(errors.discount.timeSlots)}
                                </Typography>
                              )}
                            {values.discount.timeSlots.map((slot, index) => (
                              <Box
                                key={index}
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  mb: 2,
                                }}
                              >
                                <Autocomplete
                                  multiple
                                  options={[
                                    { label: "Select All", value: "ALL" },
                                    ...DAYS_OF_WEEK,
                                  ]}
                                  getOptionLabel={(option) => option.label}
                                  value={
                                    Array.isArray(slot.dayOfWeek)
                                      ? slot.dayOfWeek.map(
                                          (day) =>
                                            DAYS_OF_WEEK.find(
                                              (d) => d.value === day
                                            ) || null
                                        )
                                      : [] // Giá trị mặc định nếu `slot.dayOfWeek` không phải mảng
                                  }
                                  onChange={(e, newValue) => {
                                    if (
                                      newValue.some(
                                        (item) => item.value === "ALL"
                                      )
                                    ) {
                                      setFieldValue(
                                        `discount.timeSlots[${index}].dayOfWeek`,
                                        DAYS_OF_WEEK.map((day) => day.value) // Chọn tất cả các ngày
                                      );
                                    } else {
                                      setFieldValue(
                                        `discount.timeSlots[${index}].dayOfWeek`,
                                        newValue.map((item) => item.value) // Chỉ chọn các mục đã chọn
                                      );
                                    }
                                  }}
                                  disableCloseOnSelect
                                  disabled={!!id}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Days of Week"
                                      error={
                                        touched.discount?.timeSlots?.[index]
                                          ?.dayOfWeek &&
                                        Boolean(
                                          errors.discount?.timeSlots?.[index]
                                            ?.dayOfWeek
                                        )
                                      }
                                      helperText={
                                        touched.discount?.timeSlots?.[index]
                                          ?.dayOfWeek &&
                                        errors.discount?.timeSlots?.[index]
                                          ?.dayOfWeek
                                      }
                                    />
                                  )}
                                  renderOption={(
                                    props,
                                    option,
                                    { selected }
                                  ) => (
                                    <li {...props}>
                                      <Checkbox
                                        checked={
                                          option.value === "ALL"
                                            ? slot.dayOfWeek.length ===
                                              DAYS_OF_WEEK.length // Kiểm tra nếu tất cả các ngày đã được chọn
                                            : selected
                                        }
                                        style={{ marginRight: 8 }}
                                      />
                                      {option.label}
                                    </li>
                                  )}
                                  sx={{ mr: 2, width: "300px" }}
                                />

                                <TextField
                                  label="Start Hour"
                                  type="number"
                                  disabled={!!id}
                                  value={slot.startHour}
                                  onChange={(e) =>
                                    setFieldValue(
                                      `discount.timeSlots[${index}].startHour`,
                                      e.target.value
                                    )
                                  }
                                  sx={{ mr: 2 }}
                                  error={
                                    touched.discount?.timeSlots?.[index]
                                      ?.startHour &&
                                    Boolean(
                                      errors.discount?.timeSlots?.[index]
                                        ?.startHour
                                    )
                                  }
                                  helperText={
                                    touched.discount?.timeSlots?.[index]
                                      ?.startHour &&
                                    errors.discount?.timeSlots?.[index]
                                      ?.startHour
                                  }
                                />
                                <TextField
                                  label="End Hour"
                                  type="number"
                                  disabled={!!id}
                                  value={slot.endHour}
                                  onChange={(e) =>
                                    setFieldValue(
                                      `discount.timeSlots[${index}].endHour`,
                                      e.target.value
                                    )
                                  }
                                  error={
                                    touched.discount?.timeSlots?.[index]
                                      ?.endHour &&
                                    Boolean(
                                      errors.discount?.timeSlots?.[index]
                                        ?.endHour
                                    )
                                  }
                                  helperText={
                                    touched.discount?.timeSlots?.[index]
                                      ?.endHour &&
                                    errors.discount?.timeSlots?.[index]?.endHour
                                  }
                                />

                                <Button
                                  variant="outlined"
                                  color="error"
                                  disabled={!!id}
                                  onClick={() => {
                                    const updatedSlots = [
                                      ...values.discount.timeSlots,
                                    ];
                                    updatedSlots.splice(index, 1);
                                    setFieldValue(
                                      "discount.timeSlots",
                                      updatedSlots
                                    );
                                  }}
                                  sx={{ ml: 2 }}
                                >
                                  Remove
                                </Button>
                              </Box>
                            ))}
                          </Grid>
                        </Grid>
                      </Item>
                    </Grid>
                  </>
                )}

                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={id ? true : false}
                  >
                    {id ? "Update Campaign" : "Create Campaign"}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Form>
        )}
      </Formik>
    </LocalizationProvider>
  );
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default CampaignForm;
