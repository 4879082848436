// src/components/AdjustmentRecordTable.js

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  tableCellClasses,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import moment from "moment";
import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import { fetchAdjustments } from "../../services/InventoryService";
import { COLORS } from "../../theme/themeColor";
import AdjustmentRecordForm from "./AdjustmentRecordForm";

const AdjustmentRecordTable = () => {
  const [adjustmentRecords, setAdjustmentRecords] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filtered, setFiltered] = useState([]); // State cho danh sách sản phẩm đã lọc
  const [error, setError] = useState(null);
  const [openForm, setOpenForm] = useState(false);
  const [selectedAdjustment, setSelectedAdjustment] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [searchTerm, setSearchTerm] = useState(""); // State cho từ khóa tìm kiếm
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    // border: 'none', // Bỏ đường viền cho TableCell
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: COLORS.BLUE,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: "5px 5px",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
      // borderWidth: 0,
    },
    // height: 20,
  }));

  const getAdjustmentRecords = async () => {
    try {
      const response = await fetchAdjustments();
      setAdjustmentRecords(response.data);
      console.log(response.data.length);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Hàm để lọc sản phẩm dựa trên từ khóa tìm kiếm
  useEffect(() => {
    if (adjustmentRecords) {
      const results = adjustmentRecords.filter((adjustmentRecord) =>
        Object.values(adjustmentRecord).some(
          (value) =>
            typeof value === "string" &&
            value.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
      setFiltered(results);
    }
  }, [searchTerm, adjustmentRecords]);

  useEffect(() => {
    getAdjustmentRecords();
  }, []);

  const handleDelete = async (id) => {
    if (window.confirm("Bạn có chắc chắn muốn xóa phiếu điều chỉnh này?")) {
      try {
        // await deleteAdjustmentRecord(id);
        setAdjustmentRecords(adjustmentRecords.filter((adj) => adj.id !== id));
        toast.success("Adjustment record deleted successfully");
        // setSnackbar({
        //   open: true,
        //   message: "Xóa phiếu điều chỉnh thành công",
        //   severity: "success",
        // });
      } catch (err) {
        toast.error(`error: ${err.response?.data?.error || err.message}`);
        // setSnackbar({
        //   open: true,
        //   message: `Lỗi: ${err.response?.data?.error || err.message}`,
        //   severity: "error",
        // });
      }
    }
  };

  const handleEdit = (adjustmentRecord) => {
    setSelectedAdjustment(adjustmentRecord);
    setOpenForm(true);
  };

  const handleAdd = () => {
    setSelectedAdjustment(null);
    setOpenForm(true);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
  };

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="50vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="50vh"
      >
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  return (
    <div style={{ width: "100%" }}>
      <Typography variant="h4" gutterBottom>
        Adjustment List
      </Typography>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Button variant="contained" color="primary" onClick={handleAdd}>
          Create Adjustment
        </Button>

        {/* TextField cho tìm kiếm */}
        <TextField
          label="Search"
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ width: "300px" }} // Đặt độ rộng cho TextField tìm kiếm
        />
      </Box>

      <TableContainer component={Paper}>
        <Table aria-label="adjustment table">
          <TableHead>
            <TableRow>
              <StyledTableCell rowSpan={2}>ID</StyledTableCell>
              <StyledTableCell rowSpan={2}>Date</StyledTableCell>
              <StyledTableCell rowSpan={2}>Name</StyledTableCell>
              <StyledTableCell rowSpan={2}>Warehouse</StyledTableCell>
              <StyledTableCell rowSpan={2}>OrderID</StyledTableCell>
              <StyledTableCell rowSpan={2}>Reason</StyledTableCell>

              <StyledTableCell
                colSpan={6}
                align="center"
                sx={{ backgroundColor: "#1976d2", color: "white" }}
              >
                <Typography variant="subtitle1" color="inherit">
                  Details
                </Typography>
              </StyledTableCell>
            </TableRow>
            <TableRow>
              {/* <TableCell colSpan={6} /> */}

              <StyledTableCell
                sx={{ backgroundColor: "#1976d2", color: "white" }}
              >
                Material Name
              </StyledTableCell>
              <StyledTableCell
                sx={{ backgroundColor: "#1976d2", color: "white" }}
              >
                Action
              </StyledTableCell>

              <StyledTableCell
                sx={{ backgroundColor: "#1976d2", color: "white" }}
              >
                Quantity
              </StyledTableCell>
              <StyledTableCell
                sx={{ backgroundColor: "#1976d2", color: "white" }}
              >
                Unit
              </StyledTableCell>
              <StyledTableCell
                sx={{ backgroundColor: "#1976d2", color: "white" }}
              >
                Price
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filtered.map((row, idx) => (
              <React.Fragment key={row.ID}>
                {row.details.map((detail, index) => (
                  <TableRow key={`${row.ID}-${index}`}>
                    {index === 0 && (
                      <>
                        <TableCell rowSpan={row.details.length}>
                          {idx + 1}
                        </TableCell>
                        <TableCell rowSpan={row.details.length}>
                          {moment(row.adjusted_at).format("DD/MM/YYYY HH:mm")}
                        </TableCell>
                        <TableCell rowSpan={row.details.length}>
                          {row.adjusted_by}
                        </TableCell>
                        <TableCell rowSpan={row.details.length}>
                          {row.warehouse.name}
                        </TableCell>
                        <TableCell rowSpan={row.details.length}>
                          {row.order_id}
                        </TableCell>
                        <TableCell rowSpan={row.details.length}>
                          {row.details[0] && row.details[0]?.reason}
                        </TableCell>
                      </>
                    )}

                    <TableCell>{detail.material.name}</TableCell>
                    <TableCell>{detail.type}</TableCell>
                    <TableCell>{detail.quantity}</TableCell>

                    <TableCell>{detail.material.unit}</TableCell>

                    <TableCell>{detail.price}</TableCell>
                  </TableRow>
                ))}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Dialog Form */}
      <Dialog open={openForm} onClose={handleCloseForm} fullWidth maxWidth="md">
        <DialogTitle>
          {selectedAdjustment
            ? "Edit Adjustment Record"
            : "Add New Adjustment Record"}
        </DialogTitle>
        <DialogContent>
          <AdjustmentRecordForm
            existingAdjustment={selectedAdjustment}
            onClose={handleCloseForm}
            onSuccess={() => {
              getAdjustmentRecords();
              toast.success(
                selectedAdjustment
                  ? "Successfully updated the adjustment record"
                  : "Successfully added a new adjustment record"
              );
              // setSnackbar({
              //   open: true,
              //   message: selectedAdjustment
              //     ? "Cập nhật phiếu điều chỉnh thành công"
              //     : "Thêm phiếu điều chỉnh thành công",
              //   severity: "success",
              // });
            }}
            onError={(msg) => {
              toast.error(msg);
              // setSnackbar({ open: true, message: msg, severity: "error" });
            }}
          />
        </DialogContent>
      </Dialog>

      {/* Snackbar Thông Báo */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbar.message}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      />
    </div>
  );
};

export default AdjustmentRecordTable;
