const config = {
  // apiBaseUrl: "http://209.52.105.56:8080/api",
  // BaseUrl: "http://209.52.105.56:8080/api",

  // apiBaseUrl: "http://localhost:8080/api",
  // BaseUrl: "http://localhhost:8080/api",

  apiBaseUrl: "https://api.avik.tech/api",
  BaseUrl: "https://api.avik.tech/api",

  // apiBaseUrl: "http://192.167.1.157:8080/api",
  // BaseUrl: "http://192.167.1.157:8080/api",
};

export default config;
