import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  Modal,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Link,
  FormControlLabel,
} from "@mui/material";

import { TableCell, tableCellClasses } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import config from "../../config";
import CategoryService from "../../services/CategoryService";
import ComboService from "../../services/ComboService";
import GroupService from "../../services/GroupModifierService"; // Import the service for Group CRUD operations
import Modifiers from "../../services/ModifierService";
import ProductService from "../../services/ProductService";
import { COLORS } from "../../theme/themeColor";
import ImagePicker from "../BaseComponent/ImagePicker";
import { Height } from "@mui/icons-material";
const BaseUrl = config.apiBaseUrl;

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxHeight: "100vh", // Giới hạn chiều cao
  overflowY: "auto", // Bật cuộn theo chiều dọc
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ComboList = () => {
  const [combos, setCombos] = useState([]);
  const [filteredCombos, setFilteredCombos] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [imageURL, setImageURL] = useState("");
  const [selectedCombo, setSelectedCombo] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [comboDetails, setComboDetails] = useState({
    name: "",
    price: 0,
    products: [],
    categories: [],
  });
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [qty, setQty] = useState({});
  const [productPrices, setProductPrices] = useState({});
  const [errors, setErrors] = useState({});
  const [groupModifiers, setGroupModifiers] = useState([]);
  const [modifiers, setModifiers] = useState([]);
  const [selectedGroupModifiers, setSelectedGroupModifiers] = useState([]);
  // State để quản lý các modifier đã chọn
  const [selectedModifiers, setSelectedModifiers] = useState([]);
  // State để quản lý minQty và maxQty
  const [minQty, setMinQty] = useState(0);
  const [maxQty, setMaxQty] = useState(0);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: COLORS.BLUE,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: "0px 5px",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
    // height: 20,
  }));

  const fetchCombos = async () => {
    const response = await ComboService.getAllCombos();
    console.log("list combo", response.data);
    setCombos(response.data);
    setFilteredCombos(response.data);

    const data = response.data;
  };

  const fetchProducts = async () => {
    try {
      const response = await ProductService.getAllProducts();
      setProducts(response.data || []);
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch products:", error);
      setLoading(false);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await CategoryService.getAllCategories();
      setCategories(response.data.dataTable || []);
    } catch (error) {
      console.error("Failed to fetch categories:", error);
    }
  };

  const fetchModifiers = async () => {
    try {
      const response = await Modifiers.getAllModifiers();
      setModifiers(response.data.dataTable || []);
      console.log("modifier", response.data.dataTable);
    } catch (error) {
      console.error("Failed to fetch modifiers:", error);
      setModifiers([]);
    }
  };

  const fetchGroupModifiers = async () => {
    try {
      const response = await GroupService.getAllGroupModifiers(); // Use GroupService to fetch group modifiers
      setGroupModifiers(response.data || []);
      // console.log('fetchGroupModifiers',response.data);
    } catch (error) {
      console.error("Failed to fetch group modifiers:", error);
      setGroupModifiers([]);
    }
  };

  useEffect(() => {
    fetchGroupModifiers();
    fetchProducts();
    fetchCategories();
    fetchModifiers();
    fetchCombos();
  }, []);

  useEffect(() => {
    if (searchTerm) {
      const results = combos.filter((combo) =>
        combo.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredCombos(results);
    } else {
      console.log("image", combos);
      setFilteredCombos(combos);
    }
  }, [searchTerm, combos]);

  // image
  const handleImageSelect = (url) => {
    console.log("Selected URL:", url);
    // formValues.imagelink_square = url;
    setImageURL(url);
  };

  const handleOpenModal = (combo = null) => {
    console.log("Open modal", combo);
    if (combo) {
      // set image
      setImageURL(combo.image_link_square);
      // Fill modal with combo details for editing
      setComboDetails({
        name: combo.name,
        description: combo.description,
        price: combo.price,
        products: combo.product_combos.map((pc) => ({
          product_id: pc.product_id,
          qty: pc.qty,
          price: pc.price,
        })),
        categories: combo.categories.map((cat) => cat.ID),
      });
      setSelectedCombo(combo);
      setSelectedProducts(
        combo.product_combos.map((pc) => ({
          ID: pc.product_id,
          name: pc.Product.name,
        }))
      );
      setSelectedCategories(
        combo.categories.map((cat) => ({
          ID: cat.Category.ID,
          name: cat.Category.name,
        }))
      );
      setQty(
        combo.product_combos.reduce(
          (acc, curr) => ({ ...acc, [curr.product_id]: curr.qty }),
          {}
        )
      );
      setProductPrices(
        combo.product_combos.reduce(
          (acc, curr) => ({ ...acc, [curr.product_id]: curr.price }),
          {}
        )
      );
      // modifier

      setSelectedGroupModifiers(
        combo.product_groups.map((product_group) => {
          // Ensure that Group and group_modifiers are not null or undefined
          const groupModifiers = product_group.group?.group_modifiers || [];

          return {
            ID: product_group.group_id,
            Name: product_group.group?.name || "", // If name is not available, use an empty string
            MinQty: product_group.group?.min_qty || 0,
            MaxQty: product_group.group?.max_qty || 0,
            type: product_group.type,
            Modifier: groupModifiers.map((item) => ({
              ID: item.modifier_id,
              Default: item.default,
              Name: item.Modifier?.name || "", // Check if Modifier is available
              Price: item.Modifier?.price || 0,
              Currency: item.Modifier?.currency || "",
            })),
          };
        })
      );
    } else {
      // Reset modal for creating new combo
      setComboDetails({
        name: "",
        description: "",
        price: 0,
        products: [],
        categories: [],
      });
      setSelectedProducts([]);
      setSelectedCategories([]);
      setQty({});
      setProductPrices({});
      setSelectedCombo(null);
      setSelectedGroupModifiers([]);
    }
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setErrors({});
  };

  const handleSaveCombo = async () => {
    let validationErrors = {};

    // Kiểm tra nếu tên combo trống
    if (!comboDetails.name.trim()) {
      validationErrors.name = "Combo name is required";
    }

    // Kiểm tra nếu giá combo trống hoặc không hợp lệ
    if (
      !comboDetails.price ||
      isNaN(comboDetails.price) ||
      comboDetails.price <= 0
    ) {
      validationErrors.price = "Valid combo price is required";
    }

    // Kiểm tra nếu không có sản phẩm nào được chọn
    if (selectedProducts.length === 0) {
      validationErrors.selectedProducts =
        "At least one product must be selected";
    }

    // Kiểm tra nếu không có category nào được chọn
    if (selectedProducts.length === 0) {
      validationErrors.selectedCategory =
        "At least one category must be selected";
    }

    // Nếu có lỗi, dừng xử lý và hiển thị lỗi
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    // Nếu không có lỗi, tiếp tục lưu dữ liệu
    setErrors({});

    const updated_selectedGroupModifiers = selectedGroupModifiers.map(
      (group) => ({
        ...group,
        group_id: group.ID,
        is_combo: true,
      })
    );

    console.log(updated_selectedGroupModifiers);

    try {
      const comboData = {
        name: comboDetails.name,
        description: comboDetails.description,
        price: comboDetails.price,
        image_link_square: imageURL,
        image_link_portrait: imageURL,
        type: "Combo",
        product_combos: selectedProducts.map((product) => ({
          product_id: product.ID,
          qty: qty[product.ID] || 1,
          price: productPrices[product.ID] || 0,
        })),
        categories: selectedCategories.map((category) => ({
          category_id: category.ID,
        })),

        product_groups: updated_selectedGroupModifiers,
      };

      console.log("comboData", comboData);

      if (selectedCombo) {
        await ComboService.updateCombo(selectedCombo.ID, comboData);
        toast.success("Combo updated successfully.");
      } else {
        await ComboService.createCombo(comboData);
        toast.success("Combo created successfully.");
      }

      setOpenModal(false);
      const response = await ComboService.getAllCombos();
      setCombos(response.data);
      setFilteredCombos(response.data);
    } catch (error) {
      console.log("Failed to save combo:", error);
      toast.error("Failed to save combo.");
    }
  };

  const handleDeleteCombo = async (id) => {
    // try {
    //   await ComboService.deleteCombo(id);
    //   setCombos(combos.filter((combo) => combo.ID !== id));
    //   setFilteredCombos(filteredCombos.filter((combo) => combo.ID !== id));
    //   toast.success("Combo deleted successfully.");
    // } catch (error) {
    //   console.error("Failed to delete combo:", error);
    //   toast.error("Failed to delete combo.");
    // }
  };

  const changeCategory = async (value) => {
    console.log("Changing category", value);
    setSelectedCategories(value);
    setErrors({});
  };

  // Hàm xử lý chọn hoặc bỏ chọn modifier
  const handleModifierSelect = (modifierID) => {
    let updatedModifiers = [...selectedModifiers];
    const index = updatedModifiers.findIndex((m) => m.ID === modifierID);

    if (index !== -1) {
      updatedModifiers.splice(index, 1); // Nếu đã chọn thì bỏ chọn
    } else {
      updatedModifiers.push({ ID: modifierID, Default: false }); // Thêm modifier với default là false
    }

    // Tính toán số lượng các checkbox Select được chọn
    const selectedCount = updatedModifiers.length;

    // Cập nhật maxQty dựa trên số lượng này
    setMaxQty(selectedCount);

    setSelectedModifiers(updatedModifiers);
  };
  const handleCustomizeSelect = (value) => {
    // Lọc và thêm field type: true
    const updatedArray = selectedGroupModifiers.map((item) => {
      if (item.ID === value.ID) {
        return { ...item, type: !item.type };

        // } else if (item.type === undefined) {
        // return { ...item, type: false };
      }
      return item;
    });
    console.log("updatedArray", updatedArray);
    setSelectedGroupModifiers(updatedArray);
  };

  const handleDefaultChoiceChangeModifier = (modifierID) => {
    setSelectedModifiers((prevModifiers) => {
      const updatedModifiers = prevModifiers.map((modifier) =>
        modifier.ID === modifierID
          ? { ...modifier, Default: !modifier.Default }
          : modifier
      );

      // Tính toán số lượng các checkbox Default được chọn
      const checkedCount = updatedModifiers.filter(
        (modifier) => modifier.Default
      ).length;

      // Cập nhật minQty dựa trên số lượng này
      setMinQty(checkedCount);

      return updatedModifiers;
    });
  };

  const handleGroupModifierChange = (event, value) => {
    const updatedArray = value.map((item) => {
      // thêm field type default customize false
      if (item.type === undefined) {
        return { ...item, type: false };
      }
      return item;
    });
    console.log("handleGroupModifierChange", updatedArray);

    setSelectedGroupModifiers(updatedArray);
    // formValues.modifiers = updatedArray
  };

  return (
    <div style={{ width: "100%" }}>
      <Typography variant="h4" gutterBottom>
        Combo List
      </Typography>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleOpenModal()}
        >
          Add Combo
        </Button>

        <TextField
          label="Search"
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ width: "300px" }} // Đặt độ rộng cho TextField tìm kiếm
        />
      </div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>ID</StyledTableCell>
              <StyledTableCell>Image</StyledTableCell>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Description</StyledTableCell>
              <StyledTableCell>Price</StyledTableCell>
              <StyledTableCell align="right">Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredCombos.map((combo) => (
              <StyledTableRow key={combo.ID}>
                <StyledTableCell>{combo.ID}</StyledTableCell>
                <StyledTableCell>
                  {" "}
                  <img
                    src={BaseUrl + combo.image_link_square}
                    alt={BaseUrl + combo.image_link_square}
                    style={{ width: 100, borderRadius: "10px" }}
                    onError={(e) => {
                      e.target.onerror = null; // Ngăn chặn lặp vô hạn nếu ảnh mặc định cũng không tải được
                      e.target.src =
                        "https://dummyimage.com/150x150/cccccc/555555.png&text=No+Image"; // Gán ảnh lỗi mặc định
                    }}
                  />
                </StyledTableCell>
                <StyledTableCell>{combo.name}</StyledTableCell>
                <StyledTableCell>{combo.description}</StyledTableCell>
                <StyledTableCell>{combo.price}</StyledTableCell>
                <StyledTableCell align="right">
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleOpenModal(combo)}
                    sx={{ marginRight: 1 }}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => handleDeleteCombo(combo.ID)}
                  >
                    Delete
                  </Button>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-title" variant="h6" component="h2">
            {selectedCombo ? "Edit Combo" : "Add Combo"}
          </Typography>

          {/* image */}
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" component="h3" gutterBottom>
              Combo image
            </Typography>
            <ImagePicker
              onImageSelect={handleImageSelect}
              defaultImage={BaseUrl + imageURL}
            />
          </Grid>

          <TextField
            label="Combo Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={comboDetails.name}
            onChange={(e) => {
              setComboDetails({ ...comboDetails, name: e.target.value });
              setErrors({});
            }}
            error={!!errors.name}
            helperText={errors.name}
          />

          <TextField
            label="Decsription"
            variant="outlined"
            fullWidth
            margin="normal"
            value={comboDetails.description}
            onChange={(e) => {
              setComboDetails({ ...comboDetails, description: e.target.value });
              setErrors({});
            }}
            error={!!errors.description}
            helperText={errors.description}
          />

          <TextField
            label="Price"
            variant="outlined"
            fullWidth
            margin="normal"
            type="number"
            value={comboDetails.price}
            onChange={(e) => {
              setComboDetails({
                ...comboDetails,
                price: parseFloat(e.target.value),
              });
              setErrors({});
            }}
            error={!!errors.price}
            helperText={errors.price}
          />

          {loading ? (
            <CircularProgress />
          ) : (
            <Autocomplete
              multiple
              options={products}
              value={selectedProducts}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option.ID === value.ID}
              onChange={(event, newValue) => {
                setSelectedProducts(newValue);
                setErrors({});
              }}
              renderOption={(props, option) => {
                const { key, ...otherProps } = props; // Tách key ra khỏi props

                return (
                  <li {...otherProps} key={key}>
                    <Checkbox
                      checked={selectedProducts.some(
                        (product) => product.ID === option.ID
                      )}
                    />
                    {option.name}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Products"
                  placeholder="Select products"
                  error={!!errors.selectedProducts}
                  helperText={errors.selectedProducts}
                />
              )}
            />
          )}
          {selectedProducts.length > 0 && (
            <TableContainer component={Paper} sx={{ mt: 2, maxHeight: 300 }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Qty</TableCell>
                    <TableCell>Price</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedProducts.map((product) => (
                    <TableRow key={product.ID}>
                      <TableCell>{product.ID}</TableCell>
                      <TableCell>{product.name}</TableCell>
                      <TableCell>
                        <TextField
                          type="number"
                          value={qty[product.ID] || 1}
                          onChange={(e) =>
                            setQty({
                              ...qty,
                              [product.ID]: parseInt(e.target.value, 10),
                            })
                          }
                          sx={{ width: 60 }}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          type="number"
                          value={productPrices[product.ID] || 0}
                          onChange={(e) =>
                            setProductPrices({
                              ...productPrices,
                              [product.ID]: parseFloat(e.target.value),
                            })
                          }
                          sx={{ width: 80 }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          <Autocomplete
            multiple
            options={categories}
            value={selectedCategories}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.ID === value.ID}
            onChange={(event, newValue) => changeCategory(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Categories"
                placeholder="Select categories"
                error={!!errors.selectedCategory}
                helperText={errors.selectedCategory}
              />
            )}
            sx={{ marginTop: 2 }}
          />

          {/* modifier group */}
          <Grid item xs={12} sm={12}>
            <Typography variant="h6" component="h3" gutterBottom>
              Modifier
            </Typography>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              Modifier set customizations will apply to this item only.{" "}
              <Link href="#learn-more" underline="hover">
                Learn more
              </Link>
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 2, // Khoảng cách giữa các nút
              }}
            >
              {/* Autocomplete for selecting existing Group Modifiers */}
              <Autocomplete
                multiple
                disableCloseOnSelect
                options={groupModifiers}
                value={selectedGroupModifiers} // Đặt giá trị đã chọn vào đây
                isOptionEqualToValue={(option, value) =>
                  // fix warning
                  option.ID === value.ID
                } // Tùy chỉnh cách so sánh
                getOptionLabel={(option) => {
                  return option ? option.Name || "" : ""; // Đảm bảo trả về một chuỗi hợp lệ
                }}
                onChange={handleGroupModifierChange}
                sx={{
                  flex: 1, // Để các nút có kích thước bằng nhau
                }}
                renderOption={(props, option, index) => {
                  // Kiểm tra xem mục này đã được chọn chưa
                  const isSelected = selectedGroupModifiers.some(
                    (selected) => selected.ID === option.ID
                  );

                  return (
                    <li
                      {...{ ...props, key: undefined }} // Loại bỏ `key` từ `props`
                      key={option.ID + index}
                      style={{
                        color: isSelected ? COLORS.WHITE : "inherit",
                        backgroundColor: isSelected ? COLORS.BLUE : "inherit", // Màu nền xanh lá cho mục đã chọn
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "10px",
                        }}
                      >
                        <span>{option.Name}</span>
                        <span>- {option.Description}</span>
                      </Box>
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Search Group Modifiers"
                    placeholder="Select group modifiers"
                  />
                )}
              />
              {/* <Button
                        variant="contained"
                        sx={{
                            flex: 1, // Để các nút có kích thước bằng nhau
                            backgroundColor: '#f3f4f6',
                            color: '#007bff',
                            border: '1px solid #ccc',
                            textTransform: 'none',
                            '&:hover': {
                                backgroundColor: '#e7e7e7',
                            },
                        }}
                        onClick={handleModifierModalOpen} // Show Modifier modal
                    >
                        Create Group Modifier
                    </Button> */}
            </Box>

            {/* Hiển thị Group Modifiers đã chọn dưới nút "Add Modifier" */}
            {selectedGroupModifiers.length > 0 && (
              <Box
                mt={2}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  // justifyContent: 'space-between', // Giãn đều các phần tử
                  gap: 1, // Khoảng cách giữa các Typography
                  flexWrap: "wrap", // Cho phép xuống hàng khi không đủ chỗ
                }}
              >
                {selectedGroupModifiers.map((groupModifier, index) => (
                  <Grid item xs={12} sm={5} key={index}>
                    <Box key={index} mt={2}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between", // Giãn đều các phần tử
                          alignItems: "center", // Căn giữa theo chiều dọc
                          gap: 2, // Khoảng cách giữa các Typography
                          background: COLORS.BLUE,
                          paddingLeft: 2,
                          paddingRight: 2,
                          color: COLORS.WHITE,
                        }}
                      >
                        <Typography variant="h6" component="h3" gutterBottom>
                          {groupModifier.Name} {/* Display the Group Name */}
                        </Typography>
                        <Typography gutterBottom>
                          minQty: {groupModifier.MinQty}{" "}
                          {/* Display the Group Name */}
                        </Typography>
                        <Typography gutterBottom>
                          maxQty: {groupModifier.MaxQty}{" "}
                          {/* Display the Group Name */}
                        </Typography>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={groupModifier.type || false}
                              onChange={() =>
                                handleCustomizeSelect(groupModifier)
                              }
                            />
                          }
                          label="Customize"
                        />
                      </Box>
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Modifier Name</TableCell>
                              <TableCell>Price</TableCell>
                              <TableCell>Default Choice</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {groupModifier.Modifier.map((modifier, index) => (
                              <TableRow key={index}>
                                <TableCell>{modifier.Name}</TableCell>
                                <TableCell>
                                  {modifier.Currency} {modifier.Price}
                                </TableCell>
                                <TableCell>
                                  {modifier.Default ? "True" : "False"}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </Grid>
                ))}
              </Box>
            )}
          </Grid>

          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveCombo}
            sx={{ marginTop: 2 }}
          >
            {selectedCombo ? "Save Changes" : "Create Combo"}
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default ComboList;
