import React, { useState } from "react";
import {
  Box,
  Button,
  Step,
  StepLabel,
  Stepper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";
// import React from "react";
import { Container } from "@mui/material";
// import StepperProcess from "./StepperProcess";

const steps = ["Check Inventory", "Produce Product", "Transfer to Warehouse B"];

const StepperProcess = ({ data }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [warehouseA, setWarehouseA] = useState(data.warehouseA);
  const [warehouseB, setWarehouseB] = useState(data.warehouseB);
  const [products, setProducts] = useState(data.products);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [transfers, setTransfers] = useState([]);

  const handleNext = () => {
    if (activeStep === 1) {
      if (!selectedProduct) {
        alert("Please select a product to produce.");
        return;
      }

      // Find selected product
      const product = products.find((p) => p.id === Number(selectedProduct));
      if (!product) {
        alert("Product not found.");
        return;
      }

      // Check and deduct materials from warehouseA
      const newWarehouseA = [...warehouseA];
      for (const material of product.materials) {
        const inventory = newWarehouseA.find(
          (item) => item.id === material.materialId
        );
        if (!inventory || inventory.quantity < material.quantity) {
          alert(`Insufficient stock for Material ID: ${material.materialId}`);
          return;
        }
        inventory.quantity -= material.quantity;
      }

      setWarehouseA(newWarehouseA);

      // Add transfer record
      setTransfers((prev) => [
        ...prev,
        {
          id: transfers.length + 1,
          productName: product.name,
          status: "WaitingForTransfer",
        },
      ]);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleCompleteTransfer = (transferId) => {
    const transfer = transfers.find((t) => t.id === transferId);
    if (!transfer) return;

    // Add to warehouseB
    setWarehouseB((prev) => [
      ...prev,
      {
        id: transfer.id,
        name: transfer.productName,
        quantity: 1,
        unit: "product",
      },
    ]);

    // Update transfer status
    setTransfers((prev) =>
      prev.map((t) =>
        t.id === transferId ? { ...t, status: "Transferred" } : t
      )
    );
  };

  return (
    <Box sx={{ width: "100%", my: 4 }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      {activeStep === 0 && (
        <Box sx={{ my: 2 }}>
          <Typography variant="h6">Warehouse A Inventory</Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Material</TableCell>
                  <TableCell align="right">Quantity</TableCell>
                  <TableCell align="right">Unit</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {warehouseA.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>{item.name}</TableCell>
                    <TableCell align="right">{item.quantity}</TableCell>
                    <TableCell align="right">{item.unit}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}

      {activeStep === 1 && (
        <Box sx={{ my: 2 }}>
          <Typography variant="h6">Produce Product</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                select
                label="Select Product"
                value={selectedProduct}
                onChange={(e) => setSelectedProduct(e.target.value)}
                fullWidth
              >
                {products.map((product) => (
                  <MenuItem key={product.id} value={product.id}>
                    {product.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </Box>
      )}

      {activeStep === 2 && (
        <Box sx={{ my: 2 }}>
          <Typography variant="h6">Transfers</Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Product</TableCell>
                  <TableCell align="right">Status</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transfers.map((transfer) => (
                  <TableRow key={transfer.id}>
                    <TableCell>{transfer.productName}</TableCell>
                    <TableCell align="right">{transfer.status}</TableCell>
                    <TableCell align="right">
                      {transfer.status === "WaitingForTransfer" && (
                        <Button
                          variant="contained"
                          color="success"
                          onClick={() => handleCompleteTransfer(transfer.id)}
                        >
                          Complete Transfer
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Typography variant="h6" sx={{ mt: 4 }}>
            Warehouse B Inventory
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Product</TableCell>
                  <TableCell align="right">Quantity</TableCell>
                  <TableCell align="right">Unit</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {warehouseB.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>{item.name}</TableCell>
                    <TableCell align="right">{item.quantity}</TableCell>
                    <TableCell align="right">{item.unit}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}

      <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
        <Button disabled={activeStep === 0} onClick={handleBack}>
          Back
        </Button>
        {activeStep < steps.length - 1 && (
          <Button variant="contained" onClick={handleNext}>
            Next
          </Button>
        )}
      </Box>
    </Box>
  );
};

const initialData = {
  warehouseA: [
    { id: 1, name: "Material A1", quantity: 100, unit: "kg" },
    { id: 2, name: "Material A2", quantity: 80, unit: "kg" },
    { id: 3, name: "Material A3", quantity: 60, unit: "kg" },
    { id: 4, name: "Material A4", quantity: 40, unit: "kg" },
    { id: 5, name: "Material A5", quantity: 20, unit: "kg" },
    { id: 6, name: "Material A6", quantity: 50, unit: "kg" },
  ],
  warehouseB: [],
  products: [
    {
      id: 1,
      name: "Product 1",
      materials: [
        { materialId: 1, quantity: 10 },
        { materialId: 2, quantity: 5 },
      ],
    },
    {
      id: 2,
      name: "Product 2",
      materials: [
        { materialId: 3, quantity: 8 },
        { materialId: 4, quantity: 12 },
      ],
    },
  ],
};

const App = () => {
  return (
    <Container>
      <StepperProcess data={initialData} />
    </Container>
  );
};

export default App;
