import axios from "axios";
import config from "../config";

const API_URL = config.apiBaseUrl;

// UnitConversion APIs
export const fetchUnitConversions = (materialId) =>
  axios.get(`${API_URL}/material/${materialId}/unit-conversions`);
export const fetchAllUnitConversions = () =>
  axios.get(`${API_URL}/material/unit-conversions`);
export const createUnitConversion = (data) =>
  axios.post(`${API_URL}/unit-conversions`, data);
export const updateUnitConversion = (id, data) =>
  axios.put(`${API_URL}/unit-conversions/${id}`, data);
export const deleteUnitConversion = (id) =>
  axios.delete(`${API_URL}/unit-conversions/${id}`);

// Warehouse APIs
export const fetchWarehouses = () => axios.get(`${API_URL}/warehouses`);
export const createWarehouse = (data) =>
  axios.post(`${API_URL}/warehouses`, data);
export const updateWarehouse = (id, data) =>
  axios.put(`${API_URL}/warehouses/${id}`, data);
export const deleteWarehouse = (id) =>
  axios.delete(`${API_URL}/warehouses/${id}`);

// ExportRecord APIs
export const fetchExports = () => axios.get(`${API_URL}/exports`);
export const createExportRecord = (data) =>
  axios.post(`${API_URL}/exports`, data);
