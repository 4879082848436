import React, { useState } from "react";
import { Button, Select, MenuItem, TextField, Grid } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";

const ProductReportUI = ({ onFetchReport, products }) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState("");

  const handleFetchReport = () => {
    if (!selectedDate || !selectedProduct) {
      alert("Please select both a date and a product.");
      return;
    }
    onFetchReport(selectedProduct, selectedDate.format("YYYY-MM-DD"));
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        {/* Chọn ngày */}
        <Grid item>
          <DatePicker
            label="Select Date"
            value={selectedDate}
            onChange={(date) => setSelectedDate(date)}
            renderInput={(params) => <TextField {...params} />}
          />
        </Grid>

        {/* Chọn sản phẩm */}
        <Grid item>
          <Select
            value={selectedProduct}
            onChange={(e) => setSelectedProduct(e.target.value)}
            displayEmpty
            style={{ minWidth: 200 }}
          >
            <MenuItem value="" disabled>
              Select Product
            </MenuItem>
            {products.map((product) => (
              <MenuItem key={product.id} value={product.id}>
                {product.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        {/* Nút gửi */}
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={handleFetchReport}
          >
            Fetch Report
          </Button>
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
};

const App = () => {
  const [chartOptions, setChartOptions] = useState(null);

  const products = [
    { id: 104, name: "104" },
    { id: 2, name: "Product B" },
    { id: 3, name: "Product C" },
  ];

  const fetchReport = async (productID, date) => {
    try {
      const response = await fetch(
        `http://localhost:8080/api/report/product/hourly-report?productID=${productID}&startDate=${date}`
      );
      const data = await response.json();
      console.log("check data", data);

      const todayData = data.filter((item) => item.day === "Today");
      const yesterdayData = data.filter((item) => item.day === "Yesterday");

      const categories = Array.from({ length: 24 }, (_, i) => `${i}:00`);
      const series = [
        {
          name: "Yesterday",
          data: categories.map(
            (_, i) => yesterdayData.find((d) => d.hour === i)?.quantity || 0
          ),
        },
        {
          name: "Today",
          data: categories.map(
            (_, i) => todayData.find((d) => d.hour === i)?.quantity || 0
          ),
        },
      ];

      setChartOptions({
        chart: { type: "column" },
        title: { text: "Hourly Product Report" },
        xAxis: { categories, title: { text: "Hour" } },
        yAxis: { title: { text: "Quantity" } },
        series,
      });
    } catch (error) {
      console.log("Failed to fetch report:", error.response.data);
    }
  };

  return (
    <div style={{ padding: "20px" }}>
      <ProductReportUI onFetchReport={fetchReport} products={products} />

      {chartOptions && (
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      )}
    </div>
  );
};

export default App;
