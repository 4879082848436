// src/components/RecipeForm.js

import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Autocomplete,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import InventoryService, {
  createRecipe,
  updateRecipe,
} from "../../services/InventoryService";

const RecipeForm = ({
  productId,
  existingRecipe,
  existingMaterialIds,
  recipeId,
  recipeType,
  onClose,
  onSuccess,
  onError,
}) => {
  const [materials, setMaterials] = useState([]);
  const [loading, setLoading] = useState(true);
  const [unit, setUnit] = useState("");
  console.log("id product", productId);

  const schema = Yup.object().shape({
    material_id: Yup.number().required("Please select a material."),
    quantity: Yup.number()
      .required("Please enter a quantity.")
      .positive("Quantity must be greater than 0."),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      material_id: existingRecipe ? existingRecipe.material_id : "",
      quantity: existingRecipe ? existingRecipe.quantity : "",
    },
  });

  useEffect(() => {
    const getMaterials = async () => {
      try {
        const response = await InventoryService.getAllMaterial();
        let fetchedMaterials = response.data;

        // Loại bỏ các materials đã có trong recipe
        if (!existingRecipe) {
          // Nếu đang thêm mới (không phải chỉnh sửa), loại bỏ materials đã có
          fetchedMaterials = fetchedMaterials.filter(
            (material) => !existingMaterialIds.includes(material.ID)
          );
        } else {
          // Nếu đang chỉnh sửa, cho phép material hiện tại được hiển thị
          fetchedMaterials = fetchedMaterials.filter(
            (material) =>
              !existingMaterialIds.includes(material.ID) ||
              material.ID === existingRecipe.material_id
          );
        }

        setMaterials(fetchedMaterials);
      } catch (err) {
        console.error("Failed to fetch materials:", err);
        onError("Failed to fetch materials from server.");
      } finally {
        setLoading(false);
      }
    };
    getMaterials();
  }, [onError]);

  const handleDetailChange = (value, materials) => {
    const material = materials.find((m) => m.ID === value);
    console.log("value", material.unit);
    setUnit(material.unit);
  };

  useEffect(() => {
    if (materials.length > 0 && existingRecipe) {
      console.log(existingRecipe);
      handleDetailChange(existingRecipe.material_id, materials);
    }
  }, [existingRecipe, materials]);

  const onSubmit = async (data) => {
    try {
      const payload = {
        product_id: productId,
        variant_id: 0,
        type: recipeType,
        details: [
          {
            material_id: data.material_id,
            quantity: data.quantity,
          },
        ],
      };
      console.log("payload", payload);
      if (existingRecipe) {
        await updateRecipe(recipeId, payload);
        console.log("update", payload);
      } else {
        await createRecipe(payload);
      }
      onSuccess();
      reset();
      onClose();
    } catch (err) {
      console.error("Failed to save recipe:", err);
      onError(err.response?.data?.error || "Failed to save recipe.");
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        {/* Material Selection */}
        <Grid item xs={12}>
          <FormControl
            fullWidth
            variant="outlined"
            error={!!errors.material_id}
          >
            {/* <InputLabel id="material-label">Material</InputLabel> */}
            <Controller
              name="material_id"
              control={control}
              defaultValue={null} // Đảm bảo giá trị mặc định là null
              render={({ field }) => (
                // <Select
                //     {...field}
                //     labelId="material-label"
                //     label="Material"
                //     disabled={loading}
                //     onChange={(e) => {
                //         field.onChange(e.target.value); // Update value for react-hook-form
                //         handleDetailChange(e.target.value, materials);
                //     }}
                // >
                //     {materials.map((material) => (
                //         <MenuItem key={material.ID} value={material.ID}>
                //             {material.name}
                //         </MenuItem>
                //     ))}
                // </Select>
                <Autocomplete
                  options={materials}
                  getOptionLabel={(option) => option.name || ""}
                  isOptionEqualToValue={(option, value) =>
                    option.ID === value.ID
                  }
                  loading={loading}
                  onChange={(event, newValue) => {
                    field.onChange(newValue ? newValue.ID : ""); // Cập nhật value cho react-hook-form
                    handleDetailChange(
                      newValue ? newValue.ID : null,
                      materials
                    ); // Gọi hàm xử lý khi chọn material
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Material"
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {loading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              )}
            />
            {errors.material_id && (
              <Typography color="error" variant="body2">
                {errors.material_id.message}
              </Typography>
            )}
          </FormControl>
        </Grid>

        {/* Quantity Input */}
        <Grid item xs={12}>
          <Controller
            name="quantity"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                type="number"
                label="Quantity"
                variant="outlined"
                fullWidth
                error={!!errors.quantity}
                helperText={errors.quantity?.message}
                inputProps={{ step: "0.01" }}
              />
            )}
          />
        </Grid>

        {/* Unit */}
        <Grid item xs={12}>
          <Controller
            name={`unit`}
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                value={unit}
                disabled
                label="Unit"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>

        {/* Submit Button */}
        <Grid item xs={12}>
          <Button variant="contained" color="primary" type="submit" fullWidth>
            {existingRecipe ? "Update Recipe" : "Add Recipe"}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default RecipeForm;
