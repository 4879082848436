import axios from "axios";
import config from "../config"; // Import URL từ file config

const API_URL = config.apiBaseUrl;

const GroupCustomerService = {
  // Fetch all groups
  getGroups: async () => {
    return axios.get(`${API_URL}/groups`);
  },

  // Fetch customers in a group
  getGroupCustomers: async (groupId) => {
    return axios.get(`${API_URL}/groups/${groupId}/customers`);
  },

  // Search customers
  searchCustomers: async (query) => {
    return axios.get(`${API_URL}/customers/search?q=${query}`);
  },

  // Add customer to a group
  addCustomerToGroup: async (groupId, customerId) => {
    return axios.post(`${API_URL}/groups/${groupId}/customers/${customerId}`);
  },

  // Create a new group
  createGroup: async (groupData) => {
    return axios.post(`${API_URL}/groups`, groupData);
  },
  // Remove customer from group
  removeCustomerFromGroup: async (groupId, customerId) => {
    return axios.delete(`${API_URL}/groups/${groupId}/customers/${customerId}`);
  },
};

export default GroupCustomerService;
