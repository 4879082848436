import axios from "axios";
import config from "../config";

const API_URL = config.apiBaseUrl + "/upload" ;
const BaseUrl = config.apiBaseUrl + "/images" ;

const uploadimage = {
    // upload image
    uploadimage(data) {
        return axios.post(API_URL, data);
    },
     // delete image by id
    deleteImage(id) {
        return axios.delete(`${BaseUrl}/${id}`);
    },
};

export default uploadimage;