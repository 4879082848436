// src/components/CustomerForm.js
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import CustomerService from "../../services/CustomerService";

const CustomerForm = ({ customer, onClose, onCustomerUpdated }) => {
  const [formData, setFormData] = useState({ ...customer });
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleUpdate = async () => {
    console.log("update", formData);
    if (formData.deleted == "") formData.deleted = null;
    setLoading(true);
    try {
      await CustomerService.updateCustomer(customer.id, formData);
      setSnackbar({
        open: true,
        message: "Customer updated successfully!",
        severity: "success",
      });
      onCustomerUpdated(); // Trigger parent to reload customer list
      onClose();
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Failed to update customer.",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>Edit Customer Information</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Name"
            name="username"
            fullWidth
            variant="outlined"
            value={formData.username}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Email"
            name="email"
            type="email"
            fullWidth
            variant="outlined"
            value={formData.email}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Phone"
            name="mobile_number"
            fullWidth
            variant="outlined"
            value={formData.mobile_number}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="RefID"
            name="ref_id"
            fullWidth
            variant="outlined"
            value={formData.ref_id}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="DeleteAt"
            name="deleted"
            fullWidth
            variant="outlined"
            value={formData.deleted}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Code"
            name="code"
            fullWidth
            variant="outlined"
            value={formData.code}
            onChange={handleChange}
          />
          <FormControl fullWidth variant="outlined" margin="dense">
            <InputLabel>Role</InputLabel>
            <Select
              label="Role"
              name="role"
              value={formData.role}
              onChange={handleChange}
            >
              <MenuItem value="user">User</MenuItem>
              <MenuItem value="KOL">KOL</MenuItem>
              <MenuItem value="STAFF">Staff</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} disabled={loading}>
            Cancel
          </Button>
          <Button
            onClick={handleUpdate}
            variant="contained"
            color="primary"
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : "Update"}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default CustomerForm;
