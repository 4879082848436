import React, { useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Alert,
} from '@mui/material';
import axios from 'axios';
import { fetchOrderTimeRangeReport } from '../../services/ReportService';
import * as XLSX from 'xlsx';

// Export data to Excel
const exportToExcel = (category, data) => {
  const rows = [];

  // Prepare header row
  const header = ['Product', ...Object.keys(data[0].dates)];
  rows.push(header);

  // Prepare data rows
  data.forEach((product) => {
    const row = [product.product_name, ...Object.values(product.dates)];
    rows.push(row);
  });

  // Convert to worksheet and create workbook
  const worksheet = XLSX.utils.aoa_to_sheet(rows);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, category);

  // Export as Excel file
  XLSX.writeFile(workbook, `${category}.xlsx`);
};

// Transform the fetched data for display
// const transformData = (data) => {
//   const categoryMap = {};

//   data.daily_reports.forEach((report) => {
//     const date = report.date;

//     report.product_sales.forEach((product) => {
//       const { category_id, category_name, product_name, total_quantity } = product;

//       if (!categoryMap[category_id]) {
//         categoryMap[category_id] = {
//           category_name,
//           data: {},
//         };
//       }

//       if (!categoryMap[category_id].data[product_name]) {
//         categoryMap[category_id].data[product_name] = {};
//       }

//       categoryMap[category_id].data[product_name][date] = total_quantity;
//     });
//   });

//   return Object.values(categoryMap).map((category) => ({
//     category_name: category.category_name,
//     data: Object.entries(category.data).map(([product_name, dates]) => ({
//       product_name,
//       dates,
//     })),
//   }));
// };
const normalizeName = (name) =>
  name
    .trim()
    .toLowerCase()
    .replace(/[^a-z0-9\s]/gi, "")
    .replace(/\s+/g, " ");

const savouryItems = [
  "chicken caesar salad",
  "frittata croissant",
  "ham cheese croissant",
  "ham potato salad",
  "mushroom avocado ciabatta",
  "mushroom onion sourdough",
  "turkey onion sourdough",
  "viet sub",
  "turkey rotis sourdough",
  "caprese bite",
  "vegan pate bun",
  "breakfast sandwich",
];

// Transform data to split Bakery into Savoury and Pastry categories
// const transformData = (data) => {
//   const categoryMap = {};

//   data.daily_reports.forEach((report) => {
//     const date = report.date;

//     report.product_sales.forEach((product) => {
//       const { category_id, category_name, product_name, total_quantity } = product;

//       // Normalize the product name for comparison
//       const normalizedProductName = normalizeName(product_name);

//       // Check if the product belongs to the Savoury category
//       const isSavoury = savouryItems.includes(normalizedProductName);

//       // Update category name if the product is in Bakery
//       let newCategoryName = category_name;
//       if (category_name.toLowerCase() === "bakery") {
//         newCategoryName = isSavoury ? "Savoury" : "Pastry";
//       }

//       // Add the product to the appropriate category
//       if (!categoryMap[newCategoryName]) {
//         categoryMap[newCategoryName] = {
//           category_name: newCategoryName,
//           data: {},
//         };
//       }

//       if (!categoryMap[newCategoryName].data[product_name]) {
//         categoryMap[newCategoryName].data[product_name] = {};
//       }

//       categoryMap[newCategoryName].data[product_name][date] = total_quantity;
//     });
//   });

//   return Object.values(categoryMap).map((category) => ({
//     category_name: category.category_name,
//     data: Object.entries(category.data).map(([product_name, dates]) => ({
//       product_name,
//       dates,
//     })),
//   }));
// };
const transformData = (data) => {
  // Ensure data and daily_reports exist
  if (!data || !Array.isArray(data.daily_reports)) {
    console.error("Invalid data structure:", data);
    return [];
  }

  const categoryMap = {};

  data.daily_reports.forEach((report) => {
    const date = report.date;

    // Safeguard against null product_sales and combo_sales
    const productSales = report.product_sales || [];
    const comboSales = report.combo_sales || [];

    // Handle product sales
    productSales.forEach((product) => {
      const { category_id, category_name, product_name, total_quantity } = product;

      // Normalize the product name for comparison
      const normalizedProductName = normalizeName(product_name);

      // Check if the product belongs to the Savoury category
      const isSavoury = savouryItems.includes(normalizedProductName);

      // Update category name if the product is in Bakery
      let newCategoryName = category_name;
      if (category_name.toLowerCase() === "bakery") {
        newCategoryName = isSavoury ? "Savoury" : "Pastry";
      }

      // Add the product to the appropriate category
      if (!categoryMap[newCategoryName]) {
        categoryMap[newCategoryName] = {
          category_name: newCategoryName,
          data: {},
        };
      }

      if (!categoryMap[newCategoryName].data[product_name]) {
        categoryMap[newCategoryName].data[product_name] = {};
      }

      categoryMap[newCategoryName].data[product_name][date] = total_quantity;
    });

    // Handle combo sales
    comboSales.forEach((combo) => {
      const { category_id, category_name, combo_name, total_quantity } = combo;

      // Add the combo to the appropriate category
      if (!categoryMap[category_name]) {
        categoryMap[category_name] = {
          category_name: category_name,
          data: {},
        };
      }

      if (!categoryMap[category_name].data[combo_name]) {
        categoryMap[category_name].data[combo_name] = {};
      }

      categoryMap[category_name].data[combo_name][date] = total_quantity;
    });
  });

  return Object.values(categoryMap).map((category) => ({
    category_name: category.category_name,
    data: Object.entries(category.data).map(([name, dates]) => ({
      product_name: name, // Includes both products and combos
      dates,
    })),
  }));
};

// Product Sales Table Component
const ProductSalesTable = ({ data }) => {
  const transformedData = transformData(data);

  return (
    <Box>
      {transformedData.map((category) => (
        <Box key={category.category_name} mb={4}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              {category.category_name}
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => exportToExcel(category.category_name, category.data)}
            >
              Export to Excel
            </Button>
          </Box>
          
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>Product</TableCell>
                  {Object.keys(category.data[0].dates).map((date) => (
                    <TableCell key={date} sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                      {date}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {category.data.map((product) => (
                  <TableRow key={product.product_name}>
                    <TableCell>{product.product_name}</TableCell>
                    {Object.keys(product.dates).map((date) => (
                      <TableCell key={date} align="center">
                        {product.dates[date]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      ))}
    </Box>
  );
};

// Main App Component
export default function App() {
  const [data, setData] = useState(null);
  const [startDate, setStartDate] = useState('2025-01-14');
  const [endDate, setEndDate] = useState('2025-01-16');
  const [startTime, setStartTime] = useState('08:00:00');
  const [endTime, setEndTime] = useState('16:00:00');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const fetchData = async () => {
    setLoading(true);
    setError('');
    try {
      const response = await fetchOrderTimeRangeReport(startDate,endDate,startTime,endTime);
      setData(response.data);
    } catch (error) {
      setError('Failed to fetch data. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ p: 4 }}>
      {/* Input Form */}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" sx={{ mb: 2 }}>
          Fetch Report
        </Typography>
        <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', mb: 2 }}>
          <TextField
            label="Start Date (YYYY-MM-DD)"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            variant="outlined"
            size="small"
          />
          <TextField
            label="End Date (YYYY-MM-DD)"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            variant="outlined"
            size="small"
          />
          <TextField
            label="Start Time (HH:MM:SS)"
            value={startTime}
            onChange={(e) => setStartTime(e.target.value)}
            variant="outlined"
            size="small"
          />
          <TextField
            label="End Time (HH:MM:SS)"
            value={endTime}
            onChange={(e) => setEndTime(e.target.value)}
            variant="outlined"
            size="small"
          />
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={fetchData}
          disabled={loading}
        >
          Fetch Report
        </Button>
      </Box>

      {/* Error Handling */}
      {error && <Alert severity="error">{error}</Alert>}

      {/* Loading Indicator */}
      {loading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      )}

      {/* Data Display */}
      {!loading && data && <ProductSalesTable data={data} />}
      {!loading && !data && !error && (
        <Typography sx={{ mt: 4, textAlign: 'center', color: 'gray' }}>
          No data available. Please fetch a report.
        </Typography>
      )}
    </Box>
  );
}