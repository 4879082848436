// RevenueReport.js
import React, { useState, useEffect } from "react";
import { fetchRevenueReport } from "../../services/ReportService";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
  MenuItem,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Grid,
} from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

function RevenueReport() {
  const [reports, setReports] = useState([]);
  const [reportsWeek, setReportsWeek] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [period, setPeriod] = useState("daily");
  const [selectedTransactions, setSelectedTransactions] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedTransactionId, setSelectedTransactionId] = useState(null); // State to store selected transaction ID
  const [metadata, setMetadata] = useState(null);
  const [openMetadataDialog, setOpenMetadataDialog] = useState(false);
  const [chartData, setchartData] = useState(null);
  const [chartDataTopup, setchartDataTopup] = useState(null);

  // report tuần

  // Hàm tính ngày từ Thứ 2 tuần trước đến Chủ nhật tuần này

  const calculateWeekDates = () => {
    const today = new Date();

    // Tính ngày Thứ 2 của tuần trước
    const lastMonday = new Date(today);
    const currentDay = today.getDay(); // 0 = Chủ nhật, 1 = Thứ 2, ..., 6 = Thứ 7

    // Nếu hôm nay là Chủ nhật (0), lùi về Thứ 2 tuần trước
    if (currentDay === 0) {
      lastMonday.setDate(today.getDate() - 6 - 7);
    } else {
      // Các ngày còn lại, tính từ Thứ 2 tuần trước
      lastMonday.setDate(today.getDate() - currentDay - 6);
    }

    // Tính ngày Chủ nhật của tuần này
    const thisSunday = new Date(lastMonday);
    thisSunday.setDate(lastMonday.getDate() + 13);

    return {
      startDate: lastMonday.toISOString().split("T")[0], // Thứ 2 tuần trước
      endDate: thisSunday.toISOString().split("T")[0], // Chủ nhật tuần này
    };
  };

  // Hàm bổ sung ngày bị thiếu
  const fillMissingDates = (data, startDate, endDate) => {
    const filledData = [];
    const start = new Date(startDate);
    const end = new Date(endDate);

    const allDates = [];
    while (start <= end) {
      allDates.push(new Date(start).toISOString().split("T")[0]);
      start.setDate(start.getDate() + 1);
    }

    allDates.forEach((date) => {
      const existingData = data.find((item) => item.summary.date === date);
      console.log("checkdate", existingData);
      if (existingData) {
        filledData.push(existingData);
      } else {
        filledData.push({
          summary: {
            date,
            total_spend: 0,
            total_topup: 0,
            total_promotion: 0,
            total_earnings: 0,
            net_amount: 0,
          },
        });
      }
    });

    return filledData;
  };

  const processChartData = () => {
    const { startDate, endDate } = calculateWeekDates();

    // Bổ sung ngày bị thiếu
    const filledData = fillMissingDates(reportsWeek, startDate, endDate);

    const mondayLastWeek = new Date(startDate);
    const labels = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    const lastWeekValues = [];
    const thisWeekValues = [];

    labels.forEach((_, index) => {
      // Tạo ngày tuần trước
      const lastWeekDate = new Date(mondayLastWeek);
      lastWeekDate.setDate(mondayLastWeek.getDate() + index);
      const lastWeekStr = lastWeekDate.toISOString().split("T")[0];

      // Tạo ngày tuần này
      const thisWeekDate = new Date(lastWeekDate);
      thisWeekDate.setDate(lastWeekDate.getDate() + 7);
      const thisWeekStr = thisWeekDate.toISOString().split("T")[0];

      // Lấy dữ liệu
      const lastWeekData = filledData.find(
        (r) => r.summary.date === lastWeekStr
      );
      const thisWeekData = filledData.find(
        (r) => r.summary.date === thisWeekStr
      );

      // console.log("check", thisWeekData);
      // Đẩy giá trị vào mảng
      lastWeekValues.push(lastWeekData ? lastWeekData.summary.total_spend : 0);
      thisWeekValues.push(thisWeekData ? thisWeekData.summary.total_spend : 0);
    });

    return {
      categories: labels,
      series: [
        {
          name: "Last Week",
          data: lastWeekValues,
          // color: "#D2691E",
          color: "rgba(158, 159, 163, 0.5)",
          pointPlacement: 0.2,
          // linkedTo: "main",
        },
        {
          name: "This Week",
          data: thisWeekValues,
          color: "#228B22",
          // id: "main",
        },
      ],
    };
  };

  const processChartDataTopup = () => {
    const { startDate, endDate } = calculateWeekDates();

    // Bổ sung ngày bị thiếu
    const filledData = fillMissingDates(reportsWeek, startDate, endDate);

    const mondayLastWeek = new Date(startDate);
    const labels = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    const lastWeekValues = [];
    const thisWeekValues = [];

    labels.forEach((_, index) => {
      // Tạo ngày tuần trước
      const lastWeekDate = new Date(mondayLastWeek);
      lastWeekDate.setDate(mondayLastWeek.getDate() + index);
      const lastWeekStr = lastWeekDate.toISOString().split("T")[0];

      // Tạo ngày tuần này
      const thisWeekDate = new Date(lastWeekDate);
      thisWeekDate.setDate(lastWeekDate.getDate() + 7);
      const thisWeekStr = thisWeekDate.toISOString().split("T")[0];

      // Lấy dữ liệu
      const lastWeekData = filledData.find(
        (r) => r.summary.date === lastWeekStr
      );
      const thisWeekData = filledData.find(
        (r) => r.summary.date === thisWeekStr
      );

      // console.log("check", thisWeekData);
      // Đẩy giá trị vào mảng
      lastWeekValues.push(lastWeekData ? lastWeekData.summary.total_topup : 0);
      thisWeekValues.push(thisWeekData ? thisWeekData.summary.total_topup : 0);
    });

    return {
      categories: labels,
      series: [
        {
          name: "Last Week",
          data: lastWeekValues,
          color: "rgba(158, 159, 163, 0.5)",
          pointPlacement: 0.2,
          // linkedTo: "main",
        },
        {
          name: "This Week",
          data: thisWeekValues,
          color: "#228B22",
          // id: "main",
        },
      ],
    };
  };

  const fetchWeekDataForChart = async () => {
    const { startDate, endDate } = calculateWeekDates();
    try {
      const response = await fetchRevenueReport(startDate, endDate, "daily");
      const data = response.data.data;
      console.log("Weekly Data for Chart:", data);
      // Lưu dữ liệu để hiển thị trong chart
      setReportsWeek(data);
      setReports(data);
    } catch (error) {
      console.error("Error fetching weekly data:", error);
    }
  };

  useEffect(() => {
    fetchWeekDataForChart();
  }, []);

  useEffect(() => {
    if (reports?.length > 0) {
      setchartData(processChartData());
      setchartDataTopup(processChartDataTopup());
    }
  }, [reports]);
  // Chuẩn bị cấu hình cho Highcharts

  const spend = {
    chart: {
      type: "column",
      backgroundColor: "transparent",
      width: null, // Để null, Highcharts sẽ tự động điều chỉnh theo container
    },
    title: {
      text: "Revenue Spend Comparison: Last Week vs This Week",
    },
    xAxis: {
      categories: chartData?.categories, // Thứ 2 -> Chủ nhật
    },
    yAxis: {
      min: 0,
      title: {
        text: "Revenue ($)",
      },
    },
    tooltip: {
      shared: true,
      headerFormat: "<b>{point.key}</b><br/>",
      pointFormat:
        '<span style="color:{series.color}">{series.name}</span>: <b>${point.y:.2f}</b><br/>',
    },
    plotOptions: {
      column: {
        grouping: true, // Đặt true để các cột nhóm cạnh nhau
        // pointPadding: 0, // Giảm khoảng cách giữa các cột trong một nhóm
        // groupPadding: 0, // Giảm khoảng cách giữa các nhóm cột
        shadow: false,
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          formatter: function () {
            return this.y.toFixed(2); // Định dạng số với 2 chữ số thập phân
          },
          style: {
            fontSize: "10px",
            fontWeight: "bold",
            color: "#000000", // Màu chữ hiển thị trên cột
          },
        },
      },
    },
    series: chartData?.series, // Dữ liệu tuần trước và tuần này
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 600, // Áp dụng nếu màn hình nhỏ hơn 600px
          },
          chartOptions: {
            legend: {
              layout: "horizontal",
              align: "center",
              verticalAlign: "bottom",
            },
          },
        },
      ],
    },
  };

  const topup = {
    chart: {
      type: "column",
      backgroundColor: "transparent",
      width: null, // Để null, Highcharts sẽ tự động điều chỉnh theo container
    },
    title: {
      text: "Revenue TopUp Comparison: Last Week vs This Week",
    },
    xAxis: {
      categories: chartDataTopup?.categories, // Thứ 2 -> Chủ nhật
    },
    yAxis: {
      min: 0,
      title: {
        text: "Revenue ($)",
      },
    },
    tooltip: {
      shared: true,
      headerFormat: "<b>{point.key}</b><br/>",
      pointFormat:
        '<span style="color:{series.color}">{series.name}</span>: <b>${point.y:.2f}</b><br/>',
    },
    plotOptions: {
      column: {
        grouping: true, // Đặt true để các cột nhóm cạnh nhau
        // pointPadding: 0, // Giảm khoảng cách giữa các cột trong một nhóm
        // groupPadding: 0, // Giảm khoảng cách giữa các nhóm cột
        shadow: false,
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          formatter: function () {
            return this.y.toFixed(2); // Định dạng số với 2 chữ số thập phân
          },
          style: {
            fontSize: "10px",
            fontWeight: "bold",
            color: "#000000", // Màu chữ hiển thị trên cột
          },
        },
      },
    },
    series: chartDataTopup?.series, // Dữ liệu tuần trước và tuần này
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 600, // Áp dụng nếu màn hình nhỏ hơn 600px
          },
          chartOptions: {
            legend: {
              layout: "horizontal",
              align: "center",
              verticalAlign: "bottom",
            },
          },
        },
      ],
    },
  };

  ///////////////////

  useEffect(() => {
    if (startDate && endDate) {
      console.log("fetch");
      fetchReport();
    }
  }, [startDate, endDate, period]);

  const fetchReport = async () => {
    try {
      const response = await fetchRevenueReport(startDate, endDate, period);
      const data = response.data.data;
      console.log("check", response.data.data);
      setReports(data);
    } catch (error) {
      console.error("Error fetching report:", error);
    }
  };

  const handleViewTransactions = (transactions) => {
    setSelectedTransactions(transactions);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedTransactions([]);
  };

  const handleRowClick = (metadata) => {
    setMetadata(metadata);
    setOpenMetadataDialog(true);
  };
  const handleCloseMetadataDialog = () => {
    setOpenMetadataDialog(false);
    setMetadata(null);
  };

  return (
    <div>
      <h2>Weekly Revenue Comparison</h2>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <HighchartsReact highcharts={Highcharts} options={spend} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <HighchartsReact highcharts={Highcharts} options={topup} />
        </Grid>
      </Grid>

      <h2>Revenue Report</h2>

      <TextField
        label="Start Date"
        type="date"
        value={startDate}
        onChange={(e) => setStartDate(e.target.value)}
        InputLabelProps={{ shrink: true }}
        style={{ marginRight: 10 }}
      />
      <TextField
        label="End Date"
        type="date"
        value={endDate}
        onChange={(e) => setEndDate(e.target.value)}
        InputLabelProps={{ shrink: true }}
        style={{ marginRight: 10 }}
      />
      <Select
        value={period}
        onChange={(e) => setPeriod(e.target.value)}
        style={{ marginRight: 10, minWidth: 120 }}
      >
        <MenuItem value="daily">Daily</MenuItem>
        <MenuItem value="monthly">Monthly</MenuItem>
        <MenuItem value="yearly">Yearly</MenuItem>
      </Select>

      <TableContainer component={Paper} style={{ marginTop: 20 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Spend</TableCell>
              <TableCell>Top-up</TableCell>
              <TableCell>Promotion</TableCell>
              <TableCell>Earnings</TableCell>
              <TableCell>Details</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reports &&
              reports.length > 0 &&
              reports.map((report, index) => (
                <TableRow key={index}>
                  <TableCell>{report.summary.date}</TableCell>
                  <TableCell>{report.summary.total_spend.toFixed(2)}</TableCell>
                  <TableCell>{report.summary.total_topup.toFixed(2)}</TableCell>
                  <TableCell>
                    {report.summary.total_promotion.toFixed(2)}
                  </TableCell>
                  <TableCell>
                    {report.summary.total_earnings.toFixed(2)}
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      onClick={() =>
                        handleViewTransactions(report.transactions)
                      }
                    >
                      View Details
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Transaction Details Dialog */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Transaction Details</DialogTitle>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Created Date</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Payment Method</TableCell>
                  <TableCell>Description</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedTransactions.map((transaction) => (
                  <React.Fragment key={transaction.ID}>
                    <TableRow
                      onClick={() => handleRowClick(transaction.metadata)}
                    >
                      <TableCell>{transaction.created_at}</TableCell>
                      <TableCell>{transaction.amount}</TableCell>
                      <TableCell>{transaction.type}</TableCell>
                      <TableCell>{transaction.payment_type}</TableCell>
                      <TableCell>{transaction.description}</TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Metadata Details Dialog */}
      <Dialog
        open={openMetadataDialog}
        onClose={handleCloseMetadataDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Metadata Details</DialogTitle>
        <DialogContent>
          {metadata && (
            <div>
              <Typography variant="h6">General Information</Typography>
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableBody>
                    {Object.entries(metadata).map(([key, value]) =>
                      typeof value !== "object" ? (
                        <TableRow key={key}>
                          <TableCell>{key}</TableCell>
                          <TableCell>{String(value)}</TableCell>
                        </TableRow>
                      ) : null
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              {/* Order Details if available */}
              {metadata.order_details && (
                <div>
                  <Typography variant="h6" style={{ marginTop: 16 }}>
                    Order Details
                  </Typography>
                  {metadata.order_details.map((detail, index) => (
                    <TableContainer
                      component={Paper}
                      key={index}
                      style={{ marginBottom: 16 }}
                    >
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Price</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>{detail.name}</TableCell>
                            <TableCell>{detail.description}</TableCell>
                            <TableCell>{detail.price}</TableCell>
                          </TableRow>

                          {/* Modifier Details */}
                          {detail.modifiers &&
                            detail.modifiers.map((modifier, modIndex) => (
                              <React.Fragment key={modIndex}>
                                <TableRow>
                                  <TableCell colSpan={3}>
                                    <Typography variant="subtitle1">
                                      {modifier.name}
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                                {modifier.options &&
                                  modifier.options.map((option, optIndex) => (
                                    <TableRow key={optIndex}>
                                      <TableCell>{option.name}</TableCell>
                                      <TableCell>
                                        {option.currency} {option.price}
                                      </TableCell>
                                      <TableCell>
                                        {option.default ? "Default" : ""}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                              </React.Fragment>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ))}
                </div>
              )}
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMetadataDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default RevenueReport;
