import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  TablePagination,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import InventoryService from "../../services/InventoryService"; // Import service API
import moment from "moment";
import SearchIcon from "@mui/icons-material/Search";
import ExportRecordForm from "./ExportRecordForm";
import { toast } from "react-toastify";

// Styled Table Components
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontSize: 14,
  "&.MuiTableCell-head": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  "&.MuiTableCell-body": {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ExportRecordList = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [exportRecords, setExportRecords] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0); // Material-UI pagination uses zero-based index
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [openModal, setOpenModal] = useState(false);

  // Fetch export records with pagination and search
  const fetchExportRecords = async () => {
    console.log("search", searchTerm);
    setLoading(true);
    try {
      const response = await InventoryService.getAllExportRecords(
        page + 1, // Backend uses 1-based index for pagination
        rowsPerPage,
        searchTerm,
        startDate,
        endDate
      );
      const value = response.data;
      console.log(value.data);
      setExportRecords(value.data);
      setTotalPages(value.totalPages);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchExportRecords();
  }, [page, rowsPerPage]);

  // Handle pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page
  };

  // Handle search
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    // setPage(0); // Reset to first page when searching
  };

  const onError = (value) => {
    toast.error(value);
  };

  const onSuccess = (value) => {
    toast.success(value);
    fetchExportRecords();
  };

  useEffect(() => {
    if (startDate && endDate) {
      console.log("fetch");
      // fetchReport();
      fetchExportRecords();
    }
  }, [startDate, endDate]);

  const handleOpenModal = (group = null) => {
    // setSelectedGroupModifier(group); // Đặt dữ liệu của nhóm được chọn (nếu có) vào state
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    // setSelectedGroupModifier(null); // Xóa dữ liệu nhóm đã chọn sau khi đóng modal
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="50vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Export Records
      </Typography>

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleOpenModal()}
        >
          Add Export Record
        </Button>

        <TextField
          label="Start Date"
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          InputLabelProps={{ shrink: true }}
          style={{ marginRight: 10 }}
        />
        <TextField
          label="End Date"
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          InputLabelProps={{ shrink: true }}
          style={{ marginRight: 10 }}
        />

        <Box display="flex" alignItems="center">
          <TextField
            label="Search"
            variant="outlined"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{ width: "300px", marginRight: "8px" }}
            InputProps={{
              endAdornment: (
                <Button
                  onClick={() => fetchExportRecords()}
                  style={{
                    minWidth: "40px",
                    padding: 0,
                  }}
                >
                  <SearchIcon color="primary" />
                </Button>
              ),
            }}
          />
        </Box>
      </Box>

      <TableContainer component={Paper}>
        <Table aria-label="adjustment table">
          <TableHead>
            <TableRow>
              <StyledTableCell rowSpan={2}>ID</StyledTableCell>
              <StyledTableCell rowSpan={2}>Date</StyledTableCell>
              <StyledTableCell rowSpan={2}>Name</StyledTableCell>
              <StyledTableCell rowSpan={2}> OrderID</StyledTableCell>
              <StyledTableCell rowSpan={2}> Warehouse</StyledTableCell>
              <StyledTableCell
                colSpan={4}
                align="center"
                sx={{ backgroundColor: "#1976d2", color: "white" }}
              >
                <Typography variant="subtitle1" color="inherit">
                  Details
                </Typography>
              </StyledTableCell>
            </TableRow>
            <TableRow>
              {/* <TableCell colSpan={6} /> */}

              <StyledTableCell
                sx={{ backgroundColor: "#1976d2", color: "white" }}
              >
                Material Name
              </StyledTableCell>

              <StyledTableCell
                sx={{ backgroundColor: "#1976d2", color: "white" }}
              >
                Quantity
              </StyledTableCell>
              <StyledTableCell
                sx={{ backgroundColor: "#1976d2", color: "white" }}
              >
                Unit
              </StyledTableCell>
              <StyledTableCell
                sx={{ backgroundColor: "#1976d2", color: "white" }}
              >
                Price
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {exportRecords.map((row, idx) => (
              <React.Fragment key={row.ID}>
                {row.details.map((detail, index) => (
                  <TableRow key={`${row.ID}-${index}`}>
                    {index === 0 && (
                      <>
                        <TableCell rowSpan={row.details.length}>
                          {idx + 1}
                        </TableCell>
                        <TableCell rowSpan={row.details.length}>
                          {moment(row.exported_at).format("DD/MM/YYYY HH:mm")}
                        </TableCell>
                        <TableCell rowSpan={row.details.length}>
                          {row.exported_by}
                        </TableCell>
                        <TableCell rowSpan={row.details.length}>
                          {row.order_id}
                        </TableCell>
                        <TableCell rowSpan={row.details.length}>
                          {row.warehouse.name}
                        </TableCell>
                      </>
                    )}
                    <TableCell>{detail.material.name}</TableCell>
                    <TableCell>{detail.quantity}</TableCell>
                    <TableCell>{detail.material.unit}</TableCell>
                    <TableCell>{detail.price}</TableCell>
                  </TableRow>
                ))}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        count={parseInt(rowsPerPage * totalPages)} // Total number of items
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={modalStyle}>
          <ExportRecordForm
            mode={"create"}
            onClose={handleCloseModal}
            onError={onError}
            onSuccess={onSuccess}
          />
        </Box>
      </Modal>
    </Box>
  );
};

export default ExportRecordList;
