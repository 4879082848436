import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  tableCellClasses,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState, useCallback } from "react";
import config from "../../config";
import { COLORS } from "../../theme/themeColor";
import ImagePicker from "../BaseComponent/ImagePicker";
import { toast } from "react-toastify";

const BaseUrl = config.apiBaseUrl;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: COLORS.BLUE,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "5px 5px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  // height: 20,
}));

const TableComponentMemo = React.memo(
  ({
    title,
    column,
    data,
    orderIndexChanges,
    handleOrderIndexChange,
    onEditRow,
    onDeleteRow,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    applyOrderIndexChange,
    openEditDialog,
    emptyRows,
  }) => {
    const capitalizeFirstLetter = (string) => {
      if (!string) return ""; // Kiểm tra chuỗi rỗng
      return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const columns = column.filter(
      (col) =>
        !["id", "created_at", "updated_at", "deleted_at"].includes(
          col.toLowerCase()
        )
    );

    return (
      <div>
        <TableContainer component={Paper} style={{ maxHeight: "68vh" }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">Idx</StyledTableCell>
                {columns.map((column, index) => (
                  <StyledTableCell key={index} align="center">
                    {capitalizeFirstLetter(column)}
                  </StyledTableCell>
                ))}
                <StyledTableCell align="right">Actions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? data.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : data
              ).map((row, rowIndex) => (
                <StyledTableRow key={rowIndex}>
                  <StyledTableCell align="center">{rowIndex}</StyledTableCell>
                  {columns.map((column, colIndex) =>
                    column === "image" ? (
                      <StyledTableCell key={colIndex} align="center">
                        <img
                          src={BaseUrl + row[column]}
                          alt={BaseUrl + row[column]}
                          style={{ width: 100, borderRadius: "10px" }}
                          onError={(e) => {
                            e.target.onerror = null; // Ngăn chặn lặp vô hạn nếu ảnh mặc định cũng không tải được
                            e.target.src =
                              "https://dummyimage.com/150x150/cccccc/555555.png&text=No+Image"; // Gán ảnh lỗi mặc định
                          }}
                        />
                      </StyledTableCell>
                    ) : column === "sort_index" ? (
                      <StyledTableCell align="center">
                        <TextField
                          label="Index"
                          variant="outlined"
                          value={orderIndexChanges[row["ID"]] ?? row[column]}
                          onChange={(e) =>
                            handleOrderIndexChange(row["ID"], e.target.value)
                          }
                          style={{ width: "60px", textAlign: "center" }}
                        />
                        <div>
                          {orderIndexChanges[row["ID"]] !== undefined &&
                            orderIndexChanges[row["ID"]] !== row[column] && (
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() =>
                                  applyOrderIndexChange(
                                    page * rowsPerPage + rowIndex,
                                    orderIndexChanges[row["ID"]],
                                    data,
                                    row["ID"]
                                  )
                                }
                              >
                                Apply
                              </Button>
                            )}
                        </div>
                        {/* {" \n"}
                        {row[column]} {row["ID"]} */}
                      </StyledTableCell>
                    ) : (
                      <StyledTableCell key={colIndex} align="center">
                        {row[column]}
                      </StyledTableCell>
                    )
                  )}
                  <StyledTableCell align="right">
                    <Button
                      variant="outlined"
                      color="primary"
                      sx={{ marginRight: 1 }}
                      onClick={() =>
                        openEditDialog(page * rowsPerPage + rowIndex)
                      }
                    >
                      Edit
                    </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => onDeleteRow(page * rowsPerPage + rowIndex)}
                    >
                      Delete
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
              {emptyRows > 0 && (
                <StyledTableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={columns.length + 1} />
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[
            10,
            25,
            50,
            { label: "All", value: data.length },
          ]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage === -1 ? data.length : rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    );
  }
);

// export default TableComponentMemo;

const TableComponent = ({
  title,
  column,
  datas,
  onAddRow,
  onEditRow,
  onDeleteRow,
}) => {
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [currentEditRow, setCurrentEditRow] = useState(null);
  const [editValues, setEditValues] = useState({});
  const [newValues, setNewValues] = useState({});
  const [page, setPage] = useState(0);
  // Khởi tạo rowsPerPage là -1 để hiển thị toàn bộ hàng khi khởi tạo
  const [rowsPerPage, setRowsPerPage] = useState(-1);
  // const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState(""); // State cho từ khóa tìm kiếm
  const [data, setdata] = useState([]); // State cho danh sách sản phẩm đã lọc
  const [imageURL, setImageURL] = useState("");
  const [orderIndexChanges, setOrderIndexChanges] = useState({});

  // Hàm để lọc sản phẩm dựa trên từ khóa tìm kiếm
  useEffect(() => {
    if (datas) {
      const results = datas.filter((item) =>
        Object.values(item).some(
          (value) =>
            typeof value === "string" &&
            value.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
      setdata(results);
    }
  }, [searchTerm, datas]);

  const capitalizeFirstLetter = (string) => {
    if (!string) return ""; // Kiểm tra chuỗi rỗng
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const openEditDialog = (index) => {
    setCurrentEditRow(index);
    setEditValues(data[index]);
    setEditDialogOpen(true);

    setImageURL(data[index]);
  };

  const closeEditDialog = () => {
    setEditDialogOpen(false);
    setCurrentEditRow(null);
    setEditValues({});
  };

  const handleEditChange = (column, value) => {
    setEditValues({
      ...editValues,
      [column]: value,
    });
  };

  const saveEdit = () => {
    onEditRow(currentEditRow, editValues);
    closeEditDialog();
  };

  // add row
  const openAddDialog = () => {
    setNewValues({});
    setAddDialogOpen(true);
  };

  const closeAddDialog = () => {
    setAddDialogOpen(false);
    setNewValues({});
  };

  const handleAddChange = (column, value) => {
    setNewValues({
      ...newValues,
      [column]: value,
    });

    // Xóa lỗi nếu có
    if (errors[column]) {
      setErrors((prev) => ({
        ...prev,
        [column]: undefined,
      }));
    }
  };

  const [errors, setErrors] = useState({});

  const saveNewRow = () => {
    // Khởi tạo lỗi
    let validationErrors = {};

    // Kiểm tra nếu trường nào rỗng thì thêm lỗi vào `validationErrors`
    columns.forEach((column) => {
      if (!newValues[column] || newValues[column].trim() === "") {
        validationErrors[column] = `${capitalizeFirstLetter(
          column
        )} is required`;
      }
    });

    // Nếu có lỗi, đặt vào state và dừng submit
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    // Nếu không có lỗi, tiếp tục lưu
    setErrors({});

    onAddRow(newValues);
    closeAddDialog();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    // setRowsPerPage(+event.target.value);
    const value = parseInt(event.target.value, 10);
    setRowsPerPage(value === data.length ? -1 : value); // -1 đại diện cho "All" để tránh phân trang
    setPage(0);
  };

  // image
  const handleImageSelect = (url) => {
    console.log("Selected URL:", url);
    // formValues.imagelink_square = url;
    setImageURL(url);
    editValues["image"] = url;
    newValues["image"] = url;
  };

  const handleOrderIndexChange = useCallback((productId, newIndex) => {
    setOrderIndexChanges((prev) => ({
      ...prev,
      [productId]: newIndex,
    }));
  }, []);

  // Hàm xử lý thay đổi giá trị order_index
  const applyOrderIndexChange = useCallback(async (id, value, datas, index) => {
    let tempValue = value;
    // Cập nhật giá trị order_index trong editValues
    // const updatedEditValues = { ...editValues, order_index: tempValue };
    // Tạo một bản sao của dữ liệu hiện tại với giá trị sort_index được cập nhật
    const updatedData = {
      ...datas[id],
      sort_index: parseInt(tempValue),
    };
    console.log("check", datas, updatedData);

    if (tempValue !== "") {
      onEditRow(id, updatedData);
    }

    // Sau khi lưu trữ, xóa giá trị khỏi `orderIndexChanges`
    setOrderIndexChanges((prev) => {
      const { [index]: removed, ...remaining } = prev;
      console.log("Updated orderIndexChanges:", remaining, index); // Kiểm tra dữ liệu sau khi cập nhật
      return remaining;
    });
  }, []);

  const hiddenFields = ["id", "created_at", "updated_at", "deleted_at"];
  // lọc 'id', 'created_at', 'updated_at', 'deleted_at'
  const columns = column.filter(
    (columns) => !hiddenFields.includes(columns.toLowerCase())
  );
  // console.log(columns)
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

  return (
    <div style={{ width: "100%" }}>
      <Typography variant="h4" gutterBottom>
        {title}
      </Typography>
      {/* Tạo hàng ngang với nút "Add Product" và ô tìm kiếm */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <Button variant="contained" color="primary" onClick={openAddDialog}>
          Add Row
        </Button>
        {/* TextField cho tìm kiếm */}
        <TextField
          label="Search"
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ width: "300px" }} // Đặt độ rộng cho TextField tìm kiếm
        />
      </div>
      {/* Gọi TableComponentMemo và truyền các props cần thiết */}
      <TableComponentMemo
        title={title}
        column={column}
        data={data}
        orderIndexChanges={orderIndexChanges}
        handleOrderIndexChange={handleOrderIndexChange}
        onEditRow={onEditRow}
        onDeleteRow={onDeleteRow}
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        applyOrderIndexChange={applyOrderIndexChange}
        openEditDialog={openEditDialog}
        emptyRows={emptyRows}
      />
      {/* Dialog chỉnh sửa */}
      <Dialog open={editDialogOpen} onClose={closeEditDialog}>
        <DialogTitle>Edit Row</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Edit the fields below and click save to update the row.
          </DialogContentText>
          {columns.map((column, index) =>
            column === "image" ? (
              <div key={index}>
                <Typography variant="h6" component="h3" gutterBottom>
                  Image
                </Typography>
                <ImagePicker
                  onImageSelect={handleImageSelect}
                  defaultImage={BaseUrl + editValues[column]}
                />
              </div>
            ) : (
              <TextField
                key={index}
                margin="dense"
                label={capitalizeFirstLetter(column)}
                type="text"
                fullWidth
                value={editValues[column] || ""}
                onChange={(e) => handleEditChange(column, e.target.value)}
                error={!!errors[column]}
                helperText={errors[column]}
              />
            )
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              // Xóa lỗi nếu có
              setErrors({});
              closeEditDialog();
            }}
            color="secondary"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button onClick={saveEdit} color="primary" variant="outlined">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog thêm hàng mới */}
      <Dialog open={addDialogOpen} onClose={closeAddDialog}>
        <DialogTitle>Add New Row</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Fill in the fields below and click save to add a new row.
          </DialogContentText>
          {columns.map((column, index) =>
            column === "image" ? (
              <div key={index}>
                <Typography variant="h6" component="h3" gutterBottom>
                  Image
                </Typography>
                <ImagePicker
                  onImageSelect={handleImageSelect}
                  defaultImage={BaseUrl + newValues[column]}
                />
              </div>
            ) : (
              <TextField
                key={index}
                margin="dense"
                label={capitalizeFirstLetter(column)}
                type="text"
                fullWidth
                value={newValues[column] || ""}
                onChange={(e) => handleAddChange(column, e.target.value)}
                error={!!errors[column]}
                helperText={errors[column]}
              />
            )
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              // Xóa lỗi nếu có
              setErrors({});
              closeAddDialog();
            }}
            color="secondary"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button onClick={saveNewRow} color="primary" variant="outlined">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TableComponent;
