import axios from "axios";
import config from "../config"; // Import URL từ file config

const API_URL = config.apiBaseUrl + "/advertisements";

// Lấy tất cả quảng cáo
const getAllAdvertisements = () => {
  return axios.get(API_URL);
};

// Lấy quảng cáo theo ID
const getAdvertisementById = (id) => {
  return axios.get(API_URL + id);
};

// Tạo quảng cáo mới
const createAdvertisement = (imageLink, screenConfigId) => {
  return axios.post(API_URL, {
    image_link: imageLink,
    screen_config_id: screenConfigId,
  });
};

// Cập nhật quảng cáo
const updateAdvertisement = (id, imageLink, screenConfigId) => {
  return axios.put(`${API_URL}/${id}`, {
    image_link: imageLink,
    screen_config_id: screenConfigId,
  });
};

// Xóa quảng cáo
const deleteAdvertisement = (id) => {
  return axios.delete(`${API_URL}/${id}`);
};

// tao channel
const createChannel = (channelName, advertisements) => {
  return axios.post(API_URL + "/channel", {
    name_channel: channelName,
    advertisements,
  });
};

const deleteChannel = (id) => {
  return axios.delete(API_URL + "/channel/" + id);
};

// Export các API dưới dạng một object để sử dụng
const AdvertisementApi = {
  getAllAdvertisements,
  getAdvertisementById,
  createAdvertisement,
  updateAdvertisement,
  deleteAdvertisement,
  createChannel,
  deleteChannel,
};

export default AdvertisementApi;
