import axios from "axios";
import config from "../config"; // Import URL từ file config

const API_URL = config.apiBaseUrl + "/campaigns";;

const createCampaign  = (campaignData) => {
  // Logic gửi API để tạo campaign
  return axios.post(`${API_URL}`, campaignData);
}

const getAllCampaign  = () => {
    // Logic gửi API để tạo campaign
    return axios.get(`${API_URL}`);
}
    // Get a single Product by ID
const getCampaign = (id) => {
    return axios.get(`${API_URL}/${id}`);
}
 // Update a Product by ID
 const updateCampaign = (id, campaign) => {
  return axios.put(`${API_URL}/${id}`, campaign);
}
// check unique GiftCode
const checkUniqueGiftCode = (giftCodes) => {
   // Split the input string into an array, trim whitespace, and filter out empty strings
   const codesArray = giftCodes.split(',')
   .map(code => code.trim()) // Trim whitespace from each code
   .filter(code => code); // Filter out any empty strings
   
    return axios.post(`${API_URL}/checkUniqueGiftCode`, codesArray);
}


  // Export các API dưới dạng một object để sử dụng
const Campaign = {
    createCampaign,
    getAllCampaign,
    getCampaign,
    updateCampaign,
    checkUniqueGiftCode
};
  
export default Campaign;