import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  tableCellClasses,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import api from "../../services/ModifierService"; // giả sử bạn có file api.js để xử lý các cuộc gọi API
import { parseFields } from "../../utils";

import { DialogActions, DialogContentText } from "@mui/material";

import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom"; // Sử dụng useNavigate
import { toast } from "react-toastify";
import config from "../../config";
import productService from "../../services/ProductService"; // Sử dụng dịch vụ sản phẩm
import { COLORS } from "../../theme/themeColor";
import AddRecipe from "./Recipelist";
const BaseUrl = config.apiBaseUrl;

const Modifiers = () => {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [datas, setDatas] = useState([]);
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]); // State cho danh sách sản phẩm đã lọc
  const [searchTerm, setSearchTerm] = useState(""); // State cho từ khóa tìm kiếm
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [openAddProductDialog, setOpenAddProductDialog] = useState(false);
  const [newProduct, setNewProduct] = useState({
    name: "",
    description: "",
    price: 0,
  });
  const [openForm, setOpenForm] = useState(false);
  const [selectedProductRecipe, setSelectedProductRecipe] = useState(null);
  const navigate = useNavigate(); // Thay thế useHistory bằng useNavigate

  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [currentEditRow, setCurrentEditRow] = useState(null);
  const [editValues, setEditValues] = useState({});
  const [newValues, setNewValues] = useState({});
  const [errors, setErrors] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const fetchData = async () => {
    try {
      const response = await api.getAllModifiers();
      setData(response.data.dataTable);
      setDatas(response.data.dataTable);
      const hiddenFields = [
        "id",
        "created_at",
        "updated_at",
        "deleted_at",
        "currency",
      ];
      const columns = response.data.columns.filter(
        (column) => !hiddenFields.includes(column.toLowerCase())
      );
      setColumns(columns);
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const addRow = async (newRow) => {
    console.log("add row currency: ", newRow);

    // Sử dụng hàm parsePrices để chuyển đổi dữ liệu
    const parsedData = parseFields(newRow);
    try {
      const response = await api.createModifier(parsedData);
      setData([...data, response.data]);
      fetchData();
    } catch (error) {
      console.error("Error adding row:", error);
    }
  };

  const editRow = async (index, updatedRow) => {
    console.log("Edit row:", index, data[index].ID);
    // Sử dụng hàm parsePrices để chuyển đổi dữ liệu
    const parsedData = parseFields(updatedRow);
    try {
      const response = await api.updateModifier(data[index].ID, parsedData);
      const newData = data.map((row, rowIndex) => {
        if (rowIndex === index) {
          return response.data;
        }
        return row;
      });
      setData(newData);
      fetchData();
    } catch (error) {
      console.error("Error editing row:", error);
    }
  };

  const deleteRow = async (index) => {
    try {
      await api.deleteModifier(data[index].ID);
      const newData = data.filter((_, rowIndex) => rowIndex !== index);
      setData(newData);
    } catch (error) {
      console.error("Error deleting row:", error);
    }
  };

  //////////////////
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: COLORS.BLUE,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: "0px 5px",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
    // height: 20,
  }));

  // useEffect(() => {
  //   const fetchProducts = async () => {
  //     const response = await productService.getAllProducts();
  //     console.log("Products", response.data);
  //     setProducts(response.data);
  //     if (response.data) {
  //         setFilteredProducts(response.data); // Ban đầu hiển thị tất cả sản phẩm
  //         }
  //     };
  //   fetchProducts();
  // }, []);

  // Hàm để lọc sản phẩm dựa trên từ khóa tìm kiếm
  useEffect(() => {
    if (searchTerm) {
      const results = data.filter((product) =>
        Object.values(product).some(
          (value) =>
            typeof value === "string" &&
            value.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
      setData(results);
    } else {
      setData(datas);
    }
  }, [searchTerm, data]);

  const handleAddProduct = async () => {
    navigate("/add-product"); // Điều hướng đến màn hình thêm sản phẩm
  };

  const handleEditProduct = async (id) => {
    navigate(`/add-product/${id}`); // Điều hướng đến màn hình thêm sản phẩm
  };

  const handleDeleteProduct = async (ID) => {
    try {
      await productService.deleteProduct(ID);
      setProducts(products.filter((product) => product.ID !== ID));
      setFilteredProducts(
        filteredProducts.filter((product) => product.ID !== ID)
      ); // Cập nhật danh sách đã lọc
      toast.success("Product deleted successfully.");
    } catch (error) {
      console.error("Error deleting product:", error);
      toast.error("Failed to delete product.");
    }
  };

  const handleCloseForm = () => {
    fetchData();
    setOpenForm(false);
  };

  const handleAdd = (product) => {
    console.log("testdata", product);
    setSelectedProductRecipe(product);
    setOpenForm(true);
  };
  ///////////////

  const capitalizeFirstLetter = (string) => {
    if (!string) return ""; // Kiểm tra chuỗi rỗng
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const openEditDialog = (index) => {
    setCurrentEditRow(index);
    setEditValues(data[index]);
    setEditDialogOpen(true);
  };

  const closeEditDialog = () => {
    setEditDialogOpen(false);
    // setCurrentEditRow(null);
    // setEditValues({});
  };

  const handleEditChange = (column, value) => {
    setEditValues({
      ...editValues,
      [column]: value,
    });
  };

  const saveEdit = () => {
    // onEditRow(currentEditRow, editValues);
    console.log("saveEdit", editValues, currentEditRow);
    editRow(currentEditRow, editValues);
    closeEditDialog();
  };

  // add row
  const openAddDialog = () => {
    setNewValues({});
    setAddDialogOpen(true);
  };

  const closeAddDialog = () => {
    setAddDialogOpen(false);
    setNewValues({});
  };
  const handleAddChange = (column, value) => {
    setNewValues({
      ...newValues,
      [column]: value,
    });

    // Xóa lỗi nếu có
    if (errors[column]) {
      setErrors((prev) => ({
        ...prev,
        [column]: undefined,
      }));
    }
  };
  const saveNewRow = () => {
    // Khởi tạo lỗi
    let validationErrors = {};

    // Kiểm tra nếu trường nào rỗng thì thêm lỗi vào `validationErrors`
    // columns.forEach((column) => {
    //   if (!newValues[column] || newValues[column].trim() === "") {
    //     validationErrors[column] = `${capitalizeFirstLetter(
    //       column
    //     )} is required`;
    //   }
    // });

    // Nếu có lỗi, đặt vào state và dừng submit
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    // Nếu không có lỗi, tiếp tục lưu
    setErrors({});

    addRow(newValues);
    closeAddDialog();
  };

  return (
    <div style={{ width: "100%" }}>
      <Typography variant="h4" gutterBottom>
        Modifiers Management
      </Typography>

      {/* Tạo hàng ngang với nút "Add Product" và ô tìm kiếm */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => openAddDialog()}
        >
          Add Modifiers
        </Button>

        {/* TextField cho tìm kiếm */}
        <TextField
          label="Search"
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ width: "300px" }} // Đặt độ rộng cho TextField tìm kiếm
        />
      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>ID</StyledTableCell>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Description</StyledTableCell>
              <StyledTableCell>price</StyledTableCell>
              <StyledTableCell>Recipe</StyledTableCell>
              <StyledTableCell>Sold Out</StyledTableCell>
              <StyledTableCell align="right">Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((product, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell>{index}</StyledTableCell>
                <StyledTableCell>{product.name}</StyledTableCell>
                <StyledTableCell>{product.description}</StyledTableCell>
                <StyledTableCell>${product.price}</StyledTableCell>
                <StyledTableCell>
                  <Button
                    variant="outlined"
                    color={product.recipe ? "info" : "error"}
                    onClick={() => handleAdd(product)}
                    style={{ marginRight: "10px" }}
                  >
                    Recipe
                  </Button>
                </StyledTableCell>
                <StyledTableCell>
                  {product.is_sold_out && (
                    <Typography sx={{ color: "red", fontWeight: "bold" }}>
                      Sold Out
                    </Typography>
                  )}
                </StyledTableCell>
                <StyledTableCell align="right">
                  <Button
                    variant="outlined"
                    onClick={() => openEditDialog(index)}
                    style={{ marginRight: "10px" }}
                  >
                    Edit
                  </Button>
                  {/* <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => deleteRow()}
                  >
                    Delete
                  </Button> */}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* Dialog Form */}
      <Dialog open={openForm} onClose={handleCloseForm} fullWidth maxWidth="md">
        <DialogTitle>
          {selectedProductRecipe ? "Edit Ingredient" : "Add New Ingredient"}
        </DialogTitle>
        <DialogContent>
          <AddRecipe
            existing={selectedProductRecipe}
            recipeType={"modifier"}
            onClose={handleCloseForm}
          />
        </DialogContent>
      </Dialog>

      {/* Dialog chỉnh sửa */}
      <Dialog open={editDialogOpen} onClose={closeEditDialog}>
        <DialogTitle>Edit Row</DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            value={editValues.name || ""}
            onChange={(e) => handleEditChange("name", e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Description"
            value={editValues.description || ""}
            onChange={(e) => handleEditChange("description", e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Price"
            type="number"
            value={editValues.price || ""}
            onChange={(e) => handleEditChange("price", parseFloat(e.target.value))}
            fullWidth
            margin="normal"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={editValues.is_sold_out || false}
                onChange={(e) => handleEditChange("is_sold_out", e.target.checked)}
              />
            }
            label="Sold Out"
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              // Xóa lỗi nếu có
              setErrors({});
              closeEditDialog();
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button onClick={saveEdit} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog thêm hàng mới */}
      <Dialog open={addDialogOpen} onClose={closeAddDialog}>
        <DialogTitle>Add New Row</DialogTitle>
        {/* <DialogContent>
          <DialogContentText>
            Fill in the fields below and click save to add a new row.
          </DialogContentText>
          {columns.map((column, index) => (
            <TextField
              key={index}
              margin="dense"
              label={capitalizeFirstLetter(column)}
              type="text"
              fullWidth
              value={newValues[column] || ""}
              onChange={(e) => handleAddChange(column, e.target.value)}
              error={!!errors[column]}
              helperText={errors[column]}
            />
          ))}
        </DialogContent> */}
        <DialogContent>
          <TextField
            label="Name"
            value={newValues['name'] || ""}
            onChange={(e) => handleAddChange("name", e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Description"
            value={newValues['description'] || ""}
            onChange={(e) => handleAddChange("description", e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Price"
            type="number"
            value={newValues['price'] || ""}
            onChange={(e) => handleAddChange("price", parseFloat(e.target.value))}
            fullWidth
            margin="normal"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={newValues['is_sold_out'] || false}
                onChange={(e) => handleAddChange("is_sold_out", e.target.checked)}
              />
            }
            label="Sold Out"
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              // Xóa lỗi nếu có
              setErrors({});
              closeAddDialog();
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button onClick={saveNewRow} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Modifiers;
