import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import config from "../../config";
const BaseUrl = config.apiBaseUrl;

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function ImagePicker({ onImageSelect, defaultImage }) {
  const defaultImages = "https://via.placeholder.com/150"; // Default image when none is selected

  const [selectedImage, setSelectedImage] = useState(defaultImages);
  const [open, setOpen] = useState(false);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [imageList, setFilteredImageList] = useState([]);
  const [searchTerm, setSearchTerm] = useState(""); // State cho từ khóa tìm kiếm

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Lấy danh sách ảnh từ API
  useEffect(() => {
    fetch(BaseUrl + "/images")
      .then((response) => response.json())
      .then((data) => {
        setImages(data.data);
        setFilteredImageList(data.data); // Lúc đầu hiển thị tất cả các ảnh
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching images:", error);
        setLoading(false);
      });
  }, []);

  // Cập nhật danh sách hình ảnh khi searchTerm thay đổi
  useEffect(() => {
    if (searchTerm === "") {
      setFilteredImageList(images); // Nếu không có từ khóa tìm kiếm, hiển thị toàn bộ danh sách
    } else {
      const results = images.filter(
        (image) =>
          image.FileName.toLowerCase().includes(searchTerm.toLowerCase()) // So sánh từ khóa với tên file
      );
      setFilteredImageList(results); // Cập nhật danh sách đã lọc
    }
  }, [searchTerm, images]);

  useEffect(() => {
    if (defaultImage) {
      setSelectedImage(defaultImage);
    }
  }, [defaultImage]);

  const handleImageSelect = (image) => {
    setSelectedImage(image.URL);
    handleClose();
    onImageSelect(image.URL); // Truyền URL cho component cha
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <div>
      <img
        src={selectedImage}
        alt="Selected"
        style={{ width: 150, cursor: "pointer", borderRadius: "10px" }}
        onClick={handleOpen}
        onError={(e) => {
          e.target.onerror = null; // Ngăn chặn lặp vô hạn nếu ảnh mặc định cũng không tải được
          e.target.src =
            "https://dummyimage.com/150x150/cccccc/555555.png&text=No+Image"; // Gán ảnh lỗi mặc định
        }}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={modalStyle}>
          {/* Tạo hàng ngang với TextField cho tìm kiếm */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "20px",
            }}
          >
            <Typography id="modal-title" variant="h6" component="h2">
              Search Images
            </Typography>
            {/* TextField cho tìm kiếm */}
            <TextField
              label="Search"
              variant="outlined"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{ width: "300px" }} // Đặt độ rộng cho TextField tìm kiếm
            />
          </div>
          <TableContainer component={Paper} sx={{ maxHeight: 400 }}>
            <Table sx={{ minWidth: 250 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Image</TableCell>
                  <TableCell align="left">Name</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {imageList &&
                  imageList.map((image) => (
                    <TableRow
                      key={image.ID}
                      onClick={() => handleImageSelect(image)}
                      style={{ cursor: "pointer" }}
                    >
                      <TableCell component="th" scope="row">
                        <img
                          src={BaseUrl + image.URL}
                          alt={image.FileName}
                          style={{ width: 50, borderRadius: "10px" }}
                          errorImage="https://dummyimage.com/150x150/cccccc/555555.png&text=No+Image"
                        />
                      </TableCell>
                      <TableCell align="left">{image.FileName}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Button onClick={handleClose} style={{ marginTop: "16px" }}>
            Close
          </Button>
        </Box>
      </Modal>
    </div>
  );
}

export default ImagePicker;
