import {
  Box,
  Button,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import React, { useState } from "react";
import {
  createWarehouse,
  updateWarehouse,
} from "../../services/InventoryService";

const WarehouseForm = ({ existingWarehouse, onClose, onSuccess, onError }) => {
  const [name, setName] = useState(
    existingWarehouse ? existingWarehouse.name : ""
  );
  const [location, setLocation] = useState(
    existingWarehouse ? existingWarehouse.location : ""
  );
  const [type, setType] = useState(
    existingWarehouse ? existingWarehouse.type : ""
  );
  const [errors, setErrors] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation
    let tempErrors = {};
    if (!name) tempErrors.name = "Warehouse name is required";
    if (!location) tempErrors.location = "Warehouse location is required";

    setErrors(tempErrors);
    if (Object.keys(tempErrors).length > 0) return;

    const data = {
      name,
      location,
      type,
    };

    try {
      if (existingWarehouse) {
        await updateWarehouse(existingWarehouse.ID, data);
      } else {
        await createWarehouse(data);
      }
      onSuccess();
      onClose();
    } catch (err) {
      onError(err.response?.data?.error || "An error occurred");
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate>
      <FormControl fullWidth margin="normal">
        <TextField
          label="Warehouse Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          error={!!errors.name}
          helperText={errors.name}
        />
      </FormControl>
      <FormControl fullWidth margin="normal">
        <TextField
          label="Location"
          value={location}
          onChange={(e) => setLocation(e.target.value)}
          error={!!errors.location}
          helperText={errors.location}
        />
      </FormControl>
      <FormControl fullWidth margin="normal">
        <InputLabel id="location-select-label">Type</InputLabel>
        <Select
          labelId="location-select-label"
          label="Type"
          value={type}
          onChange={(e) => setType(e.target.value)}
          error={!!errors.location}
        >
          <MenuItem value="sale">Sale</MenuItem>
          <MenuItem value="kitchen">Kitchen</MenuItem>
        </Select>
        {errors.type && <FormHelperText error>{errors.type}</FormHelperText>}
      </FormControl>
      <Box mt={2} display="flex" justifyContent="flex-end">
        <Button
          onClick={onClose}
          color="secondary"
          variant="contained"
          style={{ marginRight: "8px" }}
        >
          Cancel
        </Button>
        <Button type="submit" color="primary" variant="contained">
          {existingWarehouse ? "Update" : "Add New"}
        </Button>
      </Box>
    </Box>
  );
};

export default WarehouseForm;
