import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  TextField,
  Grid,
  Paper,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { toast } from "react-toastify";
import {
  fetchConfigs,
  createConfig,
  updateConfig,
  fetchRewards,
  createRewards,
  updateRewards,
  deleteRewards,
} from "../../services/appConfigService";

const RewardsPage = () => {
  const [rewards, setRewards] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentReward, setCurrentReward] = useState({
    question: "",
    answer: "",
  });

  const handlefetchRewards = async () => {
    try {
      const data = await fetchRewards();

      setRewards(data.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handlefetchRewards();
  }, []);

  const handleOpen = (reward = { question: "", answer: "" }) => {
    setCurrentReward(reward);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentReward({ question: "", answer: "" });
  };

  const handleSave = async () => {
    try {
      if (currentReward.ID) {
        await updateRewards(currentReward.ID, currentReward);
      } else {
        await createRewards(currentReward);
      }
      handlefetchRewards();
      handleClose();
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteRewards(id);
      handlefetchRewards();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Container>
      <h1>Rewards Program Management</h1>
      <Button variant="contained" color="primary" onClick={() => handleOpen()}>
        Add Reward
      </Button>
      <TableContainer component={Paper} style={{ marginTop: "20px" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Question</TableCell>
              <TableCell>Answer</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rewards.map((reward) => (
              <TableRow key={reward.ID}>
                <TableCell>{reward.question}</TableCell>
                <TableCell>{reward.answer}</TableCell>
                <TableCell style={{ width: "200px" }}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleOpen(reward)}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => handleDelete(reward.ID)}
                    style={{ marginLeft: "10px" }}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {currentReward.ID ? "Edit Reward" : "Add Reward"}
        </DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Question"
            fullWidth
            value={currentReward.question}
            onChange={(e) =>
              setCurrentReward({ ...currentReward, question: e.target.value })
            }
          />
          <TextField
            margin="dense"
            label="Answer"
            fullWidth
            value={currentReward.answer}
            onChange={(e) =>
              setCurrentReward({ ...currentReward, answer: e.target.value })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

const AppConfig = () => {
  const [configs, setConfigs] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [newConfig, setNewConfig] = useState({
    name: "",
    description: "",
    content: "",
  });

  // Fetch existing configs
  const fetchConfig = async () => {
    try {
      const response = await fetchConfigs();
      console.log(response.data);
      setConfigs(response.data.contents);
    } catch (error) {
      toast.error("Failed to fetch configurations");
    }
  };

  // Save existing config
  const saveConfig = async (id, content) => {
    console.log("save", id, content);
    try {
      await updateConfig(id, content);
      toast.success("Configuration saved successfully");
      fetchConfigs(); // Refresh after saving
    } catch (error) {
      toast.error("Failed to save configuration");
    }
  };

  // Create a new config
  const createConfigs = async () => {
    if (!newConfig.name || !newConfig.description || !newConfig.content) {
      toast.error("All fields are required!");
      return;
    }

    try {
      await createConfig(newConfig);
      toast.success("Configuration created successfully");
      setOpenDialog(false);
      setNewConfig({ name: "", description: "", content: "" });
      fetchConfigs(); // Refresh the list
    } catch (error) {
      toast.error("Failed to create configuration");
    }
  };

  useEffect(() => {
    fetchConfig();
  }, []);

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        App Config
      </Typography>
      <Button
        variant="contained"
        sx={{ marginBottom: 2 }}
        onClick={() => setOpenDialog(true)}
      >
        Create New Config
      </Button>
      <Grid container spacing={3}>
        {configs.length > 0 &&
          configs.map((config, key) => (
            <Grid item xs={12} key={config.ID}>
              <Paper sx={{ padding: 3 }}>
                <Typography variant="h6" gutterBottom>
                  {config.name}
                </Typography>
                <Typography variant="body2" gutterBottom color="textSecondary">
                  {config.description}
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  label="Content"
                  value={config.content}
                  onChange={(e) => {
                    const updatedConfigs = configs.map((item) =>
                      item.ID === config.ID
                        ? { ...item, content: e.target.value }
                        : item
                    );
                    setConfigs(updatedConfigs);
                  }}
                  InputProps={{
                    style: { minHeight: "150px" },
                  }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ marginTop: 2 }}
                  onClick={() => saveConfig(config.ID, config)}
                >
                  Save
                </Button>
              </Paper>
            </Grid>
          ))}

        <RewardsPage />
      </Grid>

      {/* Dialog for Creating New Config */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Create New Configuration</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Name"
            value={newConfig.name}
            onChange={(e) =>
              setNewConfig({ ...newConfig, name: e.target.value })
            }
            margin="dense"
          />
          <TextField
            fullWidth
            label="Description"
            value={newConfig.description}
            onChange={(e) =>
              setNewConfig({ ...newConfig, description: e.target.value })
            }
            margin="dense"
          />
          <TextField
            fullWidth
            multiline
            label="Content"
            value={newConfig.content}
            onChange={(e) =>
              setNewConfig({ ...newConfig, content: e.target.value })
            }
            margin="dense"
            InputProps={{
              style: { minHeight: "100px" },
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={createConfigs} variant="contained" color="primary">
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AppConfig;
