import {
    Box,
    Button,
    Modal,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography
} from "@mui/material";

import {
    TableCell,
    tableCellClasses
} from "@mui/material";
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import config from '../../config';
import TopupService from "../../services/TopupService";
import { COLORS } from '../../theme/themeColor';
const BaseUrl = config.apiBaseUrl

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  

const ComboList = () => {
    const [combos, setCombos] = useState([]);
    const [filteredTopup, setFilteredTopup] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedTopup, setSelectedTopup] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [topupDetails, setTopupDetails] = useState({ name: "", price: 0, description: "", });
    const [errors, setErrors] = useState({});

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: COLORS.BLUE,
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
          padding: '0px 5px',
        },
      }));
    
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
    // height: 20,
    
    }));
    
    useEffect(() => {
        const fetchTopup = async () => {
            const response = await TopupService.GetAllTopups();
            // setCombos(response.data);
            setFilteredTopup(response.data);
        };

        fetchTopup();
    }, []);



    useEffect(() => {
        if (searchTerm) {
            const results = combos.filter((combo) =>
                combo.name.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setFilteredTopup(results);
        } else {
            console.log("image", combos)
            setFilteredTopup(combos);
        }
    }, [searchTerm, combos]);



    const handleOpenModal = (topup = null) => {
        console.log("Open modal", topup);
        if (topup) {
            // Fill modal with combo details for editing
            setTopupDetails({
                name: topup.name,
                price: topup.price,
                description: topup.description
                
            });
            setSelectedTopup(topup);
            
        } else {
            // Reset modal for creating new combo
            setTopupDetails({ name: "", price: 0});
          
            setSelectedTopup(null);
        }
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setErrors({});
    };

    const handleSave = async () => {
        if (validateFields()) {
            try {
                const topupData = {
                    name: topupDetails.name,
                    price: topupDetails.price,
                    // image_link_square: imageURL,
                    // image_link_portrait: imageURL,
                    type: "Topup",
                
                };

                console.log('topupData', topupData);

                if (selectedTopup) {
                    await TopupService.UpdateTopup(selectedTopup.ID, topupData);
                    toast.success("Combo updated successfully.");
                } else {
                    await TopupService.CreateTopup(topupData);
                    toast.success("Combo created successfully.");
                }

                setOpenModal(false);
                const response = await TopupService.GetAllTopups();
                // setCombos(response.data);
                setFilteredTopup(response.data);
                setErrors({});
            } catch (error) {
                console.error("Failed to save combo:", error);
                toast.error("Failed to save combo.");
            }
        }
    };

    const handleDelete = async (id) => {
        try {
            await TopupService.DeleteTopup(id);
            // setCombos(combos.filter((combo) => combo.ID !== id));
            setFilteredTopup(filteredTopup.filter((combo) => combo.ID !== id));
            toast.success("Combo deleted successfully.");
        } catch (error) {
            console.error("Failed to delete combo:", error);
            toast.error("Failed to delete combo.");
        }
    };

    const validateFields = () => {
        const newErrors = {};
        if (!topupDetails.name) {
            newErrors.name = "Top Up Name is required";
        }
        if (!topupDetails.price || isNaN(topupDetails.price) || topupDetails.price <= 0) {
            newErrors.price = "Price is required and must be a number greater than 0";
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };


    return (
        <div style={{ width: '100%' }}>
            <Typography variant="h4" gutterBottom>
                Topup List
            </Typography>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
            <Button
                variant="contained"
                color="primary"
                onClick={() => handleOpenModal()}
            >
                Add Topup
            </Button>

            <TextField
                label="Search"
                variant="outlined"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{ width: '300px' }} // Đặt độ rộng cho TextField tìm kiếm
            />
            </div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>ID</StyledTableCell>
                            {/* <StyledTableCell>Image</StyledTableCell> */}
                            <StyledTableCell>Name</StyledTableCell>
                            <StyledTableCell>Description</StyledTableCell>
                            <StyledTableCell>Price</StyledTableCell>
                            <StyledTableCell align="right">Actions</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredTopup.map((topup) => (
                            <StyledTableRow key={topup.ID}>
                                <StyledTableCell>{topup.ID}</StyledTableCell>
                                {/* <StyledTableCell> <img src={BaseUrl + combo.image_link_square} alt={BaseUrl + combo.image_link_square} style={{ width: 100 }} /></StyledTableCell> */}
                                <StyledTableCell>{topup.name}</StyledTableCell>
                                <StyledTableCell>{topup.description}</StyledTableCell>
                                <StyledTableCell>{topup.price}</StyledTableCell>
                                <StyledTableCell align="right">
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={() => handleOpenModal(topup)}
                                        sx={{ marginRight: 1 }}
                                    >
                                        Edit
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        onClick={() => handleDelete(topup.ID)}
                                    >
                                        Delete
                                    </Button>
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={modalStyle}>
                    <Typography id="modal-title" variant="h6" component="h2">
                        {selectedTopup ? "Edit Top Up" : "Add Top Up"}
                    </Typography>

                   
                    <TextField
                        label="Top Up Name"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={topupDetails.name}
                        onChange={(e) => setTopupDetails({ ...topupDetails, name: e.target.value })}
                        error={!!errors.name}
                        helperText={errors.name}
                    />

                    <TextField
                        label="Top Up description"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={topupDetails.description}
                        onChange={(e) => setTopupDetails({ ...topupDetails, description: e.target.value })}
                        error={!!errors.description}
                        helperText={errors.description}
                    />

                    <TextField
                        label="Price"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        type="number"
                        value={topupDetails.price}
                        onChange={(e) => setTopupDetails({ ...topupDetails, price: parseFloat(e.target.value) })}
                        error={!!errors.price}
                        helperText={errors.price}
                    />

                  

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSave}
                        sx={{ marginTop: 2 }}
                    >
                        {selectedTopup ? "Save Changes" : "Create Top Up"}
                    </Button>
                </Box>
            </Modal>
        </div>
    );
};

export default ComboList;
