// src/components/CustomerSearchAndEdit.js
import { Box, Button, Paper, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
// import Grid from '@mui/material/Grid2';
import CustomerService from "../../services/CustomerService";
import CustomerForm from "./CustomerForm";
import CustomerTable from "./CustomerTable";
import GroupCustomerManager from "./CustomerGroup";

const Customer = [
  {
    id: 1,
    mobile_number: "123456",
    role: "user",
    username: "Jame",
    orders: null,
    ambassadors: [
      {
        ID: 184,
        CreatedAt: "2024-09-09T17:58:19.703835+07:00",
        UpdatedAt: "2024-09-09T17:58:19.703835+07:00",
        DeletedAt: null,
        customer_id: 282,
        ambassador_id: 1,
        commission_policy_id: 0,
      },
    ],
  },
  {
    id: 267,
    mobile_number: "22442244",
    role: "user",
    username: "Ambassador_bsjE5",
    orders: null,
    ambassadors: [
      {
        ID: 178,
        CreatedAt: "2024-09-09T16:59:32.9223055+07:00",
        UpdatedAt: "2024-09-09T16:59:32.9223055+07:00",
        DeletedAt: null,
        customer_id: 1,
        ambassador_id: 267,
        commission_policy_id: 0,
      },
      {
        ID: 179,
        CreatedAt: "2024-09-09T16:59:32.9406841+07:00",
        UpdatedAt: "2024-09-09T16:59:32.9406841+07:00",
        DeletedAt: null,
        customer_id: 271,
        ambassador_id: 267,
        commission_policy_id: 0,
      },
    ],
  },
  {
    id: 268,
    mobile_number: "4168574780",
    role: "user",
    username: "Ambassador_XfmXQ",
    orders: null,
    ambassadors: [],
  },
  {
    id: 269,
    mobile_number: "4389141324",
    role: "user",
    username: "Ambassador_GP5od",
    orders: null,
    ambassadors: [
      {
        ID: 181,
        CreatedAt: "2024-09-09T16:59:32.9779969+07:00",
        UpdatedAt: "2024-09-09T16:59:32.9779969+07:00",
        DeletedAt: null,
        customer_id: 279,
        ambassador_id: 269,
        commission_policy_id: 0,
      },
      {
        ID: 182,
        CreatedAt: "2024-09-09T16:59:32.9959047+07:00",
        UpdatedAt: "2024-09-09T16:59:32.9959047+07:00",
        DeletedAt: null,
        customer_id: 280,
        ambassador_id: 269,
        commission_policy_id: 0,
      },
    ],
  },
];
const CustomerSearchAndEdit = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [selectedCustomerForGroup, setSelectedCustomerForGroup] =
    useState(null);
  const [selectedGroupCustomer, setSelectedGroupCustomer] = useState(null);
  const [listCustomerOfGroup, setlistCustomerOfGroup] = useState([]);

  const [reloadFlag, setReloadFlag] = useState(false); // State to trigger reload

  // Callback to handle customer updates
  const handleCustomerUpdated = () => {
    setReloadFlag(!reloadFlag); // Toggle to trigger useEffect in CustomerTable
  };

  useEffect(() => {
    const fetchUsers = async () => {
      if (searchQuery.trim().length > 0) {
        const response = await CustomerService.searchCustomers(searchQuery);
        console.log("customers list", response.data);
        setCustomers(response.data);
      }
    };
    fetchUsers();
  }, [reloadFlag]);

  const handleSearch = async () => {
    try {
      if (searchQuery.trim().length > 0) {
        const response = await CustomerService.searchCustomers(searchQuery);
        console.log("customers list", response.data);
        setCustomers(response.data);
      } else {
        setCustomers([]);
      }
    } catch (error) {
      console.error("Error fetching customers:", error);
    }
  };

  const addGroup = (value) => {
    setSelectedCustomerForGroup(value);
  };
  const delSuccess = () => {
    setSelectedCustomerForGroup(null);
  };

  return (
    <div style={{ width: "100%" }}>
      <Typography variant="h4" gutterBottom>
        Customer Management
      </Typography>

      <Paper sx={{ p: 2, mb: 4 }}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <TextField
            label="Search Mobile Number"
            placeholder="Search customers..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            variant="outlined"
            fullWidth
            sx={{ mr: 2 }} // margin right for spacing
          />
          <Button variant="contained" onClick={handleSearch}>
            Search
          </Button>
        </Box>
      </Paper>

      <Box sx={{ mb: 4 }}>
        <CustomerTable
          searchQuery={searchQuery}
          customer={customers}
          onSelectCustomer={setSelectedCustomer}
          addGroup={addGroup}
          selectedGroupCustomer={selectedGroupCustomer}
          listCustomerOfGroup={listCustomerOfGroup}
          reloadFlag={reloadFlag} // Pass reloadFlag to trigger refetch
        />
      </Box>

      {selectedCustomer && (
        <CustomerForm
          customer={selectedCustomer}
          onClose={() => setSelectedCustomer(null)}
          onCustomerUpdated={handleCustomerUpdated} // Pass callback to handle updates
        />
      )}
      {/* Group Customer */}
      <Typography variant="h4" gutterBottom>
        Groups Customer
      </Typography>
      <GroupCustomerManager
        customer={selectedCustomerForGroup}
        SetlistCustomerOfGroup={setlistCustomerOfGroup}
        setSelectedGroupCustomer={setSelectedGroupCustomer}
        delSuccess={delSuccess}
      />
    </div>
  );
};

export default CustomerSearchAndEdit;
