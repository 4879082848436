// src/components/RecipeTable.js

import { Add, Delete, Edit } from "@mui/icons-material";
import {
  Alert,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  deleteRecipedetail,
  fetchRecipeById,
} from "../../services/InventoryService";
import RecipeForm from "./RecipeForm";

const RecipeTable = ({ existing, recipeType, onClose }) => {
  const [recipes, setRecipes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openForm, setOpenForm] = useState(false);
  const [selectedRecipe, setSelectedRecipe] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  // Lấy danh sách material_id đã có trong recipe
  const existingMaterialIds = recipes.details
    ? recipes.details.map((detail) => detail.material_id)
    : [];

  const getRecipes = async () => {
    // console.log('recipes: ', existing)
    try {
      const params = {
        id: existing.ID,
        type: recipeType,
      };
      const response = await fetchRecipeById(params);
      console.log("recipe", response.data.details);
      setRecipes(response.data);
    } catch (err) {
      console.log("Failed to fetch recipes:", err);
      // setError('Failed to fetch recipes from server.');
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getRecipes();
  }, [existing]);

  const removeRecipeById = (recipeId) => {
    setRecipes((prevRecipes) => ({
      ...prevRecipes,
      details: prevRecipes.details.filter((recipe) => recipe.ID !== recipeId),
    }));
  };

  const handleDelete = async (item) => {
    console.log(`deleting`, item, recipes.details);
    const data = {
      recipe_id: item.recipe_id,
      material_id: item.material_id,
    };
    // if (window.confirm('Are you sure you want to delete this recipe?')) {
    try {
      await deleteRecipedetail(data);
      // setRecipes(recipes.details.filter((recipe) => recipe.ID !== item.ID));
      removeRecipeById(item.ID);
      setSnackbar({
        open: true,
        message: "Deleted successfully",
        severity: "success",
      });
    } catch (err) {
      if (err.response)
        console.log("Failed to delete recipe:", err.response.data.error);
      console.log("Failed to delete recipe:", err.message);
      setSnackbar({
        open: true,
        message: `Error: ${err.message}`,
        severity: "error",
      });
    }
    // }
  };

  const handleEdit = (recipe) => {
    console.log("ddddd", recipes);
    setSelectedRecipe(recipe);
    setOpenForm(true);
  };

  const handleAdd = () => {
    setSelectedRecipe(null);
    setOpenForm(true);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
  };

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="50vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="50vh"
      >
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  return (
    <Box p={3}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h5">Recipes</Typography>
        <IconButton color="primary" onClick={handleAdd}>
          <Add />
        </IconButton>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Material Name</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Unit</TableCell>
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {recipes.details?.length > 0 &&
              recipes.details.map((recipe) => (
                <TableRow key={recipe.ID}>
                  <TableCell>{recipe.material.name}</TableCell>
                  <TableCell>{recipe.quantity}</TableCell>
                  <TableCell>{recipe.material.unit}</TableCell>
                  <TableCell align="right">
                    {/* <IconButton
                      color="primary"
                      onClick={() => handleEdit(recipe)}
                    >
                      <Edit />
                    </IconButton> */}
                    <IconButton
                      color="secondary"
                      onClick={() => handleDelete(recipe)}
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            {recipes.details ? null : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  No recipes available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Dialog Form */}
      <Dialog open={openForm} onClose={handleCloseForm} fullWidth maxWidth="sm">
        <DialogTitle>
          {selectedRecipe ? "Edit Recipe" : "Add Recipe"}
        </DialogTitle>
        <DialogContent>
          <RecipeForm
            productId={existing.ID}
            recipeId={recipes.ID}
            recipeType={recipeType}
            existingRecipe={selectedRecipe}
            existingMaterialIds={existingMaterialIds}
            onClose={handleCloseForm}
            onSuccess={() => {
              getRecipes();
              setSnackbar({
                open: true,
                message: selectedRecipe
                  ? "Update successful"
                  : "Added successfully",
                severity: "success",
              });
            }}
            onError={(msg) => {
              setSnackbar({ open: true, message: msg, severity: "error" });
            }}
          />
        </DialogContent>
      </Dialog>

      {/* Snackbar Notification */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default RecipeTable;
