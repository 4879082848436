import {
  Box,
  Button,
  Checkbox,
  Grid,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import config from "../../config";
import ComboService from "../../services/ComboService";
import MenuService from "../../services/MenuService";
import ProductService from "../../services/ProductService";
import ImagePicker from "../BaseComponent/ImagePicker";

const BaseUrl = config.apiBaseUrl;

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const MenuList = () => {
  const [menus, setMenus] = useState([]);
  const [filteredMenus, setFilteredMenus] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [imageURL, setImageURL] = useState("");
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [openMenuModal, setOpenMenuModal] = useState(false);
  const [openProductModal, setOpenProductModal] = useState(false);
  const [openComboModal, setOpenComboModal] = useState(false);

  const [openProductAVIKModal, setOpenProductAVIKModal] = useState(false);
  const [menuDetails, setMenuDetails] = useState({
    name: "",
    products: [],
    combos: [],
  });
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [combos, setCombos] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedCombos, setSelectedCombos] = useState([]);
  const [selectedAvikProducts, setSelectedAvikProducts] = useState([]);
  const [selectedCombosHome, setSelectedCombosHome] = useState([]);
  const [qty, setQty] = useState({});
  const [productPrices, setProductPrices] = useState({});
  const [allProductsSelected, setAllProductsSelected] = useState(false);
  const [allCombosSelected, setAllCombosSelected] = useState(false);

  const [errors, setErrors] = useState({}); // Lưu lỗi cho các trường

  useEffect(() => {
    const fetchMenus = async () => {
      //test api
      // Gọi API mỗi 2 phút
      // setInterval(fetchNewOrders, 10000); // 2 phút = 2 * 60 * 1000 ms

      const response = await MenuService.getAllMenus();
      console.log(response.data);

      setMenus(response.data);
      setFilteredMenus(response.data);

      const respons = await MenuService.getAllMenusPos();
      console.log("data menu", respons.data);
    };

    fetchMenus();
  }, []);

  useEffect(() => {
    const fetchProductsAndCombos = async () => {
      try {
        const [productsResponse, combosResponse] = await Promise.all([
          ProductService.getAllProducts(),
          ComboService.getAllCombos(),
        ]);
        console.log("products", productsResponse.data);
        setProducts(productsResponse.data || []);
        setCombos(combosResponse.data || []);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch data:", error);
        setLoading(false);
      }
    };

    fetchProductsAndCombos();
  }, []);

  useEffect(() => {
    if (searchTerm) {
      const results = menus.filter((menu) =>
        menu.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredMenus(results);
    } else {
      setFilteredMenus(menus);
    }
  }, [searchTerm, menus]);

  const handleOpenMenuModal = (menu = null) => {
    console.log(menu);
    if (menu) {
      setImageURL(menu.image);
      setMenuDetails({
        name: menu.name,
        image: menu.image,
        products: menu.products,
        combos: menu.combos,
        avik_products: menu.avik_products,
      });
      setSelectedMenu(menu);
      setSelectedProducts(menu.products);
      setSelectedCombos(menu.combos);
      setSelectedAvikProducts(menu.avik_products);
      setSelectedCombosHome(menu.home_combos);
    } else {
      setMenuDetails({
        name: "",
        products: [],
        combos: [],
        home_combos: [],
        avik_products: [],
      });
      setSelectedProducts([]);
      setSelectedCombos([]);
      setSelectedMenu(null);
      setSelectedAvikProducts([]);
      setSelectedCombosHome([]);
    }
    setOpenMenuModal(true);
  };

  const handleCloseMenuModal = () => {
    setOpenMenuModal(false);
    setErrors({});
  };

  const handleSaveMenu = async () => {
    if (validateFields()) {
      try {
        const menuData = {
          name: menuDetails.name,
          image: imageURL,
          products: selectedProducts.map((product) => ({
            ID: product.ID,
          })),
          combos: selectedCombos.map((combo) => ({
            ID: combo.ID,
          })),
          home_combos: selectedCombosHome.map((combo) => ({
            ID: combo.ID,
          })),
          avik_products: selectedAvikProducts.map((product) => ({
            ID: product.ID,
          })),
        };

        console.log(menuData);

        if (selectedMenu) {
          await MenuService.updateMenu(selectedMenu.ID, menuData);
          toast.success("Menu updated successfully.");
        } else {
          await MenuService.createMenu(menuData);
          toast.success("Menu created successfully.");
        }

        setOpenMenuModal(false);
        const response = await MenuService.getAllMenus();
        setMenus(response.data);
        setFilteredMenus(response.data);
        setErrors({});
      } catch (error) {
        console.error("Failed to save menu:", error);
        toast.error("Failed to save menu.");
      }
    }
  };

  const handleDeleteMenu = async (id) => {
    try {
      await MenuService.deleteMenu(id);
      setMenus(menus.filter((menu) => menu.ID !== id));
      setFilteredMenus(filteredMenus.filter((menu) => menu.ID !== id));
      toast.success("Menu deleted successfully.");
    } catch (error) {
      console.error("Failed to delete menu:", error);
      toast.error("Failed to delete menu.");
    }
  };

  const handleToggleAllProducts = () => {
    if (allProductsSelected) {
      setSelectedProducts([]);
      setSelectedAvikProducts([]);
    } else {
      setSelectedProducts(products);
      setSelectedAvikProducts(products);
    }
    setAllProductsSelected(!allProductsSelected);
  };

  const handleToggleAllCombos = () => {
    if (allCombosSelected) {
      setSelectedCombos([]);
    } else {
      setSelectedCombos(combos);
    }
    setAllCombosSelected(!allCombosSelected);
  };

  const validateFields = () => {
    const newErrors = {};
    if (!menuDetails.name) newErrors.name = "Menu name is required";
    if (!imageURL) newErrors.imageURL = "Image is required";
    if (selectedProducts.length === 0 && selectedCombos.length === 0) {
      newErrors.items = "Please select at least one product or combo";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  return (
    <div style={{ width: "100%" }}>
      <Typography variant="h4" gutterBottom>
        Menu List
      </Typography>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleOpenMenuModal()}
        >
          Add Menu
        </Button>

        <TextField
          label="Search"
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ width: "300px" }} // Đặt độ rộng cho TextField tìm kiếm
        />
      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Image</TableCell>
              <TableCell>Name</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredMenus.map((menu) => (
              <TableRow key={menu.ID}>
                <TableCell>{menu.ID}</TableCell>
                <TableCell>
                  <img
                    src={BaseUrl + menu.image}
                    alt={menu.image}
                    style={{ width: 70, borderRadius: "10px" }}
                    onError={(e) => {
                      e.target.onerror = null; // Ngăn chặn lặp vô hạn nếu ảnh mặc định cũng không tải được
                      e.target.src =
                        "https://dummyimage.com/150x150/cccccc/555555.png&text=No+Image"; // Gán ảnh lỗi mặc định
                    }}
                  />
                </TableCell>
                <TableCell>{menu.name}</TableCell>
                <TableCell align="right">
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleOpenMenuModal(menu)}
                    sx={{ marginRight: 1 }}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => handleDeleteMenu(menu.ID)}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Modal
        open={openMenuModal}
        onClose={handleCloseMenuModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-title" variant="h6" component="h2">
            {selectedMenu ? "Edit Menu" : "Add Menu"}
          </Typography>

          {/* image */}
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" component="h3" gutterBottom>
              Menu image
            </Typography>
            <ImagePicker
              onImageSelect={setImageURL}
              defaultImage={BaseUrl + imageURL}
            />
            {errors.imageURL && (
              <Typography color="error">{errors.imageURL}</Typography>
            )}
          </Grid>

          <Grid item xs={12} sm={6}></Grid>

          <TextField
            label="Menu Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={menuDetails.name}
            onChange={(e) =>
              setMenuDetails({ ...menuDetails, name: e.target.value })
            }
            error={!!errors.name}
            helperText={errors.name}
          />

          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenProductModal(true)}
          >
            Select Products
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenComboModal(true)}
            sx={{ ml: 2 }}
          >
            Select Combos
          </Button>
          {/* <Button variant="contained" color="primary" onClick={() => setOpenProductModal(true)} sx={{ ml: 2 }}>
                        Select AVIK Products
                    </Button> */}

          <TableContainer
            component={Paper}
            sx={{ marginTop: 2, maxHeight: 400 }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Image</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Price</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedProducts.map((product) => (
                  <TableRow key={product.ID}>
                    <TableCell>{product.ID}</TableCell>
                    <TableCell>
                      <img
                        src={BaseUrl + product.image_link_square}
                        alt={product.image_link_square}
                        style={{ width: 70, borderRadius: "10px" }}
                        onError={(e) => {
                          e.target.onerror = null; // Ngăn chặn lặp vô hạn nếu ảnh mặc định cũng không tải được
                          e.target.src =
                            "https://dummyimage.com/150x150/cccccc/555555.png&text=No+Image"; // Gán ảnh lỗi mặc định
                        }}
                      />
                    </TableCell>
                    <TableCell>{product.name}</TableCell>
                    <TableCell>{product.price}</TableCell>
                  </TableRow>
                ))}
                {selectedCombos.map((combo) => (
                  <TableRow key={combo.ID}>
                    <TableCell>{combo.ID}</TableCell>
                    <TableCell>
                      <img
                        src={BaseUrl + combo.image_link_square}
                        alt={combo.image_link_square}
                        style={{ width: 70, borderRadius: "10px" }}
                        onError={(e) => {
                          e.target.onerror = null; // Ngăn chặn lặp vô hạn nếu ảnh mặc định cũng không tải được
                          e.target.src =
                            "https://dummyimage.com/150x150/cccccc/555555.png&text=No+Image"; // Gán ảnh lỗi mặc định
                        }}
                      />
                    </TableCell>
                    <TableCell>{combo.name}</TableCell>
                    <TableCell>{combo.price}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {errors.items && (
            <Typography color="error">{errors.items}</Typography>
          )}

          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveMenu}
            sx={{ marginTop: 2 }}
          >
            {selectedMenu ? "Save Changes" : "Create Menu"}
          </Button>
        </Box>
      </Modal>

      {/* Modal for Selecting Products */}
      <Modal
        open={openProductModal}
        onClose={() => setOpenProductModal(false)}
        aria-labelledby="product-modal-title"
        aria-describedby="product-modal-description"
      >
        <Box sx={modalStyle}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "20px",
            }}
          >
            <Typography id="product-modal-title" variant="h6" component="h2">
              Select Products
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleToggleAllProducts}
            >
              {allProductsSelected ? "Uncheck All" : "Check All"}
            </Button>
          </div>
          <TableContainer
            component={Paper}
            sx={{ marginTop: 2, maxHeight: 400 }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Image</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Select</TableCell>
                  <TableCell>Select AVIK category</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {products.map((product) => (
                  <TableRow key={product.ID}>
                    <TableCell>{product.ID}</TableCell>
                    <TableCell>
                      <img
                        src={BaseUrl + product.image_link_square}
                        alt={product.image_link_square}
                        style={{ width: 60, borderRadius: "10px" }}
                        onError={(e) => {
                          e.target.onerror = null; // Ngăn chặn lặp vô hạn nếu ảnh mặc định cũng không tải được
                          e.target.src =
                            "https://dummyimage.com/150x150/cccccc/555555.png&text=No+Image"; // Gán ảnh lỗi mặc định
                        }}
                      />
                    </TableCell>
                    <TableCell>{product.name}</TableCell>
                    <TableCell>
                      <Checkbox
                        checked={selectedProducts.some(
                          (p) => p.ID === product.ID
                        )}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedProducts([...selectedProducts, product]);
                          } else {
                            setSelectedProducts(
                              selectedProducts.filter(
                                (p) => p.ID !== product.ID
                              )
                            );
                          }
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      {/* <div>{JSON.stringify(product.product_category[0].Category.name)}</div> */}
                      <Checkbox
                        checked={selectedAvikProducts.some(
                          (p) => p.ID === product.ID
                        )}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedAvikProducts([
                              ...selectedAvikProducts,
                              product,
                            ]);
                          } else {
                            setSelectedAvikProducts(
                              selectedAvikProducts.filter(
                                (p) => p.ID !== product.ID
                              )
                            );
                          }
                          console.log(selectedAvikProducts);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenProductModal(false)}
            sx={{ marginTop: 2 }}
          >
            Done
          </Button>
        </Box>
      </Modal>

      {/* Modal for Selecting Combos */}
      <Modal
        open={openComboModal}
        onClose={() => setOpenComboModal(false)}
        aria-labelledby="combo-modal-title"
        aria-describedby="combo-modal-description"
      >
        <Box sx={modalStyle}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "20px",
            }}
          >
            <Typography id="combo-modal-title" variant="h6" component="h2">
              Select Combos
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleToggleAllCombos}
            >
              {allCombosSelected ? "Uncheck All" : "Check All"}
            </Button>
          </div>
          <TableContainer
            component={Paper}
            sx={{ marginTop: 2, maxHeight: 400 }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Image</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Select Menu</TableCell>
                  <TableCell>Select Home</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {combos.map((combo) => (
                  <TableRow key={combo.ID}>
                    <TableCell>{combo.ID}</TableCell>
                    <TableCell>
                      <img
                        src={BaseUrl + combo.image_link_square}
                        alt={combo.image_link_square}
                        style={{ width: 60, borderRadius: "10px" }}
                        onError={(e) => {
                          e.target.onerror = null; // Ngăn chặn lặp vô hạn nếu ảnh mặc định cũng không tải được
                          e.target.src =
                            "https://dummyimage.com/150x150/cccccc/555555.png&text=No+Image"; // Gán ảnh lỗi mặc định
                        }}
                      />
                    </TableCell>
                    <TableCell>{combo.name}</TableCell>
                    <TableCell>
                      <Checkbox
                        checked={selectedCombos.some((c) => c.ID === combo.ID)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedCombos([...selectedCombos, combo]);
                          } else {
                            setSelectedCombos(
                              selectedCombos.filter((c) => c.ID !== combo.ID)
                            );
                          }
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        checked={selectedCombosHome.some(
                          (c) => c.ID === combo.ID
                        )}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedCombosHome([
                              ...selectedCombosHome,
                              combo,
                            ]);
                          } else {
                            setSelectedCombosHome(
                              selectedCombosHome.filter(
                                (c) => c.ID !== combo.ID
                              )
                            );
                          }
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenComboModal(false)}
            sx={{ marginTop: 2 }}
          >
            Done
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default MenuList;
